import React, { useEffect, useState } from "react";

import EmptyData from "components/empty-data";
// import DataTable, { createTheme } from "react-data-table-component";
import Api from "services/api";

import "./styles.css";
import NewPageHeader from "components/newPageHeader";

export default function Checkin() {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [data, setData] = useState([]);
  const [valorSoma, setValorSoma] = useState(0);
  useEffect(() => {
    async function fetchData() {
      const checkins = await Api.get(`events/listChecks/${event_id}`);
      checkins.data.forEach((item) => {
        item.selecionado = true;
        setTimeout(() => {
          //
        }, 200);
      });
      setData(checkins.data);
      calculasoma();
    }
    fetchData();
  }, [event_id]);

  function changeColor(id) {
    const card = document.getElementById(`row-${id}`);
    if (card.classList.contains("row-active")) {
      card.classList.remove("row-active");
    } else {
      card.classList.add("row-active");
    }
  }

  function calculasoma() {
    let somaValidados = 0;
    let somaVendidos = 0;
    let checkBoxes = document.getElementsByName("check-item");

    for (let i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes[i].checked) {
        let valores = checkBoxes[i].value.split("/");
        somaValidados = somaValidados + parseInt(valores[0]);
        somaVendidos = somaVendidos + parseInt(valores[1]);
      }
    }
    setValorSoma(somaValidados + " / " + somaVendidos);
  }

  return (
    <>
      <NewPageHeader
        title="Relatório de acessos"
        subtitle="Detalhamento dos dados de validação de ingressos."
        className="bg-white"
      />
      <div className="pagetype-tickets container-fluid mt-4">
        <div className="col-12">
          <div className="checkedTable card table-responsive">
            <div className="tableReportCheck">
              <div className="checkLine checked_Table" role="table">
                <div className="lineReport checked_TableHead" role="rowgroup">
                  <div className="titleReport checked_TableHeadRow" role="row">
                    <div className="titlesTableChecked checked_TableCol"></div>
                    <div className="titlesTableChecked checked_TableCol">
                      <div
                        id="column-sector"
                        role="columnheader"
                        className="nameReport checked_TableCol_Sortable"
                      >
                        <div>Setor</div>
                      </div>
                    </div>
                    <div className="titlesTableChecked checked_TableCol">
                      <div
                        id="column-type"
                        role="columnheader"
                        className="nameReport checked_TableCol_Sortable"
                      >
                        <div>Ingresso</div>
                      </div>
                    </div>
                    <div className="titlesTableChecked checked_TableCol">
                      <div
                        id="column-lot"
                        role="columnheader"
                        className="nameCheck checked_TableCol_Sortable"
                      >
                        <div>Lote</div>
                      </div>
                    </div>
                    <div className="titlesTableChecked checked_TableCol"></div>
                  </div>
                </div>

                {data.length ? (
                  data.map((item, i) => (
                    <div key={i}>
                      <div
                        offset="250px"
                        className="reportLine checked_TableBody"
                        role="rowgroup"
                      >
                        <div role="row" className="checkList checked_TableRow ">
                          <div
                            role="gridcell"
                            id={`row-${item.id}`}
                            data-tag="allowRowEvents"
                            className="lineLit checked_TableCell row-active"
                          >
                            <div className="custom-input custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={item.id}
                                name="check-item"
                                value={`${item.consumido}/${item.qty_sold}`}
                                checked={item.selecionado}
                                onChange={(e) => {
                                  if (item.selecionado === true) {
                                    item.selecionado = false;
                                  } else {
                                    item.selecionado = true;
                                  }
                                  setData([...data]);
                                  calculasoma();
                                }}
                                onClick={(e) => changeColor(item.id)}
                              ></input>
                              <label
                                className="custom-control-label"
                                htmlFor={item.id}
                              ></label>
                            </div>
                          </div>
                          <div
                            role="gridcell"
                            data-tag="allowRowEvents"
                            className="checkedLineCenter checked_TableCell"
                          >
                            <div data-tag="allowRowEvents">{item.sector}</div>
                          </div>
                          <div
                            role="gridcell"
                            data-tag="allowRowEvents"
                            className="checkedLineCenter checked_TableCell"
                          >
                            <div data-tag="allowRowEvents">{item.type}</div>
                          </div>
                          <div
                            role="gridcell"
                            data-tag="allowRowEvents"
                            className="checkedLineCenter checked_TableCell"
                          >
                            <div data-tag="allowRowEvents">{item.lot}</div>
                          </div>
                          <div
                            role="gridcell"
                            data-tag="allowRowEvents"
                            className="lineLitValue checked_TableCell"
                          >
                            <span>{`${item.consumido} / ${item.qty_sold}`}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <EmptyData title="Nenhum resultado disponível." />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card" id="card-checkin">
            <div className="row">
              <div className="col-9">
                <span>Quantidade total</span>
              </div>
              <div className="col-3">
                <input
                  className="total"
                  type="text"
                  disabled
                  value={valorSoma}
                  id="value-change"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
