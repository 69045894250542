import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import {
  faPen,
  faUnlockAlt,
  faSearch,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import Api from "services/api.js";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "react-toastr";
import Loading from "components/loading";
import CustomButtonSpinner from "components/buttonSpinner";
import ModalUsers from "./modalUsers/index";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";
import New_api from "services/new-api";
import { useTranslation } from "react-i18next";

let container;
let porPagina = 25;

const Users = () => {
  const [UserID, setUserID] = useState("");
  const [statusModal, setStatusModal] = useState("modal-hidden");

  const [users, setUsers] = useState([]);
  const [, /*listamail*/ setListaMail] = useState([]);
  const { t } = useTranslation("users");

  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState([]);
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState([]);
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pesquisa, setPesquisa] = useState({
    pesquisa: "",
  });

  useEffect(() => {
    listUsers("initial", 1, porPagina);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function HandleEmail(email) {
    Swal.fire({
      title: t("table.passReset.confirmModal.title"),
      icon: "warning",
      html: `${t("table.passReset.confirmModal.text")} <strong>${email}</strong>`,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "white",
      cancelButtonColor: "white",
      customClass: {
        confirmButton: "custom-confirm-button-outline",
        cancelButton: "custom-confirm-button-outline",
        htmlContainer: "swal-text-justify swal-container-user",
      },
      confirmButtonText: t("table.passReset.confirmModal.confirm"),
      cancelButtonText: t("table.passReset.confirmModal.deny"),
    }).then((result) => {
      if (result.value) {
        New_api.post("recovery", { email })
          .then((res) => {
            container.success(t("table.passReset.confirmModal.success"));
          })
          .catch((err) => {
            container.error(t("table.passReset.confirmModal.error"));
          });
      }
    });
  }

  const atualizaStatus = (e, item) => {
    item.status = Number(!item.status);
    Api.get("user/" + item.id + "/status/" + item.status)
      .then((res) => {
        if (item.status === 1) {
          container.success(
            t("table.userStatusUpdated", {
              status: t("table.active").toLowerCase(),
            })
          );
        } else {
          container.success(
            t("table.userStatusUpdated", {
              status: t("table.deactivated").toLowerCase(),
            })
          );
        }
        listUsers("initial", 1, porPagina);
      })
      .catch((err) => {
        container.error(t("table.passReset.confirmModal.error"));
      });
  };

  function openModal(id) {
    setUserID(id);
    setStatusModal("modal-show");
  }

  function closeModal(e, updateAfterEdit = false) {
    setUserID("");
    setStatusModal("modal-hidden");

    if (updateAfterEdit) {
      listUsers("initial", 1, porPagina);
    }
  }
  const listUsers = (typePage, numberPage, perPage, search) => {
    setIsLoading(true);

    let pageConsult = { page: numberPage, perPage: perPage, search: search };

    switch (typePage) {
      case "Initial":
        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
        break;

      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        } else {
          numberPage++;
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        }

        break;

      case "left":
        numberPage--;
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
        };
        if (numberPage <= 0) {
          pageConsult = {
            page: 1,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        }
        break;

      case "lastPage":
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
        };
        break;
      default:
        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
    }

    Api.post("listUser", pageConsult)
      .then((res) => {
        setUsers(res.data.data);
        setListaMail(res.data.data);

        setAtualizaPaginaAtual(res.data.page);
        setAtualizaUltimaPagina(res.data.lastPage);
        setAtualizaQuantPesquisa(res.data.total);
        setIsLoading(false);

        //Encerra loading
        document.getElementById("loading").classList.add("esconde");
      })
      .catch((err) => {
        if (err.response.data.error.status === 500) {
          container.error(t("table.passReset.confirmModal.error"));
        } else {
          container.error(err.response.data.error.message);
        }
        //Encerra loading
        if (document.getElementById("loading")) {
          document.getElementById("loading").classList.add("esconde");
        }
      });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      listUsers("initial", 1, porPagina, pesquisa.pesquisa);
    }
  };
  const updateData = (e) => {
    setPesquisa({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Loading title={t("loading")}/>

      <NewPageHeader
        title={t("header.title")}
        subtitle={t("header.text")}
        btnAction={() => openModal(null)}
        btnText={t("header.btnText")}
        className="bg-white"
      >
        <ButtonContained
          className="primary-contained new-primary-contained"
          onClick={() => openModal(null)}
          content={t("header.btnText")}
        />
      </NewPageHeader>

      <div className="container-fluid mt-4">
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="col-12">
          <div className="row">
            <div className="col-md-10 mb-2">
              <div className="card-search d-flex">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  className="input-custom"
                  name="pesquisa"
                  value={pesquisa.pesquisa || ""}
                  onChange={updateData}
                  autoComplete="off"
                  placeholder={t("searchBar.placeholder")}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div className="col-md-2 mb-2">
              {/* <button className='btn btn-outline-secondary btn-height'
                value={pesquisa.pesquisa || ''}
                onClick={e => listUsers("initial", 1, porPagina, e.target.value)}>
                Pesquisar
              </button> */}
              <CustomButtonSpinner
                customStyle={"btn btn-outline-secondary btn-height"}
                triggerOnClick={listUsers}
                isLoading={isLoading}
                text={t("searchBar.btnText")}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-sm card-table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col-3">{t("table.name")}</th>
                    <th scope="col-3">{t("table.email")}</th>
                    <th scope="col-3">{t("table.status")}</th>
                    <th scope="col-3" className="text-right">
                      {t("table.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  { users.length ? (users.map((users, i) => (
                    <tr key={i}>
                      <td>
                        {users.first_name} {users.last_name}
                      </td>
                      <td>{users.email}</td>
                      <td>
                        {users.status === 1 ? (
                          <div className="badge badge-pill badge-success">
                            {t("table.active")}
                          </div>
                        ) : (
                          <div className="badge badge-pill badge-danger">
                            {t("table.deactivated")}
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="card-table-actions">
                          <ReactTooltip
                            className="tooltip-custom"
                            place="top"
                            effect="solid"
                          />
                          <span className="mr-3">
                            {t("table.user")}{" "}
                            {users.status === 1
                              ? t("table.active")
                              : t("table.deactivated")}
                          </span>
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={users.id}
                              name={users.id}
                              defaultChecked={users.status}
                              onChange={(e) => atualizaStatus(e, users)}
                            ></input>
                            <label
                              className="custom-control-label"
                              htmlFor={users.id}
                            ></label>
                          </div>
                          <button
                            className="btn-table"
                            onClick={() => openModal(users.id)}
                            data-tip={t("table.editButton.tooltip")}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </button>
                          <button
                            className="btn-table"
                            onClick={() => HandleEmail(users.email)}
                            data-tip={t("table.passReset.tooltip")}
                          >
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))) : (
                    <tr align='center'>
                      <td colSpan={6}>
                      {t("table.emptyState")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="col-12">
                <div className="row d-flex justify-content-between datatable-footer">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mr-2">{t("pagination.perPage")}</span>
                    <div>
                      <select
                        name="SelecaoPorPagina"
                        onChange={(e) =>
                          listUsers("initial", 1, (porPagina = e.target.value))
                        }
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                    {porPagina * atualizaPaginaAtual >=
                    atualizaQuantPesquisa ? (
                      <span>
                        {t("pagination.showing", {
                          perPage: atualizaQuantPesquisa,
                          total: atualizaQuantPesquisa,
                        })}
                      </span>
                    ) : (
                      <span>
                        {t("pagination.showing", {
                          perPage: porPagina * atualizaPaginaAtual,
                          total: atualizaQuantPesquisa,
                        })}
                      </span>
                    )}

                    <button onClick={() => listUsers("initial", 1, porPagina)}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </button>
                    <button
                      onClick={() =>
                        listUsers("left", atualizaPaginaAtual, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <span>
                      {t("pagination.page", {
                        firstPage: atualizaPaginaAtual,
                        lastPage: atualizaUltimaPagina,
                      })}
                    </span>

                    <button
                      onClick={() =>
                        listUsers("right", atualizaPaginaAtual, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>

                    <button
                      onClick={() =>
                        listUsers("lastPage", atualizaUltimaPagina, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalUsers status={statusModal} id={UserID} callback={closeModal} />
      </div>
    </>
  );
};

export default Users;
