import React, { useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";

//providers
import Api from "services/api";

//assets
import "./style.css";
import { Exporter } from "./exporter";
import NewPageHeader from "components/newPageHeader";

export default function InternalReports() {
  /*
   |--------------------------------------------------------------------------
   | Hooks
   |--------------------------------------------------------------------------
   */

  const [ticketClosureBegin, setTicketClosureBegin] = useState(null);
  const [ticketClosureEnd, setTicketClosureEnd] = useState(null);

  const [pdvClosureBegin, setPDVClosureBegin] = useState(null);
  const [pdvClosureEnd, setPDVClosureEnd] = useState(null);

  const [eventAmountBegin, setEventAmountBegin] = useState(null);
  const [eventAmountEnd, setEventAmountEnd] = useState(null);

  const [financialDREBegin, setFinancialDREBegin] = useState(null);
  const [financialDREEnd, setFinancialDREEnd] = useState(null);

  const [isLoadingPDV, setIsLoadingPDV] = useState(false);
  const [isLoadingTicket, setIsLoadingTicket] = useState(false);
  const [isLoadingEventAmount, setIsLoadingEventAmount] = useState(false);
  const [isLoadingFinancialDRE, setIsLoadingFinancialDRE] = useState(false);

  /*
   |--------------------------------------------------------------------------
   | Functions
   |--------------------------------------------------------------------------
   */

  function exportPDVClosure() {
    setIsLoadingPDV(true);
    const start = moment(pdvClosureBegin).format("YYYY-MM-DD HH:mm:ss");
    const end = moment(pdvClosureEnd).format("YYYY-MM-DD HH:mm:ss");

    Api.get(
      `reports/pdv-financial-closure?date_begin=${start}&date_end=${end}`,
      {
        responseType: "arraybuffer",
      }
    )
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Relatório_Financeiro_PDV.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoadingPDV(false);
        setPDVClosureBegin(null);
        setPDVClosureEnd(null);
      })
      .catch((error) => {
        setIsLoadingPDV(false);
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro, por favor tente novamente",
          confirmButtonText: "Ok",
        });
        return error;
      });
  }

  function exportTicketClosure() {
    setIsLoadingTicket(true);
    const start = moment(ticketClosureBegin).format("YYYY-MM-DD HH:mm:ss");
    const end = moment(ticketClosureEnd).format("YYYY-MM-DD HH:mm:ss");

    Api.get(
      `reports/tickets-financial-closure?date_begin=${start}&date_end=${end}`,
      {
        responseType: "arraybuffer",
      }
    )
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Relatório_Financeiro_Ingressos.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoadingTicket(false);
        setTicketClosureBegin(null);
        setTicketClosureEnd(null);
      })
      .catch((error) => {
        setIsLoadingTicket(false);
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro, por favor tente novamente",
          confirmButtonText: "Ok",
        });
        return error;
      });
  }

  function exportEventAmountDetail() {
    setIsLoadingEventAmount(true);
    const start = moment(eventAmountBegin).format("YYYY-MM-DD HH:mm:ss");
    const end = moment(eventAmountEnd).format("YYYY-MM-DD HH:mm:ss");

    Api.get(
      `reports/events-amount-details?date_begin=${start}&date_end=${end}`,
      {
        responseType: "arraybuffer",
      }
    )
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `events.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoadingEventAmount(false);
        setEventAmountBegin(null);
        setEventAmountEnd(null);
      })
      .catch((error) => {
        setIsLoadingEventAmount(false);
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro, por favor tente novamente",
          confirmButtonText: "Ok",
        });
        return error;
      });
  }

  function exportFinancialDRE() {
    setIsLoadingFinancialDRE(true);
    const start = moment(financialDREBegin).format("YYYY-MM-DD HH:mm:ss");
    const end = moment(financialDREEnd).format("YYYY-MM-DD HH:mm:ss");

    Api.get(`reports/financial-dre-taxes?date_begin=${start}&date_end=${end}`, {
      responseType: "arraybuffer",
    })
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `financial-taxes-report.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoadingFinancialDRE(false);
        setFinancialDREBegin(null);
        setFinancialDREEnd(null);
      })
      .catch((error) => {
        setIsLoadingFinancialDRE(false);
        return error;
      });
  }

  return (
    <>
      <NewPageHeader
        title="Relatórios Internos"
        subtitle="Geração de relatórios financeiro, administrativo e operacional internos."
        className="bg-white"
      />

      <div id="config" className="mt-4 container-fluid">
        <Exporter
          btnExportText={"Exportar Relatório Ingresso"}
          endOnChange={(date) => setTicketClosureEnd(date)}
          endValue={ticketClosureEnd}
          exportFn={exportTicketClosure}
          headerText={"Fechamento financeiro Ingressos"}
          isLoading={isLoadingTicket}
          startOnChange={(date) => setTicketClosureBegin(date)}
          startValue={ticketClosureBegin}
          tipsText={
            "Relatório de fechamento financeiro de bilheteria dos eventos da plataforma por período de tempo."
          }
          tipsTitle={"Fechamento financeiro ingressos"}
        />
        <div className="col-12">
          <Exporter
            btnExportText={"Exportar Relatório PDV"}
            endOnChange={(date) => setPDVClosureEnd(date)}
            endValue={pdvClosureEnd}
            exportFn={exportPDVClosure}
            headerText={"Fechamento financeiro PDV"}
            isLoading={isLoadingPDV}
            startOnChange={(date) => setPDVClosureBegin(date)}
            startValue={pdvClosureBegin}
            tipsText={`Relatório de fechamento financeiro de pontos de vedna dos eventos da plataforma por período de tempo.`}
            tipsTitle={"Fechamento financeiro PDV"}
          />

          <Exporter
            btnExportText={"Exportar Relatório de Eventos criados"}
            endOnChange={(date) => setEventAmountEnd(date)}
            endValue={eventAmountEnd}
            exportFn={exportEventAmountDetail}
            headerText={"Quantidade de eventos criados"}
            isLoading={isLoadingEventAmount}
            startOnChange={(date) => setEventAmountBegin(date)}
            startValue={eventAmountBegin}
            tipsText={`Relatório da quantidade de eventos criados no período de tempo dado, com detalhes por evento.`}
            tipsTitle={"Quantidade de eventos criados na plataforma"}
          />
          <Exporter
            btnExportText={"Exportar Relatório de Taxas"}
            endOnChange={(date) => setFinancialDREEnd(date)}
            endValue={financialDREEnd}
            exportFn={exportFinancialDRE}
            headerText={
              "Demonstração de resultados com detalhamento de taxas aplicadas"
            }
            isLoading={isLoadingFinancialDRE}
            startOnChange={(date) => setFinancialDREBegin(date)}
            startValue={financialDREBegin}
            tipsText={`Relatório financeiro da movimentação no período analisado e taxas geradas por venda.`}
            tipsTitle={
              "Demonstração de resultados com detalhamento de taxas aplicadas"
            }
          />
        </div>
      </div>
    </>
  );
}
