import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import HeaderNavigationBar from "components/headerNavigationBar";
import Loading from "components/loading";
import StepByStep from "components/stepByStep";
import api from "services/api";
import New_api from "services/new_api";

import AccountBank from "./accountBank";
import Advanced from "./advanced";
import General from "./general";
import NewPageHeader from "components/newPageHeader";

export default function Organization() {
  const numberToNextStep = 1;
  const urlParams = new URLSearchParams(window.location.search);
  const userInformations = JSON.parse(localStorage.getItem("user"));
  const [contract, setContract] = useState();
  const org_id = urlParams.get("org_id");
  const [organization, setOrganization] = useState({
    organizationInfo: {
      name: "",
      banner: "",
      slug: "",
      document: "",
      owner_document: "",
      social_name: "",
      thumb: "",
      category: "",
      attendant: null,
      seller: null,
      event_frequency_days: null,
      payments_plan_id: null,
      currency: "",
      timezone: "",
      trust_level_id: "",
    },
    producer: {
      email: "",
      first_name: "",
      last_name: "",
      user_id: null,
    },
    accountsBank: [],
    partnersList: [],
    oldPartnersList: [],
    taxes: {},
  });
  const [paymentPlans, setPaymentPlans] = useState([]);
  const { t } = useTranslation("organizations", {
    keyPrefix: "configuration",
  });

  const { t: tg } = useTranslation("global");
  const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

  const navlinks = [
    { text: "first-step.navigation-text", position: 1 },
    {
      text: "second-step.info",
      position: 2,
      show: isWhitelabel ? true : !!userInformations.is_admin,
    },
    { text: "third-step.info", position: 3 },
  ];

  const [position, setPosition] = useState(1);
  const [steps, setSteps] = useState([
    {
      id: 1,
      stepInfo: "first-step.info",
      complete: false,
      currentPosition: 1,
      next: false,
    },
    {
      id: 2,
      stepInfo: "second-step.info",
      complete: false,
      currentPosition: 1,
      next: false,
    },
    {
      id: 3,
      stepInfo: "third-step.info",
      complete: false,
      currentPosition: 1,
      next: false,
    },
  ]);
  const readOnly =
    userInformations.is_admin ||
    !org_id ||
    +userInformations.id === +organization.producer.user_id
      ? false
      : true;
  const showSubmitButton = readOnly;
  const readOnlyTaxes = userInformations.is_admin ? false : true;

  const callBack = (receivedTaxes, paymentPlan) => {
    console.log("ASD");
    document.getElementById("loading").classList.remove("esconde");
    if (!userInformations.is_admin) {
      delete organization.organizationInfo.trust_level_id;
    }
    api
      .post(`organization/create`, {
        //Removing "checkedModal" from accountsBank array and add taxes
        organization: {
          ...organization,
          payments_plan_id: paymentPlan,
          accountsBank: organization.accountsBank.map(
            ({ checkedModal, ...array }) => array
          ),
          taxes: receivedTaxes,
        },
      })
      .then(() => {
        api.get("validToCreateEventOrEstablishment").then((res) => {
          localStorage.setItem("hasOrganizations", res.data);
          Swal.fire({
            title: t("create.success.title"),
            icon: "success",
            confirmButtonColor: "#23b936",
            cancelButtonColor: "#188125",
            text: t("create.success.text"),
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.href = "/organizacoes";
          });
          document.getElementById("loading").classList.add("esconde");
        });
      })
      .catch(() => {
        document.getElementById("loading").classList.add("esconde");
        Swal.fire({
          title: "Erro!",
          icon: "error",
          confirmButtonColor: "#23b936",
          cancelButtonColor: "#188125",
          text: "Ocorreu um erro, por favor tente novamente!",
          confirmButtonText: "Ok",
        });
      });
  };

  const callBackEdit = (dataForm, paymentPlan, type) => {
    document.getElementById("loading").classList.remove("esconde");
    let payload = dataForm;
    if (type !== "taxes") {
      let taxConvert = { taxes: {} };
      for (const key in organization.taxes) {
        if (Object.hasOwnProperty.call(organization.taxes, key)) {
          const taxContent = organization.taxes[key];
          //there are two rates that are not being used. So they don't need to be multiplied by 100.("_fintxdefaultpdv" and "_fintxminpdvprodutor")
          if (
            taxContent.type === "percentual" &&
            key !== "_fintxdefaultpdv" &&
            key !== "_fintxminpdvprodutor"
          ) {
            taxConvert = {
              ...taxConvert,
              taxes: {
                ...taxConvert.taxes,
                [key]: {
                  ...organization.taxes[key],
                  value: Math.round(
                    parseFloat(organization.taxes[key].value).toFixed(2) * 100,
                    1
                  ),
                },
              },
            };
          } else {
            // Removing "R$" from the all the fixed taxes
            taxConvert = {
              ...taxConvert,
              taxes: {
                ...taxConvert.taxes,
                [key]: {
                  //conditional not to apply replace unnecessarily
                  ...organization.taxes[key],
                  value:
                    key !== "_fintxdefaultpdv" &&
                    key !== "_fintxminpdvprodutor" &&
                    typeof organization.taxes[key].value === "string"
                      ? +organization.taxes[key].value.replace(/\D/g, "")
                      : organization.taxes[key].value,
                },
              },
            };
          }
        }
      }
      payload = { ...payload, paymentPlan, taxes: taxConvert.taxes };
    } else {
      if (!userInformations.is_admin) {
        delete organization.organizationInfo.trust_level_id;
      }
      payload = { ...organization, paymentPlan, taxes: dataForm };
    }

    if (payload.organizationInfo.trust_level) {
      delete payload.organizationInfo.trust_level;
    }

    api
      .put(`organization/edit/${org_id}`, {
        organization: payload,
        contract,
      })
      .then(() => {
        Swal.fire({
          title: t("edit.success.title"),
          icon: "success",
          confirmButtonColor: "#23b936",
          cancelButtonColor: "#188125",
          text: t("edit.success.text"),
          confirmButtonText: "Ok",
        })
          .then(() => {
            window.location.href = `/organizacao?org_id=${org_id}`;
          })
          .catch(() => {
            Swal.fire({
              title: "Falha",
              icon: "error",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#95aac9",
              text: "Erro interno. Caso o erro persista acionar o suporte!",
              confirmButtonText: "Ok",
            });
            return;
          })
          .finally(() =>
            document.getElementById("loading").classList.add("esconde")
          );
      })
      .catch((err) => {
        Swal.fire({
          title: "Oops!",
          icon: "error",
          confirmButtonColor: "#3085d6",
          text:
            err.response?.data?.message ||
            "Não foi possível atualizar a organização, tente novamente.",
          confirmButtonText: "Ok",
        });
      })
      .finally(() =>
        document.getElementById("loading").classList.add("esconde")
      );
  };

  useEffect(() => {
    if (!org_id) {
      api
        .get("pricesAndTaxes")
        .then((res) => {
          const fields = res.data.map((item) => item.tag);
          let taxes;
          for (const taxItem of res.data) {
            for (const taxState of fields) {
              if (taxItem.tag === taxState) {
                if (
                  taxState !== "_fintxadmon" &&
                  taxState !== "_fintxrebaite" &&
                  taxState !== "_cusAntecipacao" &&
                  taxState !== "_fincustoDebito" &&
                  taxState !== "_fincustoCredito" &&
                  taxState !== "_fincustoAntec" &&
                  taxState !== "_custxpix" &&
                  taxState !== "_fintxprocessamento"
                ) {
                  taxes = {
                    ...taxes,
                    [taxState]: {
                      ...organization.taxes[taxState],
                      value: taxItem.value,
                      id: taxItem.id,
                      type: taxItem.type,
                    },
                  };
                } else {
                  taxes = {
                    ...taxes,
                    [taxState]: {
                      ...organization.taxes[taxState],
                      value: taxItem.value / 100,
                      id: taxItem.id,
                      type: taxItem.type,
                    },
                  };
                }
              }
            }
          }
          setOrganization((prevState) => ({
            ...prevState,
            taxes,
          }));
          document.getElementById("loading").classList.add("esconde");
        })
        .catch(() => {
          document.getElementById("loading").classList.add("esconde");
        });
      defaultCurrencyAndTimezone();
    } else {
      document.getElementById("loading").classList.remove("esconde");
      api
        .get(`organization/show/${org_id}`)
        .then((res) => {
          let arrayPartners;
          if (
            !res.data.organization.organizationInfo.currency &&
            !res.data.organization.organizationInfo.timezone
          ) {
            defaultCurrencyAndTimezone();
          } else {
            document.getElementById("loading").classList.add("esconde");
          }
          if (
            res.data.organization.partnersList &&
            res.data.organization.partnersList.length > 0
          ) {
            arrayPartners = res.data.organization.partnersList.map(
              (partner) => {
                return {
                  ...partner,
                  first_name_partner: partner.first_name,
                  last_name_partner: partner.last_name,
                  email_partner: partner.email,
                };
              }
            );
          }

          setOrganization({
            ...res.data.organization,
            organizationInfo: {
              ...res.data.organization.organizationInfo,
              trust_level_id:
                res.data.organization.organizationInfo.trust_level.id,
            },
            oldPartnersList: arrayPartners,
            partnersList: arrayPartners,
          });
          setContract(res.data.contract);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
    api.get("paymentplans").then((res) => {
      setPaymentPlans(res.data);
    });
    // eslint-disable-next-line
  }, []);

  const defaultCurrencyAndTimezone = () => {
    New_api.get("settings/locale")
      .then(({ data: { currency, timezone } }) =>
        setOrganization((prevState) => ({
          ...prevState,
          organizationInfo: {
            ...prevState.organizationInfo,
            currency,
            timezone,
          },
        }))
      )
      .finally(() =>
        document.getElementById("loading").classList.add("esconde")
      );
  };

  return (
    <Fragment>
      <NewPageHeader
        className="bg-white"
        title={org_id ? t("edit.title") : t("create.title")}
        subtitle="Tela de configurações de organização"
      />
      <Loading title={tg("loadingText")} />
      <Container fluid>
        {!org_id ? (
          <StepByStep steps={steps} setSteps={setSteps} />
        ) : (
          <HeaderNavigationBar
            navlinks={navlinks}
            selectedPosition={setPosition}
          />
        )}
        {!org_id ? (
          steps.map((item) => {
            if (item.currentPosition === 1 && item.id === 1 && !org_id) {
              return (
                <General
                  key={item.id}
                  userInformations={userInformations}
                  setSteps={setSteps}
                  org_id={org_id}
                  organization={{
                    organization,
                    organizationInfo: organization.organizationInfo,
                    producer: organization.producer,
                    partnersList: organization.partnersList,
                    setOrganization,
                  }}
                  stepId={+item.id}
                  nextStepId={+item.id + numberToNextStep}
                />
              );
            } else if (item.currentPosition === 2 && item.id === 2) {
              return (
                <AccountBank
                  key={item.id}
                  setSteps={setSteps}
                  org_id={org_id}
                  organization={{
                    organization,
                    setOrganization,
                  }}
                  accountsBank={organization.accountsBank}
                  stepId={+item.id}
                  nextStepId={+item.id + numberToNextStep}
                />
              );
            } else if (item.currentPosition === 3 && item.id === 3) {
              return (
                <Advanced
                  defaultPaymentPlan={
                    organization.organizationInfo.payments_plan_id
                  }
                  taxes={organization.taxes}
                  currency={organization?.organizationInfo?.currency}
                  showSubmitButton={showSubmitButton}
                  readOnly={readOnlyTaxes}
                  org_id={org_id}
                  userIsAdmin={userInformations.is_admin}
                  callBack={callBack}
                  paymentPlans={paymentPlans}
                />
              );
            } else {
              return null;
            }
          })
        ) : position === 1 ? (
          <General
            setSteps={setSteps}
            userInformations={userInformations}
            readOnly={readOnly}
            org_id={org_id}
            callBackEdit={callBackEdit}
            organization={{
              organization,
              taxes: organization.taxes,
              organizationInfo: organization.organizationInfo,
              producer: organization.producer,
              partnersList: organization.partnersList,
              contract,
              setOrganization,
            }}
          />
        ) : position === 2 ? (
          <AccountBank
            setSteps={setSteps}
            org_id={org_id}
            readOnly={readOnly}
            organization={{
              organization,
              setOrganization,
            }}
            accountsBank={organization.accountsBank}
            callBackEdit={callBackEdit}
          />
        ) : (
          <Advanced
            readOnly={readOnlyTaxes}
            showSubmitButton={showSubmitButton}
            defaultPaymentPlan={organization.organizationInfo.payments_plan_id}
            taxes={organization.taxes}
            currency={organization?.organizationInfo?.currency}
            org_id={org_id}
            userIsAdmin={userInformations.is_admin}
            callBack={callBack}
            callBackEdit={callBackEdit}
            paymentPlans={paymentPlans}
          />
        )}
      </Container>
    </Fragment>
  );
}
