import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faPen,
  faEyeSlash,
  faEye,
  faSearch,
  faEllipsisV,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import Api from "../../../services/api.js";
import Swal from "sweetalert2";
import "./styles.css";

// Components
import EmptyData from "components/empty-data";
import { ToastContainer } from "react-toastr";

// Modal
import ModalProductCategorie from "pages/common-area/products-categories/modalProductCategorie/index.js";
import NewPageHeader from "components/newPageHeader/index.js";
import ButtonContained from "components/buttonContained/index.js";

// import "./styles.css";

let container;
let porPagina = 10;

const ProductsCategories = () => {
  const [data, setData] = useState([]);
  const [/*dataFilter*/, setDataFilter] = useState([]);
  const [statusModal, setStatusModal] = useState('modal-hidden');
  const [tableID, setTableID] = useState('');

  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState([]);
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState([]);
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState([]);
  const [pesquisa, setPesquisa] = useState({
    pesquisa: ""
  });

  function openModal(id) {
    setTableID(id);
    setStatusModal('modal-show')
  }

  function closeModal(e) {
    setTableID('');
    setStatusModal('modal-hidden');
  }

  useEffect(() => {
    listCategories("initial", 1, porPagina);
    // eslint-disable-next-line
  }, []);

  const listCategories = (typePage, numberPage, perPage, search) => {

    let pageConsult = { page: numberPage, perPage: perPage, search: search };
    switch (typePage) {
      case "Initial":
        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
        break;

      case "right":

        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = { page: numberPage, perPage: perPage, search: pesquisa.pesquisa };
        } else {
          numberPage++;
          pageConsult = { page: numberPage, perPage: perPage, search: pesquisa.pesquisa };
        }

        break;

      case "left":
        numberPage--;
        pageConsult = { page: numberPage, perPage: perPage, search: pesquisa.pesquisa };
        if (numberPage <= 0) {
          pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
        }
        break;

      case "lastPage":
        pageConsult = { page: numberPage, perPage: perPage, search: pesquisa.pesquisa };
        break;
      default:

        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
    }

    Api.post(`products/paginationCategoriesProducts`, pageConsult).then(res => {

      setData(res.data.data);
      setDataFilter(res.data.data);

      setAtualizaPaginaAtual(res.data.page);
      setAtualizaUltimaPagina(res.data.lastPage);
      setAtualizaQuantPesquisa(res.data.total)
    }).catch(err => {
      if (err.response.data.error.status === 500) {
        container.error('Rota não encontrada');
      } else {
        container.error(err.response.data.error.message);
      }
      //Encerra loading
      if (document.getElementById("loading")) {
        document.getElementById("loading").classList.add("esconde");
      }
    })
  }

  const handleKeyDown = (categories) => {
    if (categories.key) {
      listCategories("initial", 1, porPagina, pesquisa.pesquisa)
    }
  };
  const updateData = (e) => {
    setPesquisa({
      [e.target.name]: e.target.value,
    });
  }

  function openDropdown(i) {
    let dropdows = document.getElementsByClassName('dropdown-buttons');
    if (!dropdows[i].classList.contains('show-dropdown')) {
      if (dropdows.length > 0) {
        for (let index = 0; index < dropdows.length; index++) {
          dropdows[index].classList.remove('show-dropdown');
        }
      }
      dropdows[i].classList.add('show-dropdown');
    } else {
      dropdows[i].classList.remove('show-dropdown');
    }
  }

  const HandleClick = id => {
    Swal.fire({
      title: "Deletar",
      icon: "warning",
      text: "Deseja realmente deletar esta categoria?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#95aac9",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(result => {
      if (result.value) {
        Api.post(`products/deleteCategorie/${id}`)
          .then(res => {
            container.success("Categoria delatada com sucesso!");
           // fetchData();
          })
          .catch(err => {
            Swal.fire("Deletar", "Categoria não deletada", "error");
          });
      }
    });
  };

  const HandleActivate = id => {
    Swal.fire({
      title: "Ativar",
      icon: "warning",
      text: "Deseja realmente ativar esta categoria?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#95aac9",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(result => {
      if (result.value) {
        Api.post(`products/reativateCategorie/${id}`)
          .then(res => {
            container.success("Categoria ativada com sucesso!");
           // fetchData();
          })
          .catch(err => {
            Swal.fire("Ativar", "Categoria não deletada", "error");
          });
      }
    });
  };

  const HandleDeactivate = id => {
    Swal.fire({
      title: "Desativar",
      icon: "warning",
      text: "Deseja realmente desativar esta categoria?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#95aac9",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(result => {
      if (result.value) {
        Api.post(`products/deactivateCategorie/${id}`)
          .then(res => {
            container.success("Categoria desativada com sucesso!");
           // fetchData();
          })
          .catch(err => {
            Swal.fire("Desativar", "Categoria não desativada", "error");
          });
      }
    });
  };

  return (

    <>
      <NewPageHeader
        title="Categorias de produtos"
        subtitle="Gerencie as categorias de agrupamento de produtos."
        className="bg-white"
      >
        <ButtonContained
          className="primary-contained new-primary-contained"
          onClick={() => openModal(null)}
          content="Criar nova categoria"
        />
      </NewPageHeader>

      <div className="container-fluid mt-4">
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-top-right"
        />
        <div className="col-12 mb-2">
          <div className="card-search">
            <FontAwesomeIcon icon={faSearch} className="icon-search" />
            <input
              type="text"
              className="input-custom"
              name="pesquisa"
              placeholder="Pesquisar categoria pelo nome"
              value={pesquisa.pesquisa || ''}
              onChange={updateData}
              autoComplete="off"
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>

        <div className="col-12 mb-2">
          <div class="categorie-responsive">
            <div class="categorie-options">
              <div id="list-establishments" class="card-categorie categorie_Table" role="table">
                <div class="categorie_TableHead" role="rowgroup">
                  <div class="title-categorie categorie_TableHeadRow mb-2" role="row">
                    <div class="categorie-temas categorie_TableCol">
                      <div id="filterOrd" role="columnheader" class="categorie_TableCol_Sortable teste1">
                        <div className="text-center arrow-active">
                          Nome
                        </div>
                      </div>
                    </div>
                    <div class="categorie-temas categorie_TableCol">
                      <div role="columnheader" class="categorie_TableCol_Sortable">
                        <div>
                          Status
                        </div>
                      </div>
                    </div>
                    <div class="categorie-temas categorie_TableCol">
                      <div role="columnheader" class="categorie_TableCol_Sortable">
                        <div>
                          Descrição
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {data.length ? (
                  data.map((item, i) =>
                    <>
                      <div role="row" class="card-category shadow-sm categorie_TableRow card-establishments">
                        <div role="gridcell" data-tag="allowRowEvents" class="title-information categorie_TableCell card-establishments-infos">
                          <div>
                            {item.name}
                          </div>
                        </div>

                        <div role="gridcell" class="categorie-bad categorie_TableCell">
                          {item.status === 1 ?
                            (
                              <div className="badge badge-pill badge-success">Ativo</div>
                            ) : (
                              <div className="badge badge-pill badge-danger">Desativado</div>
                            )}
                        </div>

                        <div role="gridcell" class="title-information categorie_TableCell">
                          <div data-tag="allowRowEvents">
                            {item.description}
                          </div>
                        </div>
                        <button className="dotsMobile card-list size-button" value={i} onClick={() => openDropdown(i)}>
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </button>
                        <div className="dropdown-buttons dropdown-area">
                          <span
                            className="dropdown-item"
                            onClick={() => openModal(item.id)}
                            style={{ marginRight: "10px" }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                            <span>Editar</span>
                          </span>
                          <span
                            className="dropdown-item"
                            style={{ marginRight: "10px" }}
                            onClick={() => HandleClick(item.id)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                            <span>Deletar</span>
                          </span>
                          {item.status === 1 ? (
                            <span
                              className="dropdown-item"
                              onClick={() => HandleDeactivate(item.id)}
                            >
                              <FontAwesomeIcon icon={faEyeSlash} />
                              <span>Desativar</span>
                            </span>
                          ) : (
                            <span
                              className="dropdown-item"
                              onClick={() => HandleActivate(item.id)}
                            >
                              <FontAwesomeIcon icon={faEye} />
                              <span>Ativar</span>
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <EmptyData title="Nenhuma categoria de produtos registrado." />
                )}

              </div>
            </div>
          </div>
          
          <div className="col-12">
            <div className="row d-flex justify-content-between mb-3 datatable-footer" >
              <div className="col-md-6 d-flex align-items-center">
                <span className="mr-2">Resultados por Página:</span>
                <div>
                  <select name="SelecaoPorPagina" onChange={(e) => listCategories("initial", 1, porPagina = e.target.value)}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons" >
                {porPagina * atualizaPaginaAtual >= atualizaQuantPesquisa ? (<span> Exibindo {atualizaQuantPesquisa} de {atualizaQuantPesquisa} </span>) : (<span> Exibindo {porPagina * atualizaPaginaAtual} de {atualizaQuantPesquisa} </span>)}

                <button
                  onClick={() => listCategories("initial", 1, porPagina)}>
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </button>
                <button
                  onClick={() => listCategories("left", atualizaPaginaAtual, porPagina)}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <span>Página {atualizaPaginaAtual} de {atualizaUltimaPagina}</span>

                <button
                  onClick={() => listCategories("right", atualizaPaginaAtual, porPagina)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>

                <button
                  onClick={() => listCategories("lastPage", atualizaUltimaPagina, porPagina)}>
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <ModalProductCategorie status={statusModal} id={tableID} callback={closeModal} />
    </>
  );
};

export default ProductsCategories;
