import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import CustomButton from "components/custom-button";
import Input from "components/input";
import Swal from "sweetalert2";
import ContainerFluid from "components/containers/container-fluid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import api from "services/api";
import Pagination from "components/Pagination";
import Loading from "components/loading";
import { format } from "date-fns";
import DivFormGroup from "components/div-form-group";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";

export default function PdvList() {
  const defaultPerPage = 25;
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    currentPage: 0,
    lastPage: 0,
    amount: 0,
    perPage: 25,
  });

  const changeInput = (dataInput) => setSearch(dataInput.target.value);

  const actions = () => getData(1, defaultPerPage, search);

  const getData = (page, perPage) => {
    // eslint-disable-next-line
    document.getElementById("loading").classList.remove("esconde");
    api
      .get(
        `pdvs?page=${page || pageConfig.page}&per_page=${
          perPage || pageConfig.perPage
        }&by_query=${search}`
      )
      .then((res) => {
        setPageConfig({
          page: res.data.page,
          currentPage: res.data.page,
          lastPage: res.data.lastPage,
          amount: res.data.total,
          perPage: res.data.perPage,
        });
        const convertArray = res.data.data.map((content) => {
          return {
            id: content.id,
            created_at: content.created_at,
            name: content.name,
            enabled: content.enabled,
          };
        });
        setData(convertArray);
        // eslint-disable-next-line
        document.getElementById("loading").classList.add("esconde");
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  const deletePdv = (pdvId) => {
    api
      .delete(`/pdvs/${pdvId}`)
      .then(() => {
        Swal.fire({
          text: "PDV deletado com sucesso!",
          icon: "success",
          confirmButtonText: "Ok",
        })
          .then(() => {
            // eslint-disable-next-line
            window.location.pathname = "/PdvList";
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      })
      .catch(() => {
        Swal.fire({
          text: "Este PDV não pode ser deletado!",
          icon: "error",
          confirmButtonText: "Ok",
        })
          .then(() => {
            // eslint-disable-next-line
            window.location.pathname = "/PdvList";
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <NewPageHeader
        title="Lista de PDV's"
        subtitle="Lista dos pontos de vendas cadastrados."
        className="bg-white"
      >
        <ButtonContained
          className="primary-contained new-primary-contained"
          onClick={() => (window.location.href = "/registerPDV")}
          content="Criar PDV"
        />
      </NewPageHeader>
      <Loading title="aguarde..." />
      <ContainerFluid>
        <div className="row">
          <DivFormGroup adjutsCss="col-md-9 col-12">
            <Input
              type="text"
              placeholder="Digite aqui o nome do ponto de venda."
              name="name"
              value={search}
              onChange={changeInput}
              className="form-control"
            />
          </DivFormGroup>
          <DivFormGroup adjutsCss="button-group col-md-3 col-12">
            <CustomButton
              className="btn btn-secondary"
              onClick={actions}
              text="pesquisar"
            />
          </DivFormGroup>
        </div>
        <div className="table table-responsive">
          <table
            id="table-totalsales"
            className="table table-sm mt-2 datatable-custom"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">DATA DE CRIAÇÃO</th>
                <th scope="col" className="text-center">
                  NOME
                </th>
                <th scope="col" className="text-center">
                  STATUS
                </th>
                <th scope="col" className="text-right">
                  AÇÕES
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.name}>
                  <td>
                    {format(new Date(item.created_at), "dd/MM/yyyy HH:mm:ss")}
                  </td>
                  <td className="text-center">
                    {item.name ? item.name : "Não informado!"}
                  </td>
                  <td className="text-center">
                    {item.enabled === 1 ? "Ativo" : "Desativado"}
                  </td>
                  <td className="d-flex justify-content-end">
                    <div className="row mr-1">
                      <NavLink
                        to={`/registerPDV?pdv_id=${item.id}`}
                        className="btn-table mt-2"
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </NavLink>
                      <CustomButton
                        onClick={() => deletePdv(item.id)}
                        className="btn-table mt-2"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </CustomButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination pageConfig={pageConfig} parentFunction={getData} />
        </div>
      </ContainerFluid>
    </>
  );
}
