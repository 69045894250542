import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import CustomButtonSpinner from "components/buttonSpinner";
import EmptyData from "components/empty-data";
import Loading from "components/loading";
import Pagination from "components/Pagination";

import { useCurrencyEventContext } from "contexts/currency-event-context";
import { formatCurrency } from "utils";
import Api from "../../../services/api";
import ModalComissarios from "./modalComissarios";
import NewPageHeader from "components/newPageHeader";

export default function VendasComissarios() {
  const [showModalComissarios, setShowModalComissarios] = useState(false);
  const { currency } = useCurrencyEventContext();
  const urlParams = new URLSearchParams(window.location.search);
  const [comissarioId, setComissarioId] = useState();
  const event_id = urlParams.get("event_id");
  const [lista, setLista] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pesquisa, setPesquisa] = useState({
    pesquisa: "",
  });

  const first_page = 1;
  const default_qnty = 25;

  const [pageConfig, setPageConfig] = useState({
    page: 1,
    currentPage: 0,
    lastPage: 0,
    amount: 0,
    perPage: 25,
  });

  const handleClick = () => {
    getData(first_page, default_qnty);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getData(first_page, default_qnty);
    }
  };

  const updateData = (e) => {
    setPesquisa({
      [e.target.name]: e.target.value,
    });
  };

  function openModalComissarios(id) {
    setComissarioId(id);
    setShowModalComissarios(true);
  }

  const getData = (page, perPage) => {
    setIsLoading(true);
    Api.get(`steward/sold/event/${event_id}`, {
      params: {
        page: page || pageConfig.page,
        perPage: perPage || pageConfig.perPage,
        search: pesquisa.pesquisa,
      },
    })
      .then((res) => {
        setPageConfig({
          page: res.data.page,
          currentPage: res.data.page,
          lastPage: res.data.lastPage,
          amount: res.data.total,
          perPage: res.data.perPage,
        });
        setLista(res.data.result);
        setIsLoading(false);

        if (document.getElementById("loading")) {
          document.getElementById("loading").classList.add("esconde");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });

        return err;
      });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id]);

  return (
    <>
      <Loading title="aguarde..." />

      <NewPageHeader
        title="Vendas por promoters"
        subtitle="Listagem detalhada de vendas realizadas por promoters."
        className="bg-white"
      />
      <div
        className="pagetype-tickets container-fluid mt-4"
        style={{ paddingBottom: "30px" }}
      >
        <div className="row">
          <div className="col-md-10 mb-2">
            <div className="card-search">
              <FontAwesomeIcon icon={faSearch} className="icon-search" />
              <input
                type="text"
                className="input-custom"
                name="pesquisa"
                placeholder="Pesquise pelo nome do promoter"
                autoComplete="off"
                onChange={updateData}
                value={pesquisa.pesquisa || ""}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="col-md-2 mb-2">
            <CustomButtonSpinner
              customStyle="btn btn-outline-secondary btn-height"
              value={pesquisa.pesquisa || ""}
              triggerOnClick={handleClick}
              isLoading={isLoading}
              text="Pesquisar"
            />
          </div>
          {lista.length > 0 ? (
            <div className="col-12 mt-4">
              <div className="card">
                <div className="table-responsive">
                  <table id="table-stewards" className="table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="text-left col-4">
                          promoter
                        </th>
                        <th scope="col" className="text-center col-4">
                          Total em vendas
                        </th>
                        <th scope="col" className="text-center col-4">
                          Quantidade de ingressos
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {lista.map((steward, i) => (
                        <tr
                          key={i}
                          onClick={() => {
                            openModalComissarios(steward.steward_id);
                          }}
                        >
                          <td>{steward.steward_name}</td>
                          <td className="text-center">
                            {formatCurrency(steward.ticket_amount, {
                              isCents: false,
                              currency,
                            })}
                          </td>
                          <td className="text-center">
                            {steward.ticket_count}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination pageConfig={pageConfig} parentFunction={getData} />
            </div>
          ) : (
            <EmptyData title={"Nenhum resultado disponível."} />
          )}
        </div>
      </div>

      <ModalComissarios
        show={showModalComissarios}
        title={`Relatório detalhado`}
        stewardId={comissarioId}
        eventId={event_id}
        onClose={() => setShowModalComissarios(false)}
      />
    </>
  );
}
