const CENTS_BASE = 100;

const defaultValues = {
  language: "pt-BR",
  currency: "BRL",
  removeSymbol: false,
};

const currencySymbol = {
  BRL: "R$",
  USD: "$",
  EUR: "€",
};

const currencyLanguage = {
  BRL: "pt-BR",
  USD: "en-US",
  EUR: "es-ES",
};

export function convertFromCents(value) {
  return Math.abs(value / CENTS_BASE);
}

export function convertToCents(value) {
  return Math.round(value * CENTS_BASE);
}

/**
 * @param {string} value
 * @returns {string}
 **/
const sanitizeBreakingSpaces = (value) =>
  value
    .replace(/\xa0/g, " ")
    .replace(/\u202f/g, " ")
    .replace("-", "");

/**
 * @param {number} amount
 * @param {object} options
 * @param {string} options.currency
 * @param {string} options.language
 * @param {boolean} options.isCents
 * @param {boolean} options.removeSymbol
 * @returns {string}
 */
export const formatCurrency = (amount, options) => {
  if (amount === undefined) return formatCurrency(0, options);
  const isNegative = amount < 0;
  const value = options?.isCents ? convertFromCents(amount) : amount;
  const { currency, removeSymbol } = { ...defaultValues, ...options };
  const formatter = new Intl.NumberFormat(
    currencyLanguage[currency] ?? "pt-BR",
    {
      style: "currency",
      currency: currency || "BRL",
    }
  );
  let result = sanitizeBreakingSpaces(formatter.format(value));
  if (removeSymbol) result = result.replace(new RegExp(/[^,.0-9-]+/), "");
  return isNegative ? `-${result}` : `${result}`;
};

/**
 * @param {number} amount
 * @param {object} options
 * @param {'BRL' | 'USD' | 'EUR'} options.currency
 * @param {string} options.language
 * @param {boolean} options.removeSymbol
 * @returns {string}
 */
export const currencyFromInput = (amount, options) => {
  if (amount === undefined || amount === null) return "";
  if (typeof amount === "number")
    return currencyFromInput(amount.toString(), options);
  if (amount.trim() === "-")
    return  "-0,00";
  const { currency, removeSymbol } = { ...defaultValues, ...options };
  const removeStrings = Number(amount.replace(/[^0-9-]/g, ""));

  let result = new Intl.NumberFormat(currencyLanguage[currency] ?? "pt-BR", {
    style: "currency",
    currency: currency || "BRL",
  }).format(removeStrings / CENTS_BASE);

  if (removeSymbol) result = result.replace(new RegExp(/[^,.0-9-]+/), "");

  return result;

};

/**
 *
 * @param {'BRL' | 'EUR' | 'USD'} currency
 * @returns {'R$' | '€' | '$'}
 */
export const getCUrrencySymbol = (currency) => currencySymbol[currency];
