import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";
import api from "services/api";
import Select from "react-select";
import New_api from "services/new-api";
import ProgressBar from "react-bootstrap/ProgressBar";
import CustomButtonSpinner from "components/buttonSpinner";
import { Card, Container, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";
import { ReactComponent as Download } from "../../../../assets/icons/download.svg";
import IngressoHeader from "components/subheader";

const Localizadores = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(currentPage);
  const [previousPage, setPreviousPage] = useState(0);

  const [data, setData] = useState([]);
  const [quantity, setQuantity] = useState();
  const [sectorIds, setSectorIds] = useState([]);
  const [, setIsLoading] = useState(false);
  const [sectorsAmount, setSectorAmount] = useState(0);
  const [localizerList, setLocalizerList] = useState([]);
  const [selectedSector, setSelectedSector] = useState(0);
  const [createIsLoading, setCreateIsLoading] = useState(false);
  const [loadingLocalizers, setLoadingLocalizers] = useState(true);

  useEffect(() => {
    api.get(`event/tickets/${event_id}`).then((res) => {
      setData(res.data);
    });
  }, [event_id]);

  useEffect(() => {
    if (currentPage <= lastPage && currentPage !== previousPage) {
      getLocalizer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, lastPage]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation(["localizers"]);

  const getLocalizer = () => {
    setLoadingLocalizers(true);
    New_api.get(`events/${event_id}/localizers`, {
      params: { page: currentPage },
    })
      .then(({ data }) => {
        setLocalizerList((prevList) => [...prevList, ...data.data]);
        setPreviousPage(currentPage);
        setLastPage(data.meta.last_page);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("errorListing"),
          confirmButtonText: "Ok",
        });
      })
      .finally(() => setLoadingLocalizers(false));
  };

  const onScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 5 &&
      currentPage <= lastPage
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const options = data.map((sector) => ({
    value: sector.id,
    label: sector.sector,
  }));

  const exportXLSX = () => {
    setIsLoading(true);
    New_api.get(`events/${event_id}/localizers/export-xlsx`, {
      params: { sector_ids: sectorIds, per_page: 10000 },
      responseType: "arraybuffer",
    })
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${t("localizers")}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoading(false);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("errorExporting"),
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createLocalizers = () => {
    setCreateIsLoading(true);
    const data = {
      event_sector_id: selectedSector,
      quantity: quantity,
    };

    New_api.post(`/events/${event_id}/localizers`, data)
      .then(() => {
        Swal.fire({
          title: "Sucesso",
          icon: "success",
          text: "Localizadores criados com sucesso!",
          confirmButtonText: "Ok",
        });

        setQuantity("");
        setPreviousPage(0);
        setCurrentPage(1);
        setLastPage(1);
        setLocalizerList([]);
        getLocalizer();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        if (errors["event_sector_id"]) {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Setor para criação de localizadores não informado.",
            confirmButtonText: "Ok",
          });
        } else if (errors["quantity"]) {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "A quantidade informada é nula ou superior a 10.000 localizadores.",
            confirmButtonText: "Ok",
          });
        }
      })
      .finally(() => setCreateIsLoading(false));
  };

  function handleChange(e) {
    const index = sectorIds.indexOf(e.target.value);

    if (index < 0) {
      setSectorAmount(sectorsAmount + 1);
      return setSectorIds((sectorIds) => [...sectorIds, e.target.value]);
    } else {
      setSectorAmount(sectorsAmount - 1);
      return setSectorIds((sectorIds) =>
        sectorIds.filter((_, i) => i !== index)
      );
    }
  }

  return (
    <>
      <NewPageHeader
      title={t("localizers")}
      subtitle={t("subtitle")}
      className="bg-white"
      >
        <ButtonContained
          className="primary-contained new-primary-contained"
          onClick={() => exportXLSX()}
          content={
            <>
            <Download fill="white"/>
            {t("exportLocalizers")}
            </>
          }
        />
      </NewPageHeader>
      <IngressoHeader position={6} evento={event_id} />
      <Container fluid className="mt-4" style={{ paddingBottom: "80px" }}>
        <Container fluid className="mt-2">
          <Card>
            <Card.Body className="card-body">
              <Form>
                <Form.Row className="col-md-12 d-flex">
                  <Form.Group className="col-md-4">
                    <Form.Label htmlFor="types">
                      {t("selectYourSector")}
                    </Form.Label>
                    <Select
                      options={options}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: 40.5,
                          minHeight: 40.5,
                        }),
                      }}
                      onChange={(e) => setSelectedSector(e.value)}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <Form.Label htmlFor="quantity">
                      {t("typeAmountLabel")}
                    </Form.Label>
                    <Form.Control
                      id="quantity"
                      name="amount"
                      className="dados2 form-control"
                      value={quantity}
                      type="number"
                      placeholder={t("typeAmount")}
                      onChange={(e) => setQuantity(parseInt(e.target.value))}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4 d-flex align-items-end">
                    <CustomButtonSpinner
                      customStyle={"btn btn-primary ml-auto"}
                      triggerOnClick={createLocalizers}
                      isLoading={createIsLoading}
                      text={t("createLocalizers")}
                      type={"submit"}
                    ></CustomButtonSpinner>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>

          <Card className="card shadow-sm">
            <Card.Body className="card-body">
              <Table
                responsive
                size={"sm"}
                id="localizers-list"
                className="card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-left"></th>
                    <th scope="col" className="text-left">
                      {t("sector")}
                    </th>
                    <th scope="col" className="text-center" width={"32%"}></th>
                    <th scope="col" className="text-center">
                      {t("totalAmount")}
                    </th>
                    <th scope="col" className="text-right">
                      {t("usedAmount")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingLocalizers && localizerList.length === 0 ? (
                    <tr align={"center"}>
                      <td colSpan={6}>{t("notFound")}</td>
                    </tr>
                  ) : (
                    localizerList.map((item, i) => (
                      <tr key={i}>
                        <td width={"5%"}>
                          <input
                            type="checkbox"
                            name="isPublic"
                            id={`sector-${item.eventSectorId}`}
                            value={item.eventSectorId}
                            defaultChecked={false}
                            onChange={(e) => handleChange(e)}
                          />
                        </td>
                        <td className="text-left" width={"10%"}>
                          {item.name}
                        </td>
                        <td>
                          <ProgressBar now={item.used} max={item.available} />
                        </td>
                        <td className="text-center" width={"20%"}>
                          {item.available}
                        </td>
                        <td className="text-right" width={"20%"}>
                          {item.used}
                        </td>
                      </tr>
                    ))
                  )}
                  {loadingLocalizers && (
                    <tr align={"center"}>
                      <td colSpan={6}>
                        <span
                          style={{ color: "#2c7be5" }}
                          className="spinner-border spinner-border-sm mx-1"
                          role="status"
                          aria-hidden="true"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default Localizadores;
