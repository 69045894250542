import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import api from "services/api.js";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { ToastContainer } from "react-toastr";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { ReactComponent as ArrowLeft } from "assets/icons/ArrowLeft.svg";
import { CircleFlag } from "react-circle-flags";
import { getStorageLocale, useLanguageSettings } from "hooks/language-settings";
import "./styles.css";

let autoComplete;
let container;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const languageOptions = [
  {
    label: "PT-BR",
    value: "pt-BR",
    icon: <CircleFlag countryCode="br" height="20px" />,
  },
  {
    label: "EN",
    value: "en-US",
    icon: <CircleFlag countryCode="us" height="20px" />,
  },
  {
    label: "ES",
    value: "es-ES",
    icon: <CircleFlag countryCode="es" height="20px" />,
  },
];

const LoginComplete = () => {
  const [usuario, setUsuario] = useState();
  const [document, setDocument] = useState();
  const [check, setCheck] = useState(false);
  const [checkErro, setCheckErro] = useState(true);
  const autoCompleteRef = useRef(null);
  const [streetErro, setStreetErro] = useState(false);
  const [number, setNumber] = useState();
  const [street, setStreet] = useState("RUA TAL");
  const [numberErro, setNumberErro] = useState(false);
  const [complement, setComplement] = useState();
  const [myIp, setMyIp] = useState();
  const [localizacao, setLocalizacao] = useState({});

  const [, /*query*/ setQuery] = useState({
    formatted_address: "",
    address_components: [],
  });

  const startLanguage = getStorageLocale().backofficeLanguage || "pt-BR";

  const [selectedLanguage, setSelectedLanguage] = useState(startLanguage);

  const { t } = useTranslation("authentication", { keyPrefix: "complete" });

  const { setLanguage } = useLanguageSettings();

  const { SingleValue } = components;

  const CustomSingleValue = (props) => {
    const clonedIcon = React.cloneElement(props.data.icon, {
      style: { marginRight: "8px" },
    });
    return (
      <SingleValue {...props}>
        {clonedIcon}
        {props.data.label}
      </SingleValue>
    );
  };

  const handleSelectLanguage = (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
    setLanguage(selectedOption.value);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        componentRestrictions: { country: "br" },
      }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = {
      formatted_address: addressObject.formatted_address,
      address_components: addressObject.address_components,
    };
    updateQuery(query);
  }

  const handleSelect = async (description) => {
    let location = {};
    location.formatted_address = description;
    let geocode = await geocodeByAddress(description);

    if (geocode[0]) {
      geocode[0].address_components.forEach((item) => {
        if (item.types.indexOf("street_number") > -1) {
          location.number = item.short_name;
        }
        if (item.types.indexOf("route") > -1) {
          location.street = item.short_name;
        }
        if (item.types.indexOf("sublocality_level_1") > -1) {
          location.neighborhood = item.short_name;
        }
        if (item.types.indexOf("administrative_area_level_2") > -1) {
          location.city = item.short_name;
        }
        if (item.types.indexOf("administrative_area_level_1") > -1) {
          location.state = item.short_name;
        }
      });

      let { lat, lng } = await getLatLng(geocode[0]);
      location.lat = `${lat}`;
      location.lng = `${lng}`;
      setLocalizacao(location);
    }
  };

  const setBaseInfo = (user) => {
    if (user.document) {
      setDocument(user.document)
    }

    if (user.address.length) {      
      const address = user.address[0]
      setNumber(address.number || "")
      setComplement(address.complement || "")

    const loc = {
      formatted_address: address.formatted_address ?? "",
      street: address.street ?? "",
      neighborhood: address.neighborhood ?? "",
      city: address.city ?? "",
      state: address.state ?? "",
      zip_code: address.zip_code ?? "",
      lat: "",
      lng: ""
    }

      setLocalizacao(loc)
    }
  }

  useEffect(() => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    setUsuario(user);
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCGpw6QCOspt7qdLnpwWV9Aw3lypy246uU&libraries=places&callback=initMap`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
    getMyIp();
    setBaseInfo(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isValidCPF(inputCPF) {
    const cpf = inputCPF.replace(/[^\d]+/g, "");
    let soma = 0;
    let resto;

    if (
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    ) {
      return false;
    }

    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;
    return true;
  }

  const salvar = (e) => {
    e.preventDefault();

    if (document.length === 14) {
      if (!isValidCPF(document)) {
        container.error(t("toast.documentInvalid"));
        return;
      }
    }
    if (!localizacao) {
      setStreetErro(true);
      return;
    } else {
      setStreetErro(false);
    }
    if (!number) {
      setNumberErro(true);
      return;
    } else {
      setNumberErro(false);
    }
    if (!check) {
      setCheckErro(false);
      return;
    } else {
      setCheckErro(true);
    }

    let data = {
      document: document,
      zip_code: localizacao.zip_code,
      street: localizacao.street,
      number: number,
      neighborhood: localizacao.neighborhood,
      city: localizacao.city,
      state: localizacao.state,
      complement: complement,
      myIp: myIp,
      myformattedaddress: localizacao.formatted_address,
    };

    api
      .post("complete/producer/login", data)
      .then((res) => {
        container.success(t("toast.loginSuccess"));
        const localUser = localStorage.getItem("user");
        const user = JSON.parse(localUser);
        localStorage.removeItem("user")
        delete user.complete_register

        localStorage.setItem("user", JSON.stringify({
          complete_register: 1,
          ...user
        }))
        window.location.href = "/eventos";
      })
      .catch((err) => {
        container.error(t("toast.loginError"));
        return;
      });
  };

  function getMyIp() {
    axios
      .get(
        "https://ipgeolocation.abstractapi.com/v1/?api_key=d6a7c2ade14f474cb79391c46c0fff0f"
      )
      .then((res) => setMyIp(res.data.ip_address))
      .catch(() => {});
  }

  return (
    <div className="container-fluid">
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div className="row">
        <div
          id="login-bg"
          className="vh-100 col-12 col-md-12 col-lg-6 col-xl-7"
        />
        <div
          id="login-content"
          className="col-12 col-md-12 col-lg-6 col-xl-5 row"
        >
          <div className="content">
            <div className="content-header">
              <Link
                to="/"
                id="password-recover"
                className="nav-link"
                style={{ marginRight: "auto", width: "9rem" }}
              >
                <ArrowLeft />
              </Link>

              <img
                style={{ width: "10%" }}
                className="page-login-img text-center"
                src="../../new_logo_login.svg"
                alt="Logo"
              />
              <div style={{ marginLeft: "auto", width: "8rem" }}>
                <Select
                  options={languageOptions}
                  value={languageOptions.filter(
                    (option) => option.value === selectedLanguage
                  )}
                  onChange={handleSelectLanguage}
                  components={{
                    IndicatorSeparator: () => null,
                    SingleValue: CustomSingleValue,
                  }}
                />
              </div>
            </div>
            {/* Heading */}
            <h1
              className="display-4 text-center mb-3 pt-3"
              style={{
                color: "#0050C3",
                fontSize: "1.25rem",
              }}
            >
              {t("title", {
                username: usuario?.first_name + " " + usuario?.last_name,
              })}
            </h1>
            <p className="text-muted text-center mb-5"></p>

            {/* Form Group */}
            <div className="form-group" style={{ marginTop: "-37px" }}>
              <label>{t("label.document")}</label>
              <CpfCnpj
                className="form-control mask_document"
                name="document"
                type="text"
                maxLength="14"
                placeholder={t("placeholder.document")}
                value={document}
                onChange={(e) => setDocument(e.target.value)}
              />
              <div className="valid-feedback">{t("document.valid")}</div>
              <div className="invalid-feedback">{t("document.invalid")}</div>
              <div className="form-group">
                <label>{t("label.address")}</label>
                {street ? (
                  <input
                    type="text"
                    className={
                      streetErro ? "form-control is-invalid" : "form-control"
                    }
                    placeholder={t("placeholder.address")}
                    value={localizacao.street}
                    onChange={() => setStreet(null)}
                  />
                ) : (
                  <GooglePlacesAutocomplete
                    inputClassName={
                      streetErro ? "form-control is-invalid" : "form-control"
                    }
                    placeholder={t("placeholder.address")}
                    value={localizacao.street}
                    onSelect={async ({ description }) => {
                      await handleSelect(description);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row" style={{ marginTop: "-7px" }}>
              <div className="col-md-6 mb-3">
                <label for="firstName">{t("label.number")}</label>
                <input
                  type="text"
                  className={
                    numberErro ? "form-control is-invalid" : "form-control"
                  }
                  name="number"
                  placeholder={t("placeholder.number")}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <div className="invalid-feedback">
                  {t("error.invalidNumber")}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label for="lastName">{t("label.complement")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("placeholder.complement")}
                  value={complement}
                  onChange={(e) => setComplement(e.target.value)}
                />
              </div>
            </div>
            <div className="form-check form-group d-flex align-items-center">
              <input
                className="form-check-input is-invalid"
                type="checkbox"
                value="1"
                id="invalidCheck3"
                onClick={() => setCheck(!check)}
              />
              <label
                className="form-check-label text-muted mt-0"
                for="invalidCheck3"
              >
                {t("terms.textStart")}{" "}
                <NavLink to="/termos-de-uso">{t("terms.link")}</NavLink>{" "}
                {t("terms.textFinish")}
              </label>
              {!checkErro && (
                <div className="invalid-feedback">
                  {t("error.uncheckedTerms")}
                </div>
              )}
            </div>

            <button
              className="btn btn-lg btn-block btn-primary c3 mb-3"
              onClick={salvar}
              // style={{background:"#600EA3"}}
            >
              {t("button")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginComplete;
