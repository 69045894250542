//common
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

//providers
import Api from "../../../../services/api.js";

//components
import Loading from "../../../../components/loading";

//assets
import "./style.css";
import NewPageHeader from "components/newPageHeader/index.js";

export default function Personalizations() {
  //Setup Financeiro
  const [fintxadmon, setFintxadmon] = useState();
  const [fintxadmPDV, setFintxadmPDV] = useState();
  const [fintxparcelamento, setFintxparcelamento] = useState();
  const [fintxmincompra, setFintxmincompra] = useState();

  const [fincustimpPDV, setFincustimpPDV] = useState();
  const [fincustimpProd, setFincustimpProd] = useState();
  const [fincustimpCanc, setFincustimpCanc] = useState();
  const [fincustimpCort, setFincustimpCort] = useState();

  const [fincustoDebito, setFincustoDebito] = useState();
  const [fincustoCredito, setFincustoCredito] = useState();
  const [fintipoCredito, setFintipoCredito] = useState();
  const [fincustoAntec, setFincustoAntec] = useState();

  //Setup Custos
  const [custxadmon, setCustxadmon] = useState();
  const [custxadmPDV, setCustxadmPDV] = useState();
  const [custxparcelamento, setCustxparcelamento] = useState();
  const [cusImpressao, setCusImpressao] = useState();

  const [cusCustoDebito, setCusCustoDebito] = useState();
  const [cusCustoCredito, setCusCustoCredito] = useState();
  const [cusTipoCredito, setCusTipoCredito] = useState();
  const [cusCustoAntec, setCusCustoAntec] = useState();

  //Setup Visual
  const [corprimaria, setCorprimaria] = useState();
  const [corsecundaria, setCorSecundaria] = useState();
  const [cortercearia, setCorTercearia] = useState();
  const [corbase, setCorbase] = useState();

  //Setup Parcelamento
  const [paymentplan1, setPaymentplan1] = useState();
  const [paymentplan2, setPaymentplan2] = useState();
  const [paymentplan3, setPaymentplan3] = useState();
  const [paymentplan4, setPaymentplan4] = useState();
  const [paymentplan5, setPaymentplan5] = useState();
  const [paymentplan6, setPaymentplan6] = useState();
  const [paymentplan7, setPaymentplan7] = useState();
  const [paymentplan8, setPaymentplan8] = useState();
  const [paymentplan9, setPaymentplan9] = useState();
  const [paymentplan10, setPaymentplan10] = useState();
  const [paymentplan11, setPaymentplan11] = useState();
  const [paymentplan12, setPaymentplan12] = useState();

  // Setup Formulários de pesquisa
  const [linkSurveyNpsClient, setLinkSurveyNpsClient] = useState();
  const [linkSurveyNpsProducer, setLinkSurveyNpsProducer] = useState();

  // Setup Comercial
  const [comercialTeamEmail, setComercialTeamEmail] = useState();

  const [transferRequestEmail, setTransferRequestEmail] = useState();

  useEffect(() => {
    Api.get("config")
      .then((res) => {
        let { configuracao, prices, taxes } = res.data;
        if (configuracao && configuracao.length) {
          configuracao.forEach((item) => {
            switch (item.tag) {
              case "_corprimaria":
                setCorprimaria(item.valor);
                CorP(item.valor);
                break;
              case "_corsecundaria":
                setCorSecundaria(item.valor);
                CorS(item.valor);
                break;
              case "_cortercearia":
                setCorTercearia(item.valor);
                CorT(item.valor);
                break;
              case "_corbase":
                setCorbase(item.valor);
                CorB(item.valor);
                break;
              case "_linkSurveyNpsClient":
                setLinkSurveyNpsClient(item.valor);
                break;
              case "_linkSurveyNpsProducer":
                setLinkSurveyNpsProducer(item.valor);
                break;
              case "_comercialTeamEmail":
                setComercialTeamEmail(item.valor);
                break;
              case "_transferRequestEmail":
                setTransferRequestEmail(item.valor);
                break;
              default:
              // code block
            }
          });
        }
        if (prices && prices.length) {
          prices.forEach((item) => {
            switch (item.tag) {
              case "_fintxadmon":
                setFintxadmon(item.value / 100);
                break;
              case "_fintxadmPDV":
                setFintxadmPDV(item.value / 100);
                break;
              case "_fintxparcelamento":
                setFintxparcelamento(item.value / 100);
                break;
              case "_fintxmincompra":
                setFintxmincompra(item.value / 100);
                break;
              case "_fincustimpPDV":
                setFincustimpPDV(item.value / 100);
                break;
              case "_fincustimpProd":
                setFincustimpProd(item.value / 100);
                break;
              case "_fincustimpCanc":
                setFincustimpCanc(item.value / 100);
                break;
              case "_fincustimpCort":
                setFincustimpCort(item.value / 100);
                break;
              case "_fincustoDebito":
                setFincustoDebito(item.value / 100);
                break;
              case "_fincustoCredito":
                setFincustoCredito(item.value / 100);
                break;
              case "_fintipoCredito":
                setFintipoCredito(item.value);
                break;
              case "_fincustoAntec":
                setFincustoAntec(item.value / 100);
                break;
              case "_custxadmon":
                setCustxadmon(item.value / 100);
                break;
              case "_custxadmPDV":
                setCustxadmPDV(item.value / 100);
                break;
              case "_custxparcelamento":
                setCustxparcelamento(item.value / 100);
                break;
              case "_cusImpressao":
                setCusImpressao(item.value / 100);
                break;
              case "_cusCustoDebito":
                setCusCustoDebito(item.value / 100);
                break;
              case "_cusCustoCredito":
                setCusCustoCredito(item.value / 100);
                break;
              case "_cusTipoCredito":
                setCusTipoCredito(item.value);
                break;
              case "_cusCustoAntec":
                setCusCustoAntec(item.value / 100);
                break;
              default:
              // code block
            }
          });
        }
        if (taxes && taxes.length) {
          taxes.forEach((item) => {
            switch (item.tag) {
              case "_1x":
                setPaymentplan1(item.value);
                break;
              case "_2x":
                setPaymentplan2(item.value);
                break;
              case "_3x":
                setPaymentplan3(item.value);
                break;
              case "_4x":
                setPaymentplan4(item.value);
                break;
              case "_5x":
                setPaymentplan5(item.value);
                break;
              case "_6x":
                setPaymentplan6(item.value);
                break;
              case "_7x":
                setPaymentplan7(item.value);
                break;
              case "_8x":
                setPaymentplan8(item.value);
                break;
              case "_9x":
                setPaymentplan9(item.value);
                break;
              case "_10x":
                setPaymentplan10(item.value);
                break;
              case "_11x":
                setPaymentplan11(item.value);
                break;
              case "_12x":
                setPaymentplan12(item.value);
                break;
              default:
              // code block
            }
          });
        }
        document.getElementById("loading").classList.add("esconde");
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }, []);

  function CorP(valor) {
    const ele = document.getElementById("pseudo-color-inputP");
    ele.setAttribute("style", "background-color:" + valor);
    setCorprimaria(valor);
  }
  function CorS(valor) {
    const ele = document.getElementById("pseudo-color-inputS");
    ele.setAttribute("style", "background-color:" + valor);
    setCorSecundaria(valor);
  }
  function CorT(valor) {
    const ele = document.getElementById("pseudo-color-inputT");
    ele.setAttribute("style", "background-color:" + valor);
    setCorTercearia(valor);
  }
  function CorB(valor) {
    const ele = document.getElementById("pseudo-color-inputB");
    ele.setAttribute("style", "background-color:" + valor);
    setCorbase(valor);
  }

  const atualizaConfiguracao = (e) => {
    e.preventDefault();
    const data = {
      fintxadmon,
      fintxadmPDV,
      fintxparcelamento,
      fintxmincompra,
      fincustimpPDV,
      fincustimpProd,
      fincustimpCanc,
      fincustimpCort,
      fincustoDebito,
      fincustoCredito,
      fintipoCredito,
      fincustoAntec,
      custxadmon,
      custxadmPDV,
      custxparcelamento,
      cusImpressao,
      cusCustoDebito,
      cusCustoCredito,
      cusTipoCredito,
      cusCustoAntec,
      corprimaria,
      corsecundaria,
      cortercearia,
      corbase,
      paymentplan1,
      paymentplan2,
      paymentplan3,
      paymentplan4,
      paymentplan5,
      paymentplan6,
      paymentplan7,
      paymentplan8,
      paymentplan9,
      paymentplan10,
      paymentplan11,
      paymentplan12,
      linkSurveyNpsClient,
      linkSurveyNpsProducer,
      comercialTeamEmail,
      transferRequestEmail,
    };
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Você tem certeza?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim atualizar!",
        cancelButtonText: "Não, desistir!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          document.getElementById("loading").classList.remove("esconde");
          Api.post("config/save", data)
            .then((res) => {
              document.getElementById("loading").classList.add("esconde");
              swalWithBootstrapButtons.fire(
                "Atualizado!",
                "Seus dados de configuração do site foram atualizados!",
                "success"
              );
            })
            .catch(() => {
              Swal.fire({
                title: "Ops!",
                icon: "error",
                text: "Ocorreu um erro interno, por favor tente novamente",
                confirmButtonText: "Ok",
              });
            })
            .finally(() =>
              document.getElementById("loading").classList.add("esconde")
            );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado!",
            "Seus dados continuam os mesmos :)",
            "error"
          );
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <>
      <Loading title="aguarde..." />

      <NewPageHeader
        title="Personalizações"
        subtitle="Aqui você pode configurar taxas e opções do sistema."
        className="bg-white"
      />

      <div id="config" className="mt-4 container-fluid">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                Setup financeiro{" "}
                <span className="tips">
                  <img
                    src="./icons/information.png"
                    alt=""
                    className="fluid imagem-quest"
                  />
                  <div className="tips-content">
                    <h3 className="tips-header">Setup financeiro</h3>
                    Configuração básica das taxas e valores cobrados dos
                    clientes.
                  </div>
                </span>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-xl-3">
                  <label>taxa administrativa online</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Taxa de conveniência cobrada dos clientes que fazem a
                      compra online.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fintxadmon || ""}
                      onChange={(event) => setFintxadmon(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>taxa administrativa PDV</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Taxa de conveniência cobrada dos clientes que fazem a
                      compra em pontos de venda.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fintxadmPDV || ""}
                      onChange={(event) => setFintxadmPDV(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>taxa por parcelamento</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Taxa percentual cobrada por parcela em compras parceladas.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fintxparcelamento || ""}
                      onChange={(event) =>
                        setFintxparcelamento(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>taxa mínima por compra</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Taxa de conveniência mínima cobrada.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        R$
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fintxmincompra || ""}
                      onChange={(event) =>
                        setFintxmincompra(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6 col-xl-3">
                  <label>custo por impressão PDV</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por unidade impresso nos pontos de venda da
                      rede credenciada.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        R$
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fincustimpPDV || ""}
                      onChange={(event) => setFincustimpPDV(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>custo por impressão produtor</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por unidade impresso nos pontos de venda do
                      produtor.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        R$
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fincustimpProd || ""}
                      onChange={(event) =>
                        setFincustimpProd(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>custo impressão cancelada</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por unidade de ingresso impresso cancelado.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        R$
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fincustimpCanc || ""}
                      onChange={(event) =>
                        setFincustimpCanc(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>custo impressão cortesia</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por unidade de cortesia impressa.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        R$
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fincustimpCort || ""}
                      onChange={(event) =>
                        setFincustimpCort(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6 col-xl-3">
                  <label>custo débito</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por transação de débito nas máquinas de
                      cartão.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fincustoDebito || ""}
                      onChange={(event) =>
                        setFincustoDebito(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>custo crédito</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por transação de crédito nas máquinas de
                      cartão.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fincustoCredito || ""}
                      onChange={(event) =>
                        setFincustoCredito(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>tipo de crédito</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Modelo de recebimento das vendas na modalidade crédito na
                      máquina de cartão.
                    </div>
                  </span>
                  <div className="input-group input-pay-method">
                    <label className="radio-inline-left">
                      {fintipoCredito === 0 ? (
                        <input
                          type="radio"
                          name="optradio"
                          value="0"
                          checked
                          onChange={(event) =>
                            setFintipoCredito(event.target.value)
                          }
                        />
                      ) : (
                        <input
                          type="radio"
                          name="optradio"
                          value="0"
                          onChange={(event) =>
                            setFintipoCredito(event.target.value)
                          }
                        />
                      )}
                      a vista
                    </label>
                    <label className="radio-inline-right">
                      {fintipoCredito === 30 ? (
                        <input
                          type="radio"
                          name="optradio"
                          value="30"
                          checked
                          onChange={(event) =>
                            setFintipoCredito(event.target.value)
                          }
                        />
                      ) : (
                        <input
                          type="radio"
                          name="optradio"
                          value="30"
                          onChange={(event) =>
                            setFintipoCredito(event.target.value)
                          }
                        />
                      )}
                      30 dias
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>custo antecipação</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por antecipação de uma venda na modalidade
                      crédito para modalidade de recebimento de crédito em 30
                      dias.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={fincustimpCort || ""}
                      onChange={(event) =>
                        setFincustimpCort(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                Setup custos{" "}
                <span className="tips">
                  <img
                    src="./icons/information.png"
                    alt=""
                    className="fluid imagem-quest"
                  />
                  <div className="tips-content">
                    Configuração das taxas e custos da plataforma, calculando
                    quanto pagamos para as adquirências e parceiros, para saber
                    a margem de lucro das operações.
                  </div>
                </span>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-xl-3">
                  <label>taxa administrativa online</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Taxa de conveniência cobrada dos clientes que fazem a
                      compra online.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={custxadmon || ""}
                      onChange={(event) => setCustxadmon(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>taxa administrativa PDV</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Taxa de conveniência cobrada dos clientes que fazem a
                      compra em pontos de venda.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={custxadmPDV || ""}
                      onChange={(event) => setCustxadmPDV(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>taxa por parcelamento</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Taxa percentual cobrada por parcela em compras parceladas.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={custxparcelamento || ""}
                      onChange={(event) =>
                        setCustxparcelamento(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>custo por impressão</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Taxa de conveniência mínima cobrada.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        R$
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={cusImpressao || ""}
                      onChange={(event) => setCusImpressao(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-xl-3">
                  <label>custo débito</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por transação de débito nas máquinas de
                      cartão.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={cusCustoDebito || ""}
                      onChange={(event) =>
                        setCusCustoDebito(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>custo crédito</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por transação de crédito nas máquinas de
                      cartão.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={cusCustoCredito || ""}
                      onChange={(event) =>
                        setCusCustoCredito(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>tipo de crédito</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Modelo de recebimento das vendas na modalidade crédito na
                      máquina de cartão.
                    </div>
                  </span>
                  <div className="input-group input-pay-method">
                    <label className="radio-inline-left">
                      {cusTipoCredito === 0 ? (
                        <input
                          type="radio"
                          name="optradio2"
                          value="0"
                          checked
                          onChange={(event) =>
                            setCusTipoCredito(event.target.value)
                          }
                        />
                      ) : (
                        <input
                          type="radio"
                          name="optradio2"
                          value="0"
                          onChange={(event) =>
                            setCusTipoCredito(event.target.value)
                          }
                        />
                      )}
                      a vista
                    </label>
                    <label className="radio-inline-right">
                      {cusTipoCredito === 30 ? (
                        <input
                          type="radio"
                          name="optradio2"
                          value="30"
                          checked
                          onChange={(event) =>
                            setCusTipoCredito(event.target.value)
                          }
                        />
                      ) : (
                        <input
                          type="radio"
                          name="optradio2"
                          value="30"
                          onChange={(event) =>
                            setCusTipoCredito(event.target.value)
                          }
                        />
                      )}
                      30 dias
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>custo antecipação</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Custo cobrado por antecipação de uma venda na modalidade
                      crédito para modalidade de recebimento de crédito em 30
                      dias.
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={cusCustoAntec || ""}
                      onChange={(event) => setCusCustoAntec(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                Setup visual{" "}
                <span className="tips">
                  <img
                    src="./icons/information.png"
                    alt=""
                    className="fluid imagem-quest"
                  />
                  <div className="tips-content">
                    Configuração das cores e fontes da plataforma.
                  </div>
                </span>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-xl-3">
                  <label>cor primária</label>
                  <div className="color-card">
                    <label
                      htmlFor="color-input"
                      className="pseudo-color"
                      id="pseudo-color-inputP"
                    ></label>
                    <input
                      type="color"
                      value={corprimaria || ""}
                      className="color-picker"
                      onChange={(event) => CorP(event.target.value)}
                    />
                    <input
                      type="text"
                      value={corprimaria || ""}
                      className="color-text"
                      onChange={(event) => CorP(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>cor secundária</label>
                  <div className="color-card">
                    <label
                      htmlFor="color-input"
                      className="pseudo-color"
                      id="pseudo-color-inputS"
                    ></label>
                    <input
                      type="color"
                      value={corsecundaria || ""}
                      className="color-picker"
                      onChange={(event) => CorS(event.target.value)}
                    />
                    <input
                      type="text"
                      value={corsecundaria || ""}
                      className="color-text"
                      onChange={(event) => CorS(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>cor terciária</label>
                  <div className="color-card">
                    <label
                      htmlFor="color-input"
                      className="pseudo-color"
                      id="pseudo-color-inputT"
                    ></label>
                    <input
                      type="color"
                      value={cortercearia || ""}
                      className="color-picker"
                      onChange={(event) => CorT(event.target.value)}
                    />
                    <input
                      type="text"
                      value={cortercearia || ""}
                      className="color-text"
                      onChange={(event) => CorT(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3">
                  <label>cor base</label>
                  <div className="color-card">
                    <label
                      htmlFor="color-input"
                      className="pseudo-color"
                      id="pseudo-color-inputB"
                    ></label>
                    <input
                      type="color"
                      value={corbase || ""}
                      className="color-picker"
                      onChange={(event) => CorB(event.target.value)}
                    />
                    <input
                      type="text"
                      value={corbase || ""}
                      className="color-text"
                      onChange={(event) => CorB(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                Setup parcelamento{" "}
                <span className="tips">
                  <img
                    src="./icons/information.png"
                    alt=""
                    className="fluid imagem-quest"
                  />
                  <div className="tips-content">
                    Configuração básica das taxas cobradas dos clientes para
                    cada parcela para compras parceladas em cartão de crédito.
                  </div>
                </span>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 col-xl-2">
                  <label>uma vez (1x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control is-invalid"
                      value={paymentplan1 || ""}
                      onChange={(event) => setPaymentplan1(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>duas vezes (2x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan2 || ""}
                      onChange={(event) => setPaymentplan2(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>três vezes (3x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan3 || ""}
                      onChange={(event) => setPaymentplan3(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>quatro vezes (4x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan4 || ""}
                      onChange={(event) => setPaymentplan4(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>cinco vezes (5x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan5 || ""}
                      onChange={(event) => setPaymentplan5(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>seis vezes (6x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan6 || ""}
                      onChange={(event) => setPaymentplan6(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-xl-2">
                  <label>sete vezes (7x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan7 || ""}
                      onChange={(event) => setPaymentplan7(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>oito vezes (8x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan8 || ""}
                      onChange={(event) => setPaymentplan8(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>nove vezes (9x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan9 || ""}
                      onChange={(event) => setPaymentplan9(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>dez vezes (10x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan10 || ""}
                      onChange={(event) => setPaymentplan10(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>onze vezes (11x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan11 || ""}
                      onChange={(event) => setPaymentplan11(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2">
                  <label>doze vezes (12x)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        %
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentplan12 || ""}
                      onChange={(event) => setPaymentplan12(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                Setup formulários de pesquisa{" "}
                <span className="tips">
                  <img
                    src="./icons/information.png"
                    alt=""
                    className="fluid imagem-quest"
                  />
                  <div className="tips-content">
                    Configuração dos links de Pesquisas de satisfação e NPS dos
                    clientes e produtores.
                  </div>
                </span>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>Formulário NPS clientes:</label>
                  <div className="input-group">
                    <div className="input-group-prepend col-md-12">
                      <input
                        type="text"
                        className="form-control col-md-12"
                        value={linkSurveyNpsClient || ""}
                        onChange={(event) =>
                          setLinkSurveyNpsClient(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <label>Formulário NPS produtores:</label>
                  <div className="input-group">
                    <div className="input-group-prepend col-md-12">
                      <input
                        type="text"
                        className="form-control col-md-12"
                        value={linkSurveyNpsProducer || ""}
                        onChange={(event) =>
                          setLinkSurveyNpsProducer(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                Setup time comercial{" "}
                <span className="tips">
                  <img
                    src="./icons/information.png"
                    alt=""
                    className="fluid imagem-quest"
                  />
                  <div className="tips-content">
                    Configuração do endereço de email do setor comercial.
                  </div>
                </span>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>Email do time comercial:</label>
                  <div className="input-group">
                    <div className="input-group-prepend col-md-12">
                      <input
                        type="text"
                        className="form-control col-md-12"
                        value={comercialTeamEmail || ""}
                        onChange={(event) =>
                          setComercialTeamEmail(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                Setup pedidos de transferência{" "}
                <span className="tips">
                  <img
                    src="./icons/information.png"
                    alt=""
                    className="fluid imagem-quest"
                  />
                  <div className="tips-content">
                    Configuração do endereço de email que receberá os pedidos de
                    transferência a serem aprovados ou não.
                  </div>
                </span>
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Email de recebimento de pedidos de transferência:
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend col-md-12">
                      <input
                        type="text"
                        className="form-control col-md-12"
                        value={transferRequestEmail || ""}
                        onChange={(event) =>
                          setTransferRequestEmail(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="box-button">
            <button
              className="btn btn-primary col-md-3 c3"
              onClick={(event) => atualizaConfiguracao(event)}
            >
              Atualizar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
