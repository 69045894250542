import { useTranslation } from "react-i18next";

export const useEventEditHelpers = () => {
  const { t } = useTranslation();
  const antifraudRiskOptions = [
    {
      label: t("events:eventEdit.salesSettings.riskLevel.options.low"),
      value: "low",
    },
    {
      label: t("events:eventEdit.salesSettings.riskLevel.options.medium"),
      value: "medium",
    },
    {
      label: t("events:eventEdit.salesSettings.riskLevel.options.high"),
      value: "high",
    },
  ];

  const antifraudTypeOptions = [
    {
      value: "show",
      label: t("events:eventEdit.salesSettings.eventCategory.options.show"),
    },
    {
      value: "theater",
      label: t("events:eventEdit.salesSettings.eventCategory.options.theater"),
    },
    {
      value: "movies",
      label: t("events:eventEdit.salesSettings.eventCategory.options.movies"),
    },
    {
      value: "party",
      label: t("events:eventEdit.salesSettings.eventCategory.options.parties"),
    },
    {
      value: "festival",
      label: t(
        "events:eventEdit.salesSettings.eventCategory.options.festivals"
      ),
    },
    {
      value: "course",
      label: t("events:eventEdit.salesSettings.eventCategory.options.courses"),
    },
    {
      value: "sports",
      label: t("events:eventEdit.salesSettings.eventCategory.options.sport"),
    },
    {
      value: "corporate",
      label: t(
        "events:eventEdit.salesSettings.eventCategory.options.corporate"
      ),
    },
  ];

  return {
    antifraudRiskOptions,
    antifraudTypeOptions,
  };
};
