import ButtonContained from "components/buttonContained";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import "./styles.css";
import New_api from "services/new-api";
import { ToastContainer } from "react-toastr";
import { saveUserData } from "utils/auth-utils";

TwoFactor.propTypes = {
  otp: PropTypes.string,
  setOtp: PropTypes.func,
  t: PropTypes.func,
  loginInformations: PropTypes.object,
  setTwoFactor: PropTypes.func,
  authData: PropTypes.object,
  selectedLanguage: PropTypes.string,
};

let container;

export function TwoFactor({
  otp,
  setOtp,
  t,
  loginInformations,
  setTwoFactor,
  authData,
  selectedLanguage,
}) {
  const { email, password } = loginInformations;
  const isOtpValid = otp && otp.length === 6;
  const [error, setError] = useState(false);
  const [timer, setTimer] = useState(60);
  const [initialOtp, setInitialOtp] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    let interval;
    if (isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
            return 60;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isButtonDisabled]);

  useEffect(() => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    setFormattedTime(
      `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
    );
  }, [timer, isButtonDisabled, t]);

  useEffect(() => {
    if (initialOtp && initialOtp !== otp) {
      setError(false);
    }
  }, [otp, initialOtp]);

  useEffect(() => {
    if (authData?.token) {
      saveUserData(authData, selectedLanguage)
      window.location.href = '/eventos'
    }
  }, [authData, selectedLanguage]);

  const handleResendCode = () => {
    setIsButtonDisabled(true);
    setTimer(60);
    handleAuth("resend")
      .then(() => {
        container.success(t("login.alert.resendToken"));
      })
      .catch(() => {
        container.error(t("login.alert.tooManyRequests"));
      });
  };

  const handleChangeOTP = (otp) => {
    setOtp(otp.toUpperCase());
  };

  const handleBack = () => {
    setTwoFactor(false);
  };

  const handleAuth = async (type) => {
    await New_api.post("/auth/token", {
      email,
      password,
      token: type === "resend" ? null : otp,
    })
      .then((res) => {
        if (type !== "resend") {
          saveUserData(res.data, selectedLanguage)

          if (res.data.user.is_admin === 1) {
            window.location.href = "/eventos";
          } else {
            window.location.href = "/eventos";
            if (res.data.user.complete_register) {
              window.location.href = "/eventos";
            } else {
              window.location.href = "/complete/login";
            }
          }
        }
      })
      .catch((error) => {
        setInitialOtp(otp);

        if (error.response && error.response.data.code === "INVALID_TOKEN") {
          setError(t("login.invalidToken"));
        }

        if (error.response && error.response.data.code === "EXPIRED_TOKEN") {
          setError(t("login.expiredToken"));
        }
      });
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    handleAuth();
  };

  return (
    <form id="form-login" className="col-12" onSubmit={handleSignUp}>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <h1 className="display-4 text-center mb-3 register-title">
        {t("login.twoFactorTitle")}
      </h1>
      <p className="text-muted text-center mb-3 register-text">
        {t("login.twoFactorText")} <strong>{email}</strong>
      </p>

      <OTPInput
        value={otp}
        onChange={handleChangeOTP}
        numInputs={6}
        containerStyle={{ maxWidth: "400px" }}
        inputStyle={`otp-input-login ${error ? "border-red" : ""}`}
        renderInput={(props) => (
          <input
            {...props}
            style={{
              padding: 0,
              textAlign: "center",
              borderColor: error ? "red" : "",
            }}
          />
        )}
        shouldAutoFocus
      />

      {error && (
        <div style={{ minWidth: "400px", padding: "0px 8px 16px 8px" }}>
          <p className="text-error-two-factor">{error}</p>
        </div>
      )}

      <button
        type="button"
        className="button-resend-code"
        onClick={handleResendCode}
        disabled={isButtonDisabled}
      >
        {isButtonDisabled
          ? `${t("login.resendToken")} (${formattedTime})`
          : t("login.resendToken")}
      </button>

      <div className="d-flex flex-column align-items-center two-factor-buttons">
        <ButtonContained
          type="submit"
          id="button-submit-login"
          disabled={!isOtpValid}
          className="primary-contained d-flex justify-content-center mt-3"
          content={t("login.enter")}
        />

        <ButtonContained
          type="button"
          id="button-back-login"
          onClick={handleBack}
          className="secondary-outlined d-flex justify-content-center mb-3"
          content={t("login.back")}
        />
      </div>

      <hr />

      <p className="login-help-text text-center">
        {t("login.needHelp")}{" "}
        <a
          className="login-talk-us"
          href={process.env.REACT_APP_WHATSAPP}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("login.talkToUs")}
        </a>
      </p>
    </form>
  );
}
