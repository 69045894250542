import React from "react";
import Swal from "sweetalert2";
import api from "services/api";
import ButtonContained from "components/buttonContained";

export default function PageHeader(props) {
  const generatorXLSX = () => {
    api
      .post(
        `/generatorPartXLSX/${"xlsx"}`,
        {
          event: props.event,
          search: props.search,
        },
        { responseType: "arraybuffer" }
      )
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${"Participantes"}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <>
      <div className="page-title container-fluid">
        <div className="col-12 page-header-infos">
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h2>{props.title}</h2>
              <p>{props.text}</p>
            </div>
            {(props.showActions === undefined || props.showActions) && (
              <>
                {props.btnText && props.btnAction ? (
                  <div className="box-btn">
                    <ButtonContained
                      onClick={props.btnAction}
                      className="primary-contained new-primary-contained"
                      disabled={props.disabled}
                      content={props.btnText}
                      />
                  </div>
                ) : props.btnLink && !props.btnAction ? (
                  <div className="box-btn">
                    <ButtonContained
                      onClick={() => window.location.href = props.btnLink}
                      className="primary-contained new-primary-contained"
                      content={props.btnText}
                      />
                  </div>
                ) : props.download ? (
                  <div className="box-btn">
                    <ButtonContained
                      onClick={(e) => generatorXLSX()}
                      className="primary-contained new-primary-contained"
                      content={"Exportar Lista"}
                      />
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
