//common
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastr";

//providers
import Api from "services/api";

//components
import ModalTicketCategory from "pages/common-area/setup/ticket-categories/modal-ticket-categories";

//assets
import "./styles.css";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";
// eslint-disable-next-line no-unused-vars
let container;

export default function TicketCategory() {
  const [statusModal, setStatusModal] = useState("modal-hidden");
  const [isId, setIsId] = useState(0);
  const [listNomen, setListNomen] = useState([]);

  const call = () => {
    Api.get(`/system/nomenclature`)
      .then((res) => {
        setListNomen(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  useEffect(() => {
    call();
  }, []);

  const del = (idNomenclature) => {
    Swal.fire({
      title: "Deletar categoria",
      icon: "warning",
      text: "Deseja realmente deletar esta categoria?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#95aac9",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Api.post(`/delete/nomenclature/${idNomenclature}`)
          .then((res) => {
            call();
            Swal.fire("Deletado!", res.data.message, "success");
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };
  const openModal = (id = null) => {
    setStatusModal("modal-show");
    setIsId(id);
  };
  // const openEditModal = () => {
  //     setEditIsOpen('show')
  // }

  function closeModal() {
    setStatusModal("modal-hidden");
    call();
  }
  // function closeEditModal() {
  //     setEditIsOpen('hide');
  // }

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      {/* <Loading title="aguarde..." text="estamos carregando as categorias :)" /> */}
      <NewPageHeader
        id="categories"
        title="Categorias de ingressos"
        subtitle="Essa é a página inicial dos tipos de categoria de ingressos."
        className="bg-white"
      >
        <ButtonContained
          className="primary-contained new-primary-contained"
          onClick={() => openModal()}
          content="Criar categoria"
        />
      </NewPageHeader>
      <div className="content mt-4 container-fluid">
        {listNomen.map((nomen) => (
          <div key={nomen.id} className="row">
            <div className="col-12">
              <div id="line-category" className="card-row shadow-sm">
                <div className="card-row-main col-12">
                  <div id="nomenclature-type" className="card-row-infos">
                    {nomen.name}
                    <div className="mobile-buttons">
                      <button
                        id="edit-category"
                        onClick={() => openModal(nomen.id)}
                        className="btn-table"
                        data-tip="Editar categoria"
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                        <span>Editar categoria</span>
                      </button>
                      <button
                        id="delete-category"
                        onClick={() => del(nomen.id)}
                        className="btn-table"
                        data-tip="Deletar categoria"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <span>Deletar categoria</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ModalTicketCategory
        status={statusModal}
        idNomenclature={isId}
        retorno={closeModal}
      />
    </>
  );
}
