import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

NewPageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default function NewPageHeader({ title, subtitle, children, className }) {
  return (
    <div className={`page-header-container w-100 ${className}`}>
      <div className="page-header-titles-container">
        <span className="page-header-title">{title}</span>
        <span className="page-header-subtitle">{subtitle}</span>
      </div>
      {children}
    </div>
  );
}
