import React, { useState } from "react";
import './styles.css';
import EquipValidation from "./equipvalidation";
import EquipSearch from "./equipsearch";
import NewPageHeader from "components/newPageHeader";

const EquipmentControl = () => {

    const [type, setType] = useState(1);

    return (
        <>
            <NewPageHeader
                title="Controle de Equipamentos"
                subtitle="Busque e gerencie o envio e retorno de equipamentos do estoque."
            />
            <div className="control-painel">
                <div className="selector">
                    <span>Modo de seleção: </span>
                    <div className="form-check">
                        <input
                            type="radio"
                            className="form-check-input"
                            id="validation"
                            name="discountType"
                            value={1}
                            defaultChecked={type}
                            onChange={e => setType(1)}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="validation"
                        >
                            Modo pesquisa
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            type="radio"
                            className="form-check-input"
                            id="search"
                            name="discountType"
                            value={2}
                            onChange={e => setType(2)}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="search"
                        >
                            Modo validador
                        </label>
                    </div>
                </div>
            </div>
            {type === 1 ? (
                <EquipSearch />

            ) : (
                <EquipValidation />
            )}
        </>
    )

}

export default EquipmentControl;
