import React, { Fragment, useEffect, useState } from "react";
import IngressoHeader from "components/subheader";
import { Container } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import api from "services/api";
import Loading from "components/loading";
import { ToastContainer } from "react-toastr";
import "./styles.css";
import { useTranslation } from "react-i18next";
import NewPageHeader from "components/newPageHeader";
let container;

export default function SortingOfSectors() {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  //States
  const [loading, setLoading] = useState(false);
  const [sectors, setSectors] = useState([]);
  // Functions

  const { t } = useTranslation();

  const handleOnDragEnd = (result) => {
    // avoids dragging elements out of the container
    if (!result.destination) return;

    // removes and adds array sectors
    const StaticSectors = Array.from(sectors);
    const originalSectors = Array.from(sectors);
    const [reorderedItem] = originalSectors.splice(result.source.index, 1);
    originalSectors.splice(result.destination.index, 0, reorderedItem);

    //put the object's current index in the index sector
    const originalSectorsWithNewIndex = originalSectors.map((item, index) => {
      return { ...item, sector_index: index };
    });

    // removing a css class "dragging"
    let sectorItem = document.getElementsByClassName("card-sector");
    for (let i = 0; i < sectorItem.length; i++) {
      sectorItem[i].classList.remove("dragging");
    }
    setSectors(originalSectorsWithNewIndex);

    const updatedSectors = originalSectorsWithNewIndex.filter(
      (item, index) => item.name !== StaticSectors[index].name
    );

    api
      .patch(`events/${event_id}/event-sectors`, {
        sectors: updatedSectors,
      })
      .then(() => {
        container.success(t("tickets:sortSectors.sortingSuccess"));
      })
      .catch(() => {
        setSectors(StaticSectors);
        container.error(t("tickets.sortSectors.sortingError"));
      });
  };

  const handleOnDragStart = (sectorDrag) => {
    let sectorItem = document.getElementsByClassName("card-sector");
    for (let i = 0; i < sectorItem.length; i++) {
      sectorItem[i].classList.remove("dragging");
    }
    sectorItem[sectorDrag.source.index].classList.add("dragging");
  };

  const listSectors = () => {
    setLoading(true);
    api
      .get(`events/sectors/${event_id}`)
      .then((res) => {
        const eventSectors = res.data.map((sector) => ({
          id: sector.id,
          name: sector.name,
          sector_index: sector.sector_index,
        }));
        setSectors(eventSectors);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <NewPageHeader
        className="bg-white"
        title={t("tickets:sortSectors.title")}
        subtitle={t("tickets:sortSectors.subtitle")}
      />
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      {loading && <Loading title={t("global:wait") + "..."} />}
      <IngressoHeader position={5} evento={event_id} />
      <Container fluid className="mt-4">
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragStart={handleOnDragStart}
        >
          <Droppable droppableId="sectors">
            {(provided) => (
              <ul
                className="sectors"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {sectors.map((sector, index) => (
                  <Draggable
                    key={sector.id}
                    draggableId={`${sector.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        className="card-sector"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="row h-100 d-flex align-items-center">
                          <div className="pl-2 pr-4">
                            <FontAwesomeIcon icon={faGripVertical} />
                          </div>
                          <div className="card-sector-name">{sector.name}</div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </Fragment>
  );
}
