import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import DataTable, { createTheme } from 'react-data-table-component';
import NewPlanosPagamentos from "./create-planos";
// eslint-disable-next-line import/no-unresolved
import api from 'services/api';
import EmptyData from "components/empty-data";
import NewPageHeader from 'components/newPageHeader';
import ButtonContained from 'components/buttonContained';

let container;
export default function PlanosPagamentos() {
  const [carregando, setCarregando] = useState(true);
  const [planos, setPlanos] = useState([]);
  const [plano, setPlano] = useState(null);
  const [visivel, setVisivel] = useState(false);

  const columns = [
    {
      name: '',
      selector: 'name',
      sortable: true,
    },
    {
      right: true,
      cell: (row) => (
        <>
          <ReactTooltip className="tooltip-custom" effect="solid" place="top" />
          <span
            className="btn-table"
            onClick={() => editaPlano(row)}
            style={{ marginRight: '8px' }}
            data-tip="Editar"
          >
            <FontAwesomeIcon icon={faPen} />
          </span>

          {/* <button
            className="btn-table"
            style={{ marginRight: "8px" }}
            // onClick={() => HandleClick(row.id)}
            data-tip="Deletar"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button> */}

        </>
      ),
    },
  ];

  createTheme('ticket', {
    text: {
      primary: '#4F6C7C',
      secondary: '#CBC1BF',
      font: 'roboto',
    },
    headertext: {
      primary: '#2F81DA',
    },
    divider: {
      default: '#e9f2f2',
    },
    highlightOnHover: {
      default: '#EEEEEE',
      text: 'rgba(0, 0, 0, 0.87)',
      divider: {
        default: '#3F25A8',
      },
    },
    button: {
      default: '#3F25A8',
      focus: '#00A7E1',
      hover: '#20C4F4',
      disabled: 'rgba(0, 0, 0, 0.12)',
    },
  });


  const carregaPlanos = () => {
    api.get('/paymentplans').then((res) => {
      setCarregando(false);
      setPlanos(res.data);
    }).catch((err) => {
      setCarregando(false);
      container.error(err.response.data.error.message);
    });
  };

  // eslint-disable-next-line no-shadow
  const editaPlano = (plano) => {
    setPlano(plano);
    setVisivel(true);

  };

  const modalRetorno = (retorno) =>{
    setPlano(null);
    setVisivel(false);
    carregaPlanos();
  }

  useEffect(() => {
    carregaPlanos();
  }, []);

  return (
    <>
      <NewPageHeader
        title="Planos de Pagamento"
        subtitle="Aqui você pode configurar os planos de parcelamento que podem ser atrelados a clientes ou organizações."
        className='bg-white'
      >
        <ButtonContained
          className="primary-contained new-primary-contained"
          onClick={(e) => editaPlano(null)}
          content={"Cadastrar Plano"}
        />
      </NewPageHeader>

      <div className="container-fluid mt-4">
        <div className="col-12 mb-5">
          <DataTable
            columns={columns}
            data={planos}
            defaultSortField="title"
            theme="ticket"
            progressPending={carregando}
            progressComponent={
              <div style={{ padding: "24px" }}>Carregando...</div>
            }
            noDataComponent={<EmptyData title="Nenhum resultado disponível." />}
            noHeader
            noTableHead
          />
        </div>
      </div>

      <NewPlanosPagamentos
        plano={plano}
        visivel={visivel}
        retorno={modalRetorno}
      />
    </>
  );
}
