import { faLink, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import ModalCodeComissarios from "pages/ticket-area/ingressos/promocionalCode/modalCodeComissarios";
import api from "services/api";

import { useCurrencyEventContext } from "contexts/currency-event-context";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils";
import { ReactComponent as ExternalLink } from "../../../../assets/icons/externalLink.svg";
import IngressoHeader from "../../../../components/subheader";
import ModalCode from "./modalCode";
import New_api from "services/new-api";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";

const LINK_DEV = process.env.REACT_APP_LINK_MARKETPLACE_DEV;

const Codigo = () => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [linkEvent, setLinkEvent] = useState("");
  const [codeID, setCodeID] = useState("");
  const [statusModal, setStatusModal] = useState("modal-hidden");
  const [statusModal2, setStatusModal2] = useState("modal-hidden");

  const [code, setCode] = useState({
    discounts: [],
    types: [],
    discount: { types: [] },
    showForm: false,
    options: [],
    percentualdesconto: true,
    status: "hide",
    event_id: null,
    discount_id: null,
  });

  const loadDiscounts = useCallback(() => {
    api
      .get(`discount/get-by-event/${event_id}`)
      .then((res) => {
        setCode({ ...code, discounts: res.data.descontos });
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("global.errors.internalServerError"),
          confirmButtonText: t("global.buttons.ok"),
        });
      });
    New_api.get(`/events/${event_id}`, {
      params: { fields: ["link_funz", "slug"] },
    }).then(({ data: { link_funz, slug } }) => {
      const link = LINK_DEV ? LINK_DEV : link_funz;
      setLinkEvent(link + slug);
    });
    // eslint-disable-next-line
  }, [event_id]);

  useEffect(() => {
    loadDiscounts();
  }, [loadDiscounts]);

  function openModal(id) {
    setCodeID(id);
    setStatusModal("modal-show");
  }
  function openModal2(id) {
    setCodeID(id);
    setStatusModal2("modal-show");
  }

  function closeModal(e) {
    setCodeID("");
    setStatusModal("modal-hidden");
    loadDiscounts();
  }
  function closeModal2(e) {
    setCodeID("");
    setStatusModal2("modal-hidden");
    loadDiscounts();
  }
  const { currency } = useCurrencyEventContext();

  return (
    <Fragment>
      <NewPageHeader
        className="bg-white"
        title={t("tickets:promotionalCode.title")}
        subtitle={t("tickets:promotionalCode.subtitle")}
      >
        <ButtonContained
          onClick={() => {
            openModal(null);
          }}
          className="primary-contained new-primary-contained"
          content={t("tickets:promotionalCode.newPromotionalCode")}
        />
      </NewPageHeader>
      <IngressoHeader position={3} evento={event_id} />
      <div className="container-fluid mt-4">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table
                id="table-totalsales"
                className="table table-sm card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-left">
                      {t("tickets:promotionalCode.promoCode")}
                    </th>
                    <th scope="col" className="text-center">
                      {t("tickets:promotionalCode.type")}
                    </th>
                    <th scope="col" className="text-center">
                      {t("tickets:promotionalCode.usedQuantity")}
                    </th>
                    <th scope="col" className="text-right">
                      {t("tickets:promotionalCode.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {code.discounts.map((item, i) => (
                    <tr key={i}>
                      <td>{item.code}</td>
                      <td className="text-center">
                        <span>
                          {item.type
                            ? `${item.value}%`
                            : formatCurrency(item.value, { currency })}
                        </span>
                      </td>
                      <td className="text-center">
                        <span>
                          {item.used === item.amount
                            ? t("tickets:promotionalCode.all")
                            : `${item.used} / ${
                                isNaN(item.amount)
                                  ? t("global:unlimited")
                                  : item.amount
                              }`}
                        </span>
                      </td>
                      <td className="text-right">
                        <div className="row d-flex justify-content-end pr-2 flex-nowrap">
                          <Link
                            className="btn-table"
                            to={{
                              pathname: `${linkEvent}?cupom=${item.code}`,
                            }}
                            data-tip=""
                            target="blank"
                          >
                            <ExternalLink />
                          </Link>
                          <button
                            className="btn-table"
                            data-tip={t(
                              "tickets:promotionalCode.linkComissioned"
                            )}
                            onClick={(e) => {
                              openModal2(item.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faLink} />
                          </button>
                          <button
                            className="btn-table"
                            data-tip={t(
                              "tickets:promotionalCode.editPromotionalCode"
                            )}
                            onClick={(e) => {
                              openModal(item.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ModalCode
        status={statusModal}
        discount_id={codeID}
        callback={closeModal}
        event_id={event_id}
      />
      <ModalCodeComissarios
        status={statusModal2}
        id={codeID}
        event_id={event_id}
        discount_id={codeID}
        callback={closeModal2}
      />
    </Fragment>
  );
};

export default Codigo;
