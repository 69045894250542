import Cookies from "js-cookie";
import { getStorageLocale } from "hooks/language-settings";


export function saveUserData(data, selectedLanguage) {
  localStorage.setItem("refresh_token", data.refreshToken);
  localStorage.setItem("token", data.token);
  localStorage.setItem("user", JSON.stringify(data.user));
  localStorage.setItem("user_id", data.user.id);
  localStorage.setItem("user_admin", data.user.is_admin);
  localStorage.setItem("email", data.user.email);

  const locale = getStorageLocale();
  const userLanguage =
    data.user.chosen_language || selectedLanguage;
  Cookies.set(
    "locale",
    JSON.stringify({
      ...locale,
      backofficeLanguage: userLanguage || locale.backofficeLanguage,
    })
  );
}
