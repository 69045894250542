export const PDV_MODULE = process.env.REACT_APP_PDV_MODULE;

const IS_WHITELABEL = process.env.REACT_APP_IS_WHITELABEL;

export const isWhitelabel = Number(IS_WHITELABEL)

export const COLORS = {
  darkBlue: "#2075F4",
  blue: "#6495ed",
  lightBlue: "#ebfaff",
  middleBlue: "#00A7E5",
  darkGray: "#4f6c7c",
  gray: "#bacad3",
  weakGray: "#dfe7eb",
  lightGray: "#F9FBFD",
  white: "#FFFFFF",
  black: "#000000",
  dark: "#98AFBC",
  silver: "#8FA5B2",
  danger: "#DC3545",
  neutralColorDarkD4: "#525059",
  neutralColorDarkD2: "#2F2E33",
  neutralColorLightL2: "#D3D2D9",
  purple: "#5B4299",
  lightPurple: "#beaee6",
};

export const FONTS_SIZE = {
  fontSize0: "1rem",
  fontSize1: "1.3rem",
  fontSize2: "1.5rem",
  fontSize3: "2.5rem",
  fontSize4: "2.8rem",
  fontSize5: "3.5rem",
};

export const FONT = "Roboto";

export const SPACINGS = {
  space0: "4px",
  space1: "8px",
  space2: "12px",
  space3: "16px",
  space4: "24px",
  space5: "35px",
  space6: "50px",
};

const sizes = {
  mobile: "321px",
  mobileL: "600px",
  tablet: "768px",
  sideBarClosed: "991px",
  desktop720p: "1280px",
  desktop1080p: "1920px",
  desktop4k: "3000px",
};

export const breakPoints = {
  mobile: `(min-width: ${sizes.mobile})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  sideBarClosed: `(min-width: ${sizes.sideBarClosed})`,
  desktop720p: `(min-width: ${sizes.desktop720p})`,
  desktop1080p: `(min-width: ${sizes.desktop1080p})`,
  desktop4k: `(min-width: ${sizes.desktop4k})`,
};

export const transferTypeAccounts = {
  savings: "Poupança",
  checking: "Corrente",
  email: "E-mail",
  document: "CPF ou CNPJ",
  phone: "Celular",
  key: "Chave Aleatória",
};

export const transferDestiny = {
  organizer: "Organizador",
  "third party": "Terceiros",
};

export const orderStatusNames = {
  estornado_parcialmente: "Estornada parcialmente",
  cancelado: "Cancelado",
  charged_back: "Chargeback",
  disputa: "Em disputa",
  "erro-adquirencia": "Erro interno",
  estornada: "Estornada",
  estornado: "Estornada",
  expirada: "Expirada",
  expirado: "Expirada",
  falhada: "Falha",
  finalizado: "Finalizada",
  fraude: "Fraude",
  pendente: "Pendente",
  pendente_estorno: "Estorno em processamento",
  "pendente-estorno-sistema": "Aguardando estorno sistema",
  retido: "Retida",
  reversed: "Revertida",
  revertida: "Revertida",
  suspeita: "Com suspeita de fraude",
  "pré-autorizada": "Em análise",
  nova: "Nova",
  autorizada: "Autorizada",
  "pendente-retentativa": "Pendente (Retentativa)",
};
export const translatedOrderStatusNames = {
  estornado_parcialmente: "orderStatusNames.partiallyRefunded",
  cancelado: "orderStatusNames.canceled",
  charged_back: "orderStatusNames.chargedBack",
  disputa: "orderStatusNames.dispute",
  "erro-adquirencia": "orderStatusNames.internalError",
  estornada: "orderStatusNames.refunded",
  estornado: "orderStatusNames.refunded",
  expirada: "orderStatusNames.expired",
  expirado: "orderStatusNames.expired",
  falhada: "orderStatusNames.failed",
  finalizado: "orderStatusNames.finished",
  fraude: "orderStatusNames.fraud",
  pendente: "orderStatusNames.pending",
  pendente_estorno: "orderStatusNames.refundProcessing",
  "pendente-estorno-sistema": "orderStatusNames.waitingRefundSystem",
  retido: "orderStatusNames.held",
  reversed: "orderStatusNames.reversed",
  revertida: "orderStatusNames.reversed",
  suspeita: "orderStatusNames.fraudSuspect",
  "pré-autorizada": "orderStatusNames.analysis",
  nova: "orderStatusNames.new",
  autorizada: "orderStatusNames.authorized",
  "pendente-retentativa": "orderStatusNames.pendingRetry",
};

export const paymentMethods = {
  Boleto: "Boleto",
  Cartão: "Cartão de Crédito",
  Dinheiro: "Dinheiro",
  PIX: "PIX",
  picpay: "PicPay",
  Débito: "Cartão de Débito",
};
export const translatedPaymentMethods = [
  {
    label: "paymentMethods.paymentBillet",
    value: "Boleto",
  },
  {
    label: "paymentMethods.paymentCard",
    value: "Cartão de Crédito",
  },
  {
    label: "paymentMethods.cash",
    value: "Dinheiro",
  },
  {
    label: "paymentMethods.pix",
    value: "PIX",
  },
  {
    label: "paymentMethods.picpay",
    value: "PicPay",
  },
  {
    label: "paymentMethods.debit",
    value: "Cartão de Débito",
  },
];

export const antifraudStatus = {
  approved: "Aprovado",
  pending: "Aguardando revisão",
  declined: "Rejeitado",
  not_authorized: "Não autorizado",
  canceled: "Cancelado",
  fraud: "Fraude confirmada",
  not_analyzed: "Não analisado",
};

export const translatedAntifraudStatus = {
  approved: "antifraudStatus.approved",
  pending: "antifraudStatus.pending",
  declined: "antifraudStatus.declined",
  not_authorized: "antifraudStatus.not_authorized",
  canceled: "antifraudStatus.canceled",
  fraud: "antifraudStatus.fraud",
  not_analyzed: "antifraudStatus.not_analyzed",
};

export const antifraudRecommendation = {
  approve: "Aprovar",
  review: "Revisar",
  decline: "Rejeitar",
};

export const translatedAntifraudRecommendation = {
  approve: "antifraudRecommendation.approve",
  review: "antifraudRecommendation.review",
  decline: "antifraudRecommendation.decline",
};

export const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

export const finalityKeys = {
  artist: "Artista",
  air: "Aéreas",
  food: "Alimentação",
  marketing: "Marketing",
  bar: "Bar",
  structure: "Estrutura",
  operation: "Operação",
  online_anticipation: "Antecipação de Recebível (Online)",
  pos_anticipation: "Antecipação de Recebível (POS)",
};

export const destinyKeys = {
  organizer: "Organizador",
  "third party": "Terceiros",
};

export const channels = [
  {
    id: 1,
    name: "communication_channel_type",
    value: "social_media",
    label: "Rede Social",
  },
  {
    id: 2,
    name: "communication_channel_type",
    value: "telephone",
    label: "Telefone",
  },
  {
    id: 3,
    name: "communication_channel_type",
    value: "email",
    label: "Email",
  },
];

export const channelSelectStyles = {
  control: (control) => ({
    ...control,
    cursor: "pointer",
    boxShadow: "none",
    width: "100%",
    padding: "0.1rem",
    borderRadius: "0.375rem",
  }),
  placeholder: (placeholder) => ({
    ...placeholder,
    color: "#b1cce6",
  }),
  option: (option, state) => ({
    ...option,
    color: !state.isSelected ? "#6e84a3" : "white",
    cursor: "pointer",
  }),
  singleValue: (singleValue) => ({
    ...singleValue,
    color: "#6e84a3",
  }),
  dropdownIndicator: (dropdownIndicator) => ({
    ...dropdownIndicator,
    color: "#6e84a3",
  }),
};

export const SponsorCategories = [
  {
    id: 1,
    name: "category",
    value: "SPONSOR",
    label: "Patrocinador",
  },
  {
    id: 2,
    name: "category",
    value: "SUPPORT",
    label: "Apoio",
  },
  {
    id: 3,
    name: "category",
    value: "PRODUCER",
    label: "Realização",
  },
  {
    id: 4,
    name: "category",
    value: "CO_PRODUCER",
    label: "Correalização",
  },
  {
    id: 5,
    name: "category",
    value: "PARTNERSHIP",
    label: "Parceria",
  },
  {
    id: 6,
    name: "category",
    value: "INSTITUTIONAL_PARTNERSHIP",
    label: "Parceria institucional",
  },
  {
    id: 7,
    name: "category",
    value: "PRESENTED_BY",
    label: "Apresentado por",
  },
];

export const genderOptions = ({
  neutral,
  masculine,
  feminine,
  nonBinary,
  notInform,
}) => [
  { value: "", label: neutral },
  { value: "FEMALE", label: feminine },
  { value: "MALE", label: masculine },
  { value: "NON_BINARY", label: nonBinary },
  { value: "NOT_INFORM", label: notInform },
];

export const paginationConfigs = {
  page: 1,
  currentPage: 0,
  lastPage: 0,
  amount: 0,
  perPage: 25,
};

export const PERMISSIONAB = PDV_MODULE === "1" && IS_WHITELABEL === "1";

export const LOCALE = {
  localize: {
    month: (n) => months[n],
    day: (n) => days[n],
  },
  formatLong: {},
};

export const CHANNEL_LABELS = {
  pos: "PDV",
  site: "Site",
  app: "App",
};

export const badgeTypes = {
  success: "custom-badge-success",
  danger: "custom-badge-danger",
  warning: "custom-badge-warning",
  custom: "custom-badge-custom",
  marketing: "custom-badge-marketing",
  info: "custom-badge-info",
  transferSuccess: "transfer-badge-success",
  transferWarning: "transfer-badge-warning",
  transferDanger: "transfer-badge-danger",
  transferLight: "transfer-badge-light",
};

export const currencies = [
  { formatted: "Real (R$)", value: "BRL" },
  { formatted: "Dollar ($)", value: "USD" },
  { formatted: "Euro (€)", value: "EUR" },
];
