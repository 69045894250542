import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as LockIcon } from "assets/icons/LockIcon.svg";
import { ReactComponent as MailIcon } from "assets/icons/MailIcon.svg";
import { useLanguageSettings } from "hooks/language-settings";
import React, { useEffect, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import "./styles.css";

import ButtonContained from "components/buttonContained";
import { Trans, useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { ToastContainer } from "react-toastr";
import New_api from "services/new-api";
import { saveUserData } from "utils/auth-utils";
import animationData from "../../../components/lotties/new-load.json";
import { TwoFactor } from "../two-factor";

let container;

const Login = () => {
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [twoFactor, setTwoFactor] = useState(false);
  const [otp, setOtp] = useState("");
  const [esqueceu, setEsqueceu] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const [errorDisplay, setErrorDisplay] = useState({
    user_not_found: "none",
    password_mismatch: "none",
    too_many_requests: "none",
  });
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [loading, setLoading] = useState(false);

  const { setLanguage } = useLanguageSettings();
  const { SingleValue } = components;
  const { t, i18n } = useTranslation(["authentication", "global"]);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const CustomSingleValue = (props) => {
    const clonedIcon = React.cloneElement(props.data.icon, {
      style: { marginRight: "8px" },
    });
    return (
      <SingleValue {...props}>
        {clonedIcon}
        {props.data.label}
      </SingleValue>
    );
  };

  const updateField = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  const authenticateRequest = (email, password) => {
    New_api.post("auth/token", { email, password })
      .then((data) => data.data)
      .then((data) => {
        if (data?.token) {
          saveUserData(data, selectedLanguage)
          window.location.href= '/eventos'
        }
        setTwoFactor(true);
      })
      .catch((err) => {
        if (err.response && err.response.data.code === "INVALID_CREDENTIALS") {
          setErrorDisplay({
            ...errorDisplay,
            password_mismatch: "block",
          });
        }

        if (err.response && err.response.data.code === "TOO_MANY_REQUESTS") {
          setTwoFactor(true);
        }
      });
  };

  const handleSignUp = (e) => {
    setErrorDisplay({
      user_not_found: "none",
      password_mismatch: "none",
    });
    e.preventDefault();
    authenticateRequest(login.email, login.password);
  };

  function CloseElement(value) {
    let alert = document.getElementById("alert-" + value);
    if (alert.classList.contains("showelement")) {
      alert.classList.remove("showelement");
    }
  }

  const solicitaAcesso = (e) => {
    e.preventDefault();
    const emailIsValid = validateEmail(login.email);
    if (!emailIsValid) {
      container.error("Email inválido.");
      return;
    }

    setLoading(true);
    New_api.post("recovery", { email: login.email })
      .then(() => {
        setEmailSent(true);
      })
      .catch(() => {
        let alert1 = document.getElementById("alert-1");
        alert1.classList.add("showelement");
        Swal.fire({
          title: t("global:errors.ops"),
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const logaounao = (e) => {
    e.preventDefault();
    setEsqueceu(!esqueceu);
  };

  function handleEvent(event) {
    var senha = document.getElementById("password-input");
    if (event === "mousedown") {
      senha.setAttribute("type", "text");
    }
    if (event === "mouseup") {
      senha.setAttribute("type", "password");
    }
  }

  const languageOptions = [
    {
      label: "PT-BR",
      value: "pt-BR",
      icon: <CircleFlag countryCode="br" height="20px" />,
    },
    {
      label: "EN",
      value: "en-US",
      icon: <CircleFlag countryCode="us" height="20px" />,
    },
    {
      label: "ES",
      value: "es-ES",
      icon: <CircleFlag countryCode="es" height="20px" />,
    },
  ];

  const handleSelectLanguage = (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  useEffect(() => {
    setLanguage(selectedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    const emailField = document.getElementById("emailField");

    if (emailField) {
      const setCustomValidityMessage = () => {
        const customMessage = t("login.emailValidation", {
          email: emailField.value,
        });
        emailField.setCustomValidity(customMessage);
      };

      emailField.oninput = () => {
        emailField.setCustomValidity("");
      };

      emailField.oninvalid = setCustomValidityMessage;

      return () => {
        emailField.oninput = null;
        emailField.oninvalid = null;
      };
    }
  }, [t]);

  const handleEmailSentButton = () => {
    setEsqueceu(false);
    setEmailSent(false);
  };

  return (
    <div className="container-fluid" id="page-login">
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div className="row">
        <div
          id="login-bg"
          className="bg-cover col-12 col-md-12 col-lg-6 col-xl-6"
        />
        <div
          id="login-content"
          className="row col-12 col-md-12 col-lg-6 col-xl-6"
        >
          <div id="login-container">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "8rem",
                alignItems: "center",
              }}
            >
              <Select
                options={languageOptions}
                value={languageOptions.filter(
                  (option) => option.value === selectedLanguage
                )}
                onChange={handleSelectLanguage}
                components={{
                  IndicatorSeparator: () => null,
                  SingleValue: CustomSingleValue,
                }}
                isSearchable={false}
              />
            </div>
            <div className="content-header">
              <img
                style={{ width: "15%" }}
                className="page-login-img text-center"
                src="../../new_logo_login.svg"
                alt="Logo"
              />
            </div>
            {esqueceu ? (
              emailSent ? (
                <>
                  <h1 className="display-4 text-center mb-5">
                    {t("emailSent")}
                  </h1>
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <p className="text-mail-sent text-center mb-3">
                      <Trans
                        i18nKey={"authentication:ifExists"}
                        components={{ strong: <strong /> }}
                        values={{ email: login.email }}
                      />
                    </p>
                    <p className="text-mail-sent text-center mb-3">
                      {t("inCase")}
                    </p>

                    <ButtonContained
                      onClick={handleEmailSentButton}
                      className="new-primary-contained"
                      content={t("login.forgot.navLink")}
                    />
                  </div>
                </>
              ) : (
                <form
                  id="form-login"
                  className="col-12"
                  onSubmit={solicitaAcesso}
                >
                  <h1 className="display-4 text-center mb-3">
                    {t("login.forgot.title")}
                  </h1>
                  <p className="text-muted text-center mb-3">
                    {t("login.forgot.text")}
                  </p>
                  <div
                    id="alert-1"
                    className="alert alert-danger alert-dismissible container-fluid"
                    style={{ display: errorDisplay.user_not_found }}
                  >
                    <span>{t("login.forgot.alert.notFound")}</span>
                    <div onClick={() => CloseElement(1)} className="close">
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                  <div
                    id="alert-2"
                    className="alert alert-success alert-dismissible container-fluid"
                  >
                    <span>{t("login.forgot.alert.emailSent")}</span>
                    <div onClick={() => CloseElement(2)} className="close">
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                  <div className="input-icon-container">
                    <MailIcon className="input-icon" stroke="#0050C3" />
                    {loading && (
                      <div className="input-icon-load">
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                          }}
                        />
                      </div>
                    )}
                    <input
                      type="email"
                      placeholder={t("login.placeholder.email")}
                      className="form-control"
                      name="email"
                      value={login.email}
                      onChange={updateField}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-primary mb-3"
                    disabled={!login.email}
                  >
                    {t("login.forgot.button")}
                  </button>
                  <hr />
                  <p id="password-recover" className="justify-content-center">
                    <span className="nav-link" onClick={(e) => logaounao(e)}>
                      {t("login.forgot.navLink")}
                    </span>
                  </p>
                </form>
            )) : twoFactor ? (
              <TwoFactor
                otp={otp}
                setOtp={setOtp}
                t={t}
                loginInformations={login}
                setTwoFactor={setTwoFactor}
                selectedLanguage={selectedLanguage}
              />
            ) :
              (
              <form id="form-login" className="col-12" onSubmit={handleSignUp}>
                <h1 className="display-4 text-center mb-3 register-title">
                  {t("login.title")}
                </h1>
                <p className="text-muted text-center mb-3 register-text">
                  {t("login.text")}
                </p>
                <div
                  id="alert-0"
                  className="alert alert-danger alert-dismissible container-fluid"
                  style={{ display: errorDisplay.user_not_found }}
                >
                  <span>{t("login.notFound")}</span>
                  <div
                    onClick={() =>
                      setErrorDisplay({
                        ...errorDisplay,
                        user_not_found: "none",
                      })
                    }
                    className="close"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <div
                  id="alert-3"
                  className="alert alert-danger alert-dismissible container-fluid"
                  style={{ display: errorDisplay.too_many_requests }}
                >
                  <span>{t("login.alert.tooManyRequests")}</span>
                  <div
                    onClick={() =>
                      setErrorDisplay({
                        ...errorDisplay,
                        too_many_requests: "none",
                      })
                    }
                    className="close"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <div
                  id="alert-0"
                  className="alert alert-danger alert-dismissible container-fluid"
                  style={{ display: errorDisplay.password_mismatch }}
                >
                  <span>{t("login.notFound")}</span>
                  <div
                    onClick={() =>
                      setErrorDisplay({
                        ...errorDisplay,
                        password_mismatch: "none",
                      })
                    }
                    className="close"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <div className="input-icon-container">
                  <MailIcon className="input-icon" stroke="#0050C3" />
                  <input
                    type="email"
                    id="emailField"
                    placeholder={t("login.placeholder.email")}
                    className="form-control form-control-appended"
                    name="email"
                    value={login.email}
                    onChange={updateField}
                  />
                </div>
                <div className="input-group input-group-merge password-field mb-3 input-icon-container">
                  <LockIcon className="input-icon" stroke="#0050C3" />
                  <input
                    type="password"
                    placeholder={t("login.placeholder.password")}
                    id="password-input"
                    className="form-control form-control-appended"
                    name="password"
                    value={login.password}
                    onChange={updateField}
                  />
                  <div
                    className="input-group-append"
                    id="eye-icon"
                    onMouseDown={() => handleEvent("mousedown")}
                    onMouseUp={() => handleEvent("mouseup")}
                  >
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faEye} />
                    </span>
                  </div>
                  <p id="password-recover">
                    <span className="nav-link" onClick={(e) => logaounao(e)}>
                      {t("login.forgottenPassword")}
                    </span>
                  </p>
                </div>

                <ButtonContained
                  type="submit"
                  id="button-submit-login"
                  disabled={!login.email || !login.password}
                  className="primary-contained d-flex"
                  content={t("login.button")}
                />
                <hr />
                <p className="login-help-text">
                  {t("login.needHelp")}{" "}
                  <a
                    className="login-talk-us"
                    href={process.env.REACT_APP_WHATSAPP}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("login.talkToUs")}
                  </a>
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
