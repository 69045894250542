import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faEyeSlash,
  faEye,
  faSearch,
  faEllipsisV,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import Api from "services/api";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastr";
import EmptyData from "components/empty-data";
import ModalProduct from "./modalProduct";
import { ImageHandlerService } from "services/image-handler";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";

let container;
let porPagina = 25;

const Products = () => {
  const [data, setData] = useState([]);
  const [, /*dataFilter*/ setDataFilter] = useState([]);
  const [modalStatus, setModalStatus] = useState("modal-hidden");
  const [product_id, setProductId] = useState(null);
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState([]);
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState([]);
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState([]);
  const [pesquisa, setPesquisa] = useState({
    pesquisa: "",
  });

  function openModal(id = null) {
    setProductId(id);
    setModalStatus("modal-show");
  }

  function closeModal() {
    setProductId(null);
    setModalStatus("modal-hidden");
  }

  const HandleActivate = (id) => {
    Swal.fire({
      title: "Ativar",
      icon: "warning",
      text: "Deseja realmente ativar este produto?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#95aac9",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    })
      .then((result) => {
        if (result.value) {
          Api.post(`products/reativate/${id}`)
            .then((res) => {
              container.success("Produto ativado com sucesso!");
              //fetchData();
            })
            .catch((err) => {
              Swal.fire("Ativar", "Produto não ativado", "error");
            });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  const HandleDeactivate = (id) => {
    Swal.fire({
      title: "Desativar",
      icon: "warning",
      text: "Deseja realmente desativar este produto?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#95aac9",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        Api.post(`products/deactivate/${id}`)
          .then((res) => {
            container.success("Produto desaativado com sucesso!");
          })
          .catch((err) => {
            Swal.fire("Desativar", "Produto não desativado", "error");
          });
      }
    });
  };

  function openDropdown(i) {
    let dropdows = document.getElementsByClassName("dropdown-buttons");
    if (!dropdows[i].classList.contains("show-dropdown")) {
      if (dropdows.length > 0) {
        for (let index = 0; index < dropdows.length; index++) {
          dropdows[index].classList.remove("show-dropdown");
        }
      }
      dropdows[i].classList.add("show-dropdown");
    } else {
      dropdows[i].classList.remove("show-dropdown");
    }
  }

  useEffect(() => {
    listProduct("initial", 1, porPagina);
    // eslint-disable-next-line
  }, []);

  const listProduct = (typePage, numberPage, perPage, search) => {
    let pageConsult = { page: numberPage, perPage: perPage, search: search };
    switch (typePage) {
      case "Initial":
        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
        break;

      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        } else {
          numberPage++;
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        }

        break;

      case "left":
        numberPage--;
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
        };
        if (numberPage <= 0) {
          pageConsult = {
            page: 1,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        }
        break;

      case "lastPage":
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
        };
        break;
      default:
        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
    }

    Api.post(`products/paginationListProducts`, pageConsult)
      .then((res) => {
        setData(res.data.data);
        setDataFilter(res.data.data);
        setAtualizaPaginaAtual(res.data.page);
        setAtualizaUltimaPagina(res.data.lastPage);
        setAtualizaQuantPesquisa(res.data.total);
      })
      .catch((err) => {
        if (err.response.data.error.status === 500) {
          container.error("Rota não encontrada");
        } else {
          container.error(err.response.data.error.message);
        }
        //Encerra loading
        if (document.getElementById("loading")) {
          document.getElementById("loading").classList.add("esconde");
        }
      });
  };

  const handleKeyDown = (product) => {
    if (product.key) {
      listProduct("initial", 1, porPagina, pesquisa.pesquisa);
    }
  };
  const updateData = (e) => {
    setPesquisa({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <NewPageHeader
        title="Produtos"
        subtitle="Lista de produtos cadastrados no sistema."
        className="bg-white"
      >
        <ButtonContained
          className="primary-contained new-primary-contained"
          onClick={() => openModal(null)}
          content="criar produto"
        />
      </NewPageHeader>

      <div className="container-fluid mt-4" id="products">
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="col-12">
          <div className="row d-flex">
            <div className="col-md-12">
              <div className="card-search">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  type="text"
                  className="input-custom"
                  name="pesquisa"
                  placeholder="Filtrar por nome do produto"
                  value={pesquisa.pesquisa || ""}
                  onChange={updateData}
                  autoComplete="off"
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 mb-2 mt-2">
          <div className="categorie-responsive">
            <div className="categorie-options">
              <div
                id="list-establishments"
                className="card-categories"
                role="table"
              >
                <div
                  className="title-categorie categorie_TableHeadRow"
                  role="row"
                >
                  <div className="categorie-temas categorie_TableCol"></div>
                  <div className="categorie-temas categorie_TableCol">
                    <div
                      role="columnheader"
                      className="categorie_TableCol_Sortable"
                    >
                      <div>Nome</div>
                    </div>
                  </div>
                  <div className="categorie-temas categorie_TableCol">
                    <div
                      role="columnheader"
                      className="categorie_TableCol_Sortable"
                    >
                      <div>Status</div>
                    </div>
                  </div>
                  <div className="categorie-temas categorie_TableCol">
                    <div
                      role="columnheader"
                      className="categorie_TableCol_Sortable"
                    >
                      <div>Composição</div>
                    </div>
                  </div>
                  <div className="categorie-temas categorie_TableCol"></div>
                </div>

                {data.length ? (
                  data.map((item, i) => (
                    <div key={i} className="shadow-sm card-establishments">
                      <div className="title-information">
                        <div
                          className="img-product"
                          style={{
                            backgroundImage: `url(${ImageHandlerService.getImageFromUrl(
                              item.picture,
                              {
                                format: "webp",
                                quality: 50,
                                resize: { width: 80, fit: "contain" },
                              },
                              "/logo-pocket.jpg"
                            )})`,
                          }}
                        ></div>
                      </div>

                      <div className="title-information categorie_TableCell card-establishments-infos">
                        <div>{item.name}</div>
                      </div>

                      <div
                        role="gridcell"
                        className="categorie-bad categorie_TableCell"
                      >
                        {item.status === 1 ? (
                          <div className="badge badge-pill badge-success">
                            Ativo
                          </div>
                        ) : (
                          <div className="badge badge-pill badge-danger">
                            Desativado
                          </div>
                        )}
                      </div>

                      <div
                        role="gridcell"
                        className="title-information categorie_TableCell"
                      >
                        <div data-tag="allowRowEvents">
                          {item.composition === 1 ? (
                            <div>Composto</div>
                          ) : (
                            <div>Simples</div>
                          )}
                        </div>
                      </div>

                      <div
                        role="gridcell"
                        className="title-information categorie_TableCell"
                      >
                        <button
                          className="dotsMobile card-list size-buttons offset-10"
                          value={i}
                          onClick={() => openDropdown(i)}
                        >
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </button>
                        <div className="dropdown-buttons dropdown-area">
                          <span
                            className="dropdown-item"
                            onClick={() => openModal(item.id)}
                            style={{ marginRight: "10px" }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                            <span>Editar</span>
                          </span>
                          {item.status === 1 ? (
                            <span
                              className="dropdown-item"
                              onClick={() => HandleDeactivate(item.id)}
                            >
                              <FontAwesomeIcon icon={faEyeSlash} />
                              <span>Desativar</span>
                            </span>
                          ) : (
                            <span
                              className="dropdown-item"
                              onClick={() => HandleActivate(item.id)}
                            >
                              <FontAwesomeIcon icon={faEye} />
                              <span>Ativar</span>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <EmptyData title="Nenhum produtos registrado." />
                )}
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row d-flex justify-content-between mb-3 datatable-footer">
              <div className="col-md-6 d-flex align-items-center">
                <span className="mr-2">Resultados por Página:</span>
                <div>
                  <select
                    name="SelecaoPorPagina"
                    onChange={(e) =>
                      listProduct("initial", 1, (porPagina = e.target.value))
                    }
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                {porPagina * atualizaPaginaAtual >= atualizaQuantPesquisa ? (
                  <span>
                    {" "}
                    Exibindo {atualizaQuantPesquisa} de {atualizaQuantPesquisa}{" "}
                  </span>
                ) : (
                  <span>
                    {" "}
                    Exibindo {porPagina * atualizaPaginaAtual} de{" "}
                    {atualizaQuantPesquisa}{" "}
                  </span>
                )}

                <button onClick={() => listProduct("initial", 1, porPagina)}>
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </button>
                <button
                  onClick={() =>
                    listProduct("left", atualizaPaginaAtual, porPagina)
                  }
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <span>
                  Página {atualizaPaginaAtual} de {atualizaUltimaPagina}
                </span>

                <button
                  onClick={() =>
                    listProduct("right", atualizaPaginaAtual, porPagina)
                  }
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>

                <button
                  onClick={() =>
                    listProduct("lastPage", atualizaUltimaPagina, porPagina)
                  }
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalProduct
        status={modalStatus}
        product_id={product_id}
        callback={closeModal}
      ></ModalProduct>
    </>
  );
};

export default Products;
