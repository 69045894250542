import React, { useEffect, useState } from "react";
import Autocomplete from "../../../components/inputautocomplete";
import Loading from "../../../components/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Api from "../../../services/api.js";
import { ToastContainer } from "react-toastr";
import ModalFaq from "./modalfaq"
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";

let container;

let questions = [];
export default function Faqs() {
  let idfaq = 0;
  const [faqLists, setFaqLists] = useState();
  const [lista, setLista] = useState();
  const [faqID, setfaqID] = useState('');
  const [statusModal, setStatusModal] = useState('modal-hidden');
  useEffect(() => {
    Api.get("faqs").then((res) => {
      setFaqLists(res.data);
      setLista(res.data);
      res.data.forEach((item) => {
        let flag = false;
        for (let index = 0; index < questions.length; index++) {
          const element = questions[index];
          if (element && element.id === item.id) {
            flag = false;
            break;
          } else {
            flag = true;
          }
        }
        if (questions.length === 0) {
          flag = true;
        }
        if (flag) {
          questions.push({ id: item.id, name: item.pergunta });
        }
      });

      //Encerra loading
      document.getElementById("loading").classList.add("esconde");
    }).catch(() => {
      Swal.fire({
        title: 'Ops!',
        icon: 'error',
        text: 'Ocorreu um erro interno, por favor tente novamente',
        confirmButtonText: 'Ok'
      })
    });
  }, []);

  const filterByID = (faq) => {
    let fl = false;
    if (faq.id === idfaq) {
      fl = true;
    }
    if (fl) {
      document.getElementById("loading").classList.add("esconde");
      return faq;
    } else {
      document.getElementById("loading").classList.add("esconde");
    }
  };

  const faqselect = (e) => {
    idfaq = e;
    document.getElementById("loading").classList.remove("esconde");
    setTimeout(() => {
      let list = faqLists.filter(filterByID);
      setLista(list);
    }, 300);
  };

  const atualizaVisibilidade = (e, item) => {
    item.active = Number(!item.active);

    Api.get("faqs/" + item.id + "/active/" + item.active)
      .then((res) => {
        if (item.active === 1) {
          container.success("FAQ ativada com sucesso!");
        } else {
          container.success("FAQ desativada com sucesso!");
        }
        Api.get("faqs").then((res) => {
          setFaqLists(res.data);
          setLista(res.data);
        });
      })
      .catch((err) => {
        container.error("FAQ não ativada/desativada!");
      });
  };

  function openModal(id) {
    setfaqID(id);
    setStatusModal('modal-show')
  }

  function closeModal(e) {
    setfaqID('');
    setStatusModal('modal-hidden');
    // document.getElementById("loading").classList.remove("esconde");
    Api.get("faqs").then((res) => {
      setFaqLists(res.data);
      setLista(res.data);
      res.data.forEach((item) => {
        let flag = false;
        for (let index = 0; index < questions.length; index++) {
          const element = questions[index];
          if (element && element.id === item.id) {
            flag = false;
            break;
          } else {
            flag = true;
          }
        }
        if (questions.length === 0) {
          flag = true;
        }
        if (flag) {
          questions.push({ id: item.id, name: item.pergunta });
        }
      });
      // //Encerra loading
      // document.getElementById("loading").classList.add("esconde");
    }).catch(() => {
      Swal.fire({
        title: 'Ops!',
        icon: 'error',
        text: 'Ocorreu um erro interno, por favor tente novamente',
        confirmButtonText: 'Ok'
      })
    });
  }

  return (
    <>
      <Loading title="aguarde..."/>

      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />

      <NewPageHeader
        title="Faqs"
        subtitle="Lista de perguntas frequentes cadastrados no sistema."
        className="bg-white"
      >
        <ButtonContained
          onClick={() => openModal(null)}
          content="Criar nova pergunta"
          className="primary-contained new-primary-contained"
        />
      </NewPageHeader>

      <div className="container-fluid mt-4">
        <div className="col-md-12">
          <div className="card-search">
            <FontAwesomeIcon icon={faSearch} className="icon-search" />
            <Autocomplete suggestions={questions} onSelectEvent={faqselect} placeholder={"Digite aqui sua pergunta"} />
          </div>
        </div>

        <div className="mt-2 col-12">
          <div className="dataTable-thead">
            <div className="dataTable-th">
              <span>Perguntas</span>
            </div>
            <div className="dataTable-th">
              <span style={{ marginLeft: "auto" }}>Visibilidade</span>
            </div>
            <div className="dataTable-th">
              <span style={{ marginLeft: "auto" }}>Ação</span>
            </div>
          </div>
        </div>

        <div id="dataTable-body" className="col-12">
          {lista?.map((question,i) => (
            <div key={i}>
              <div
                className={
                  question.visivel === 1
                    ? "dataTable-tr-active"
                    : "dataTable-tr-deactive"
                }
              >
                <div className="dataTable-td">{question.pergunta}</div>
                <div className="dataTable-td">
                  <div
                    className="custom-control custom-switch"
                    style={{ paddingLeft: "3.50em", marginLeft: "auto", position: "static" }}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={question.id}
                      name={question.id}
                      defaultChecked={question.active}
                      onChange={(e) => {
                        atualizaVisibilidade(e, question);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={question.id}
                    ></label>
                  </div>
                </div>
                <div className="dataTable-td">
                  <button
                    className="btn-table"
                    data-tip="Editar"
                    // to={`/faq/${question.id}`}
                    style={{ marginLeft: "auto" }}
                    onClick={() => openModal(question.id)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalFaq status={statusModal} id={faqID} callback={closeModal} />
    </>
  );
}
