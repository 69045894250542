import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ModalToken from "./modalToken";
import api from "services/api";
import "./styles.css";
import {
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-circular-progressbar/dist/styles.css";
import EmptyData from "components/empty-data";
import { now } from "moment/moment";
import Swal from "sweetalert2";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {dateFormatter} from "../../../../utils/date-formatter";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";

const translateStatus = {
  'Ativo': 'active',
  'Inativo': 'inactive',
}
const MyEquipments = () => {
  let userId = localStorage.getItem("user_id");
  const { t,i18n:{language} } = useTranslation(["global", "equipments"]);

  const [list, setList] = useState([]);
  const [detail, setDetail] = useState({});
  const [statusModal, setStatusModal] = useState("modal-hidden");
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState();
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState();
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState();
  const [percent, setPercent] = useState(0);
  const [reload, setReload] = useState(false);
  const [paginate, setPaginate] = useState({
    page: 1,
    perPage: 25,
  });
  const [disabled, setDisabled] = useState(true);

  function openModal(item) {
    setStatusModal("modal-show");
  }

  function closeModal() {
    setStatusModal("modal-hidden");
    setReload(true);
  }

  function addEquipment(userDetail) {
    if (!userDetail.active) {
      Swal.fire({
        title: t("inactiveLicense", { ns: "global" }),
        icon: "error",
        text: t("inactiveLicenseContactSupport", { ns: "global" }),
        confirmButtonText: t("confirmButtonText", { ns: "global" }),
      });
      return;
    } else if (moment(userDetail.validate).isBefore(now())) {
      Swal.fire({
        title: t("expiredLicense", { ns: "global" }),
        icon: "error",
        text: t("expiredLicenseContactSupport", { ns: "global" }),
        confirmButtonText: t("confirmButtonText", { ns: "global" }),
      });
      return;
    }
    openModal();
  }

  const pagination = (typePage, numberPage, perPage) => {
    switch (typePage) {
      case "Initial":
        setPaginate({
          page: numberPage,
          perPage: perPage,
        });
        break;
      case "left":
        numberPage--;
        if (numberPage <= 0) {
          setPaginate({
            page: 1,
            perPage: perPage,
          });
        } else {
          setPaginate({
            page: numberPage,
            perPage: perPage,
          });
        }
        break;
      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          setPaginate({
            page: numberPage,
            perPage: perPage,
          });
        } else {
          numberPage++;
          setPaginate({
            page: numberPage,
            perPage: perPage,
          });
        }
        break;

      case "lastPage":
        setPaginate({
          page: numberPage,
          perPage: perPage,
        });
        break;
      default:
        setPaginate({
          page: 1,
          perPage: 25,
        });
        break;
    }
  };

  useEffect(() => {
    if (userId || reload) {
      api
        .post(`userEquipments/${userId}`, paginate)
        .then((res) => {
          const detailUser = res.data.detailUser;
          setList(res.data.listEquip.data);
          setAtualizaPaginaAtual(res.data.listEquip.page);
          setAtualizaUltimaPagina(res.data.listEquip.lastPage);
          setAtualizaQuantPesquisa(res.data.listEquip.total);
          setDetail(detailUser);
          if (detailUser) {
            setPercent(
              (detailUser.licenses_in_use / detailUser.number_of_licenses) * 100
            );
            setDisabled(false);
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: t("internalServerError", { ns: "global" }),
            confirmButtonText: t("confirmButtonText", { ns: "global" }),
          });
          return err;
        });
    }
		// eslint-disable-next-line
  }, [userId, reload, paginate]);

  return (
    <>
      <NewPageHeader
        title={t("equipments:myEquipments.title")}
        className="bg-white"
      >
        <ButtonContained
          className="primary-contained new-primary-contained"
          onClick={() => addEquipment(detail)}
          disabled={disabled}
          content={t("equipments:myEquipments.header-btn-text")}
        />
      </NewPageHeader>
      <div className="container-fluid">
        <div className="equipment-general mt-3">
          {detail ? (
            <>
              <h1>{t("equipments:myEquipments.active-licenses")}</h1>
              <h2>
                {t("equipments:myEquipments.available-licenses")}
              </h2>
              <div className="card mt-4 p-3">
                <div className="row card-item">
                  <div className="col-md-2 progress-circle">
                    <CircularProgressbar
                      value={percent}
                      text={`${percent.toFixed(2)}%`}
                      styles={buildStyles({
                        pathColor: `rgba(63, 37, 171,1)`,
                        textColor: "#600EA3",
                      })}
                    />
                  </div>
                  <div className="col-md-10 licenses-infos">
                    {detail.active ? (
                      moment(detail.validate).isAfter(now()) ? (
                        <span className="badge badge-pill badge-info">
                          {t("myEquipments.used", { ns: "equipments" }) +
                            " " +
                            detail.licenses_in_use +
                            "/" +
                            detail.number_of_licenses}
                        </span>
                      ) : (
                        <span className="badge badge-pill badge-danger">
                          {t("myEquipments.expired-license", {
                            ns: "equipments",
                          })}
                        </span>
                      )
                    ) : (
                      <span className="badge badge-pill badge-secondary">
                        {t("myEquipments.inactive-license", {
                          ns: "equipments",
                        })}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="equipment-general mt-4 mb-4">
                <h1>
                  {t("myEquipments.equipment-status", { ns: "equipments" })}
                </h1>
              </div>

              <div className="card">
                <div className="table-responsive">
                  <table
                    id="table-totalsales"
                    className="table table-sm card-table"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center">
                          {t("myEquipments.devices-table.type", {
                            ns: "equipments",
                          })}
                        </th>
                        <th className="text-center">
                          {t("myEquipments.devices-table.sku", {
                            ns: "equipments",
                          })}
                        </th>
                        <th className="text-center">
                          {t("myEquipments.devices-table.patrimony", {
                            ns: "equipments",
                          })}
                        </th>
                        <th className="text-center">
                          {t("Status", { ns: "global" })}
                        </th>
                        <th className="text-center">
                          {t("myEquipments.devices-table.last-modifications", {
                            ns: "equipments",
                          })}
                        </th>
                        <th className="text-center">
                          {t("global:Event")}
                        </th>
                        <th className="text-center">
                          {t("global:User")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">{item.type}</td>
                          <td className="text-center">SKU</td>
                          <td className="text-center">{item.patrimony}</td>
                          <td className="text-center">{t(`equipments:myEquipments.${translateStatus[item.status]}`)}</td>
                          <td className="text-center">
                            {dateFormatter(item.lastmodification, {
                                shouldShowTime: true,
                                locale: language,
                            })}
                          </td>
                          <td className="text-center">
                            {t("event", { ns: "global" })}
                          </td>
                          <td className="text-center">{item.username}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="col-12">
                  <div className="row d-flex justify-content-between datatable-footer">
                    <div className="col-md-6 d-flex align-items-center">
                      <span className="mr-2">
                        {t("myEquipments.results-per-page", {
                          ns: "equipments",
                        })}
                      </span>
                      <div>
                        <select
                          name="SelecaoPorPagina"
                          onChange={(e) =>
                            pagination(
                              "Initial",
                              paginate.page,
                              (paginate.perPage = e.target.value)
                            )
                          }
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                      {paginate.perPage * atualizaPaginaAtual >=
                      atualizaQuantPesquisa ? (
                        <span>
                          {t("myEquipments.exhibitings", {
                            from: atualizaQuantPesquisa,
                            to: atualizaQuantPesquisa,
                            ns: "equipments",
                          })}
                        </span>
                      ) : (
                        <span>
                          {t("myEquipments.exhibitings", {
                            from: paginate.perPage * atualizaPaginaAtual,
                            to: atualizaQuantPesquisa,
                            ns: "equipments",
                          })}
                        </span>
                      )}
                      <button
                        onClick={() =>
                          pagination("Initial", 1, paginate.perPage)
                        }
                      >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </button>
                      <button
                        onClick={() =>
                          pagination(
                            "left",
                            atualizaPaginaAtual,
                            paginate.perPage
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </button>
                      <span>
                        {t("myEquipments.page", {
                          page: atualizaPaginaAtual,
                          pages: atualizaUltimaPagina,
                          ns: "equipments",
                        })}
                      </span>
                      <button
                        onClick={() =>
                          pagination(
                            "right",
                            atualizaPaginaAtual,
                            paginate.perPage
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>

                      <button
                        onClick={() =>
                          pagination(
                            "lastPage",
                            atualizaUltimaPagina,
                            paginate.perPage
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <EmptyData
              title={t("myEquipments.user-with-no-licenses", {
                ns: "equipments",
              })}
              text={t("myEquipments.contact-support", { ns: "equipments" })}
            />
          )}
        </div>
      </div>
      <ModalToken userId={userId} callback={closeModal} status={statusModal} />
    </>
  );
};

export default MyEquipments;
