import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./styles.css";
import upload from "../../assets/icons/upload.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import fileArrowUp from "../../assets/icons/fileArrowUp.svg";
import { ReactComponent as TrashCan } from "../../assets/icons/trashCan.svg";
import { useTranslation } from "react-i18next";

export function FileDropzone({
  title,
  callback,
  selectedFile,
  handleDownloadFileClick,
  acceptedFileTypes,
  showTitleCompletition,
  fileType,
}) {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles) => {
      callback(acceptedFiles[0]);
    },
    [callback]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    maxFiles: 1,
    onDrop,
  });

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    callback();
  };

  return (
    <div className={`dropzone-wrapper p-10`}>
      {!selectedFile ? (
        <div className="img-group position-relative" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="dropzone-content">
            <div className="icon-container">
              <img className="icon" src={upload} alt="" />
            </div>
            {title}
            {showTitleCompletition && (
              <div className="title-completition">
                <p>{t("components:dropzone.title-completition")}</p>
                <p className="file-type">{`${t(
                  "components:dropzone.file-type"
                )} ${fileType}`}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="dropcard" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="dropcard-content">
            <div className="icon-container">
              <img className="icon" src={fileArrowUp} alt="" />
            </div>
            <div>
              <p className="dropcard-title">{selectedFile?.name}</p>
              <p className="dropcard-size">{selectedFile?.size / 1024} KB</p>
            </div>
          </div>
          <div className="dropcard-buttons">
            <button
              className="dropcard-button"
              onClick={handleDownloadFileClick}
            >
              <DownloadIcon fill="none" />
            </button>
            <button className="dropcard-button" onClick={handleDeleteClick}>
              <TrashCan stroke="#1A212B" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
