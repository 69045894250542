/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import styled from "styled-components";
import Swal from "sweetalert2";

import ModalSector from "components/ingressos/ModalSector";
import SectorTable from "components/ingressos/SectorTable";
import IngressoHeader from "components/subheader";
import api from "services/api";
import { breakPoints } from "services/breakPoints";
import { COLORS, FONTS_SIZE, SPACINGS } from "services/constants";
import New_api from "services/new-api";
import EventAutomaticallyLotCheck from "components/event-automatically-lot-check";
import { useTranslation } from "react-i18next";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";

export default function IngressosHooks() {
  // eslint-disable-next-line no-undef
  const windowJS = window;
  const urlParams = new URLSearchParams(windowJS.location.search);
  const eventId = urlParams.get("event_id");
  const [refetch, setRefetch] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tickets, setTickets] = React.useState([]);
  const [lots, setLots] = React.useState([]);
  const [totalIngressos, setTotalIngressos] = React.useState({
    qty: 0,
    sold: 0,
    pending: 0,
    available: 0,
  });
  const [seatsCategories, setSeatsCategories] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [editSectorName, setEditSectorName] = React.useState();
  const [editSectorSeatsId, setEditSectorSeatsId] = React.useState();
  const [editSectorId, setEditSectorId] = React.useState();
  const [seatsEventKey, setSeatsEventKey] = React.useState();
  const [reload, setReload] = React.useState(false);
  const [automaticallyTurnsLots, setAutomaticallyTurnsLots] = React.useState();
  const { t } = useTranslation("tickets", { keyPrefix: "configuration" });

  useEffect(() => {
    New_api.get(`/events/${eventId}`, {
      params: { fields: ["automatically_turns_lots", "seats_integration_id"] },
    })
      .then(({ data: { automatically_turns_lots, seats_integration_id } }) => {
        setAutomaticallyTurnsLots(!!automatically_turns_lots);

        if (seats_integration_id) {
          setSeatsEventKey(seats_integration_id);

          return New_api.get(
            `/events/${seats_integration_id}/seats/categories`
          );
        }
      })
      .then((res) => {
        if (res?.data) {
          setSeatsCategories(res.data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tickets.length === 0) {
      setIsLoading(true);
    }
    api
      .get(`/event/tickets/${eventId}`, { params: { passports: false } })
      .then((res) => {
        if (res.data.length > 0) {
          setTickets(res.data);
          const totalIngressosMap = res.data
            .map((sectors) => sectors.tickets)
            .map((totalTickets) => {
              return totalTickets.map((lotes) => {
                return {
                  qty: lotes.qty,
                  sold: lotes.total_sold,
                  pending: lotes.qty - lotes.total_available + lotes.total_sold,
                };
              });
            })
            .flat();
          let reduced = [];
          if (totalIngressosMap.length > 0) {
            reduced = totalIngressosMap.reduce((previusLote, currentLote) => {
              return {
                qty: previusLote.qty + currentLote.qty,
                sold: previusLote.sold + currentLote.sold,
                pending: previusLote.pending + currentLote.pending,
              };
            });
          }
          setTotalIngressos(reduced);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: t("alert.title", {
            error:
              error?.response?.status || t("global:errors.internalServerError"),
          }),
          icon: "error",
          text: t("alert.error-tickets"),
        });
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setRefetch(false);
      });
    // eslint-disable-next-line
  }, [eventId, refetch, isOpen, reload]);

  function editModal(isEdit, editSectorName, sectorSeatsId, sectorId) {
    setIsEdit(isEdit);
    setEditSectorId(sectorId);
    setEditSectorName(editSectorName);
    setEditSectorSeatsId(sectorSeatsId);
  }

  function handleLoading(loadingState, reloadState) {
    setIsLoading(loadingState);
    setReload(reloadState);
  }

  const openModalSetor = () => {
    api
      .get(`/lots/event/${eventId}`)
      .then((res) => {
        setLots(res.data);
      })
      .catch((error) => {
        Swal.fire({
          title: t("alert.title", { error: error.response.status }),
          icon: "error",
          text: t("alert.error-lots"),
        });
      })
      .finally(() => {
        setIsOpen(!isOpen);
      });
  };

  return (
    <>
      <NewPageHeader
        className="bg-white"
        title={t("header.title")}
        subtitle={t("header.text")}
      >
        <ButtonContained
          onClick={() => {
            openModalSetor();
            editModal(false);
          }}
          className="primary-contained new-primary-contained"
          content={t("header.button-text")}
        />
      </NewPageHeader>
      <IngressoHeader position={0} evento={eventId} />
      {isLoading || automaticallyTurnsLots === undefined ? (
        <LoadingContainer>
          <LoadingIcon color={COLORS.darkBlue} size={80} />
          <LoadingText>{t("loading")}...</LoadingText>
        </LoadingContainer>
      ) : (
        <PageContainer>
          {automaticallyTurnsLots !== undefined && (
            <EventAutomaticallyLotCheck
              checked={automaticallyTurnsLots}
              eventId={eventId}
              onChange={setAutomaticallyTurnsLots}
            />
          )}

          <TicketsDataContainer className="container-fluid">
            {tickets.map((sector) => (
              <SectorTable
                key={sector.id}
                sectorData={sector}
                setRefetch={setRefetch}
                refetch={refetch}
                setReload={setReload}
                isPassport={false}
                editModal={editModal}
                openModalSetor={openModalSetor}
                sectorSeatsId={
                  sector.seats_category_id === "null"
                    ? null
                    : sector.seats_category_id
                }
                isAutomaticTurn={automaticallyTurnsLots}
              />
            ))}

            {totalIngressos.available !== 0 && (
              <TotalIngressosContainer className="col-12">
                <QuantidadeTotalText className="col-6 col-md-3 pl-3 pl-md-5">
                  {t("total")}:
                </QuantidadeTotalText>
                <ValorTotalText className="col-6 col-md-5">{`${totalIngressos.sold} / ${totalIngressos.qty}`}</ValorTotalText>
              </TotalIngressosContainer>
            )}
          </TicketsDataContainer>

          <ModalSector
            seatsEventKey={seatsEventKey}
            editSectorName={editSectorName}
            editSectorSeatsId={editSectorSeatsId}
            editSectorId={editSectorId}
            isEdit={isEdit}
            toggleFunction={{ isOpen, setIsOpen }}
            eventId={eventId}
            setRefetch={setRefetch}
            lotes={lots}
            tickets={tickets}
            seatsCategoriesList={seatsCategories}
            handleLoading={handleLoading}
          />
        </PageContainer>
      )}
    </>
  );
}
// /////////////////////////////////// CONTAINERs /////////////////////////

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  //60px é o tamanho do footer que aparece nesse breakpoint
  padding-bottom: calc(${SPACINGS.space6} + 60px);
  @media ${breakPoints.tablet} {
    padding-bottom: ${SPACINGS.space6};
  }
`;
const LoadingContainer = styled.div`
  flex-direction: column;
  min-width: 32px;
  height: calc(100% - 240px);
  align-items: center;
  display: flex;
  justify-content: center;
  @media ${breakPoints.tablet} {
    height: calc(100% - 180px);
  }
`;

const TotalIngressosContainer = styled.div`
  display: flex;
  height: 48px;
  background-color: ${COLORS.darkBlue};
  min-width: 290px;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  overflow: overlay;
  font-family: Rubik, sans-serif;
  @media ${breakPoints.mobileL} {
    min-width: 550px;
  }
`;

const TicketsDataContainer = styled.div`
  justify-content: center;
  padding: 0 0 ${SPACINGS.space4} 0;
  width: 100%;
`;

// /////////////////////////////////// TEXTs //////////////////////////////
const QuantidadeTotalText = styled.span`
  padding-left: 45px;
  color: ${COLORS.white};
  font-size: ${FONTS_SIZE.fontSize0};
  height: 42;
`;
const ValorTotalText = styled.span`
  text-align: right;
  color: ${COLORS.white};
  font-size: ${FONTS_SIZE.fontSize0};
  height: 42;
`;
const LoadingText = styled.span`
  color: ${COLORS.gray};
  font-size: ${FONTS_SIZE.fontSize0};
  height: 42;
  width: 80%;
  @media ${breakPoints.mobileL} {
    font-size: ${FONTS_SIZE.fontSize1};
    width: auto;
  }
`;
// /////////////////////////////////// ICONs //////////////////////////////

const LoadingIcon = styled(BiLoaderAlt)`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
