import {
	faCalendarAlt,
	faDollarSign,
	faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chart as ChartJS } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import Card from "components/card";
import { DatePicker } from "components/datepicker";
import EmptyData from "components/empty-data";
import Loading from "components/loading";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import { format, sub } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { install } from "resize-observer";
import Api from "services/api";
import Swal from "sweetalert2";
import { formatCurrency } from "utils";
import { dateFormatter } from "utils/date-formatter";
import "./styles.css";
import NewPageHeader from "components/newPageHeader";
ChartJS.register(zoomPlugin);

export default function EventSalesDate() {
  //FAVOR NAO APAGAR! ESTA FUNÇAO SERVE PARA VISUALIZAÇÃO NO SAFARI
  install();
  //FAVOR NAO APAGAR! ESTA FUNÇAO SERVE PARA VISUALIZAÇÃO NO SAFARI
  const { currency } = useCurrencyEventContext();
  const [list, setList] = useState([]);
  const [totalizer, setTotalizer] = useState(0);
  const [totalizerTick, setTotalizerTick] = useState(0);
  const [date, setDate] = useState({
    dataInit: sub(new Date(), {
      days: 15,
    }),
    dataEnd: new Date(),
  });
  const [dataChart, setDataChart] = useState({});
  const [data2, setData2] = useState({});
  const [chart, setChart] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");

  const { t, i18n } = useTranslation(["reports", "global"]);

  function showGraphTypePayment() {
    if (chart) {
      setChart(false);
    } else {
      setChart(true);
    }
  }

  const salesChart = (start, end) => {
    let data = { start, end, event_id };

    data.start = format(date.dataInit, "yyyy-MM-dd 00:00:00");
    data.end = format(date.dataEnd, "yyyy-MM-dd 23:59:59");

    Api.post(`/EventSalesDate`, data)
      .then((res) => {
        setList(
          res?.data?.salesAmount?.map((sale) => {
            const listSale = sale;
            const { qty } = res?.data?.ticketsAmount?.find(
              (ticket) => ticket.date === sale.date
            );
            return { ...listSale, qty };
          }) || []
        );
        setTotalizer(res.data.totalizerValue);
        setTotalizerTick(res.data.totalizerTickets);
        let dates = [];
        let datesTickets = [];
        let totals = [];
        let amount = [];

        res.data.salesAmount.forEach((data) => {
          const formattedDate = dateFormatter(data.created_at, {
            locale: i18n.language,
            shouldShowTime: false,
          });
          dates.push(formattedDate);
          totals.push(data.tot);
        });

        res.data.ticketsAmount.forEach((data) => {
          const formattedDate = dateFormatter(data.created_at, {
            locale: i18n.language,
            shouldShowTime: false,
          });
          datesTickets.push(formattedDate);
          amount.push(data.qty);
        });

        setDataChart({
          labels: dates,
          datasets: [
            {
              label: t("perDay.dataChartLabel.dailyValue"),
              data: totals,
              backgroundColor: "#600EA3",
              barPercentage: 0.5,
              barThickness: 12,
              minBarLength: 7,
              borderRadius: 6,
              borderSkipped: false,
            },
          ],
        });

        setData2({
          labels: datesTickets,
          datasets: [
            {
              label: t("perDay.dataChartLabel.ticketsQuantity"),
              data: amount,
              backgroundColor: "#0050C3",
              barPercentage: 1.0,
              barThickness: 12,
              minBarLength: 1,
              maxBarLength: 20,
              borderRadius: 6,
              borderSkipped: false,
            },
          ],
        });

        document.getElementById("loading").classList.add("esconde");
      })
      .catch(() => {
        Swal.fire({
          title: t("global:ops"),
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: "Ok",
        });
      });
  };

  useEffect(() => {
    salesChart(date.dataInit, date.dataEnd);
    // eslint-disable-next-line
  }, [date, i18n.language]);

  const columns = [
    {
      name: t("perDay.column.date"),
      selector: "date",
      sortable: true,
      cell: (row) => (
        <span>
          {dateFormatter(row.created_at, {
            locale: i18n.language,
            shouldShowTime: false,
          })}
        </span>
      ),
    },
    {
      name: t("perDay.column.quantity"),
      selector: "qty",
      sortable: true,
    },
    {
      name: t("perDay.column.total"),
      selector: "tot",
      sortable: true,
      cell: (row) => (
        <span>
          {row.tot
            ? formatCurrency(row.tot, { isCents: false, currency })
            : "-"}
        </span>
      ),
    },
  ];

  return (
    <Fragment>
      <NewPageHeader
        title={t("perDay.header.title")}
        subtitle={t("perDay.header.text")}
        className="bg-white"
      />
      <Loading title={t("global:loadingText")} />
      <ReactTooltip place="top" className="tooltip-custom" effect="solid" />
      <div id="body-sales" className="pagetype-tickets container-fluid mt-4">
        <div id="cards-dashboard" className="row dashboard-style">
          <div className="col-12 col-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted mb-2">
                      {t("perDay.cards.sold")}
                    </h6>
                    <span className="h2 mb-0">{totalizerTick}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 text-muted mb-0">
                      <FontAwesomeIcon icon={faTicketAlt} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col" data-tip={t("perDay.cards.tooltip")}>
                    <h6 className="text-uppercase text-muted mb-2">
                      {t("perDay.cards.total")}
                    </h6>
                    <span className="h2 mb-0">
                      {formatCurrency(totalizer, { isCents: false, currency })}
                    </span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 text-muted mb-0">
                      <FontAwesomeIcon icon={faDollarSign} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <DatePicker
                      value={date.dataInit}
                      onChange={(value) =>
                        setDate({
                          ...date,
                          dataInit: value,
                        })
                      }
                      placeholderText={t("perDay.placeholder.startDate")}
                    />
                  </div>
                  <div className="col">
                    <DatePicker
                      value={date.dataEnd}
                      onChange={(value) =>
                        setDate({
                          ...date,
                          dataEnd: value,
                        })
                      }
                      placeholderText={t("perDay.placeholder.endDate")}
                      marginLeft={[0, "-5rem", 0]}
                    />
                  </div>
                  <div className="col-auto">
                    <span className="h2 text-muted mb-0">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-12">
            <Card header={t("perDay.cards.header")}>
              <div className="row justify-content-end">
                <div className="col-sm-12 col-md-3">
                  <div className="row justify-content-center align-items-center pb-2">
                    <div>
                      <h4 className="card-header-title">
                        {t("perDay.cards.total")}
                      </h4>
                    </div>
                    <div
                      className="custom-control custom-switch m-0 justify-content-center align-items-center"
                      style={{ width: 0, paddingLeft: "47px" }}
                    >
                      <input
                        onClick={() => showGraphTypePayment()}
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitchGraph"
                      />
                      <label
                        id="amount-tickets-label"
                        className="custom-control-label mr-5"
                        htmlFor="customSwitchGraph"
                      />
                    </div>
                    <div>
                      <h4 className="card-header-title">
                        {t("perDay.dataChartLabel.ticketsQuantity")}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              {chart ? (
                <div id="chart-content">
                  <Bar
                    id="bar-chart"
                    data={dataChart}
                    width={500}
                    height={150}
                    options={{
                      tooltips: {
                        cornerRadius: 10,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                        ...(window.innerWidth <= 968 && {
                          zoom: {
                            pan: {
                              enabled: true,
                              mode: "x",
                            },
                            zoom: {
                              pinch: {
                                enabled: true, // Enable pinch zooming
                              },
                              wheel: {
                                enabled: true, // Enable wheel zooming
                              },
                              mode: "x",
                            },
                          },
                        }),
                      },
                      scales: {
                        y: {
                          ticks: {
                            color: "#95aac9",
                            callback: function (value) {
                              return formatCurrency(value, {
                                currency,
                              });
                            },
                          },
                          grid: {
                            borderDash: [3, 2],
                            borderColor: "#fff",
                          },
                        },
                        x: {
                          ticks: {
                            color: "#95aac9",
                          },
                          grid: {
                            display: false,
                            borderColor: "#fff",
                          },
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <div id="chart-content">
                  <Bar
                    data={data2}
                    width={500}
                    height={150}
                    options={{
                      tooltips: {
                        cornerRadius: 10,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                        ...(window.innerWidth <= 968 && {
                          zoom: {
                            pan: {
                              enabled: true,
                              mode: "x",
                            },
                            zoom: {
                              pinch: {
                                enabled: true, // Enable pinch zooming
                              },
                              wheel: {
                                enabled: true, // Enable wheel zooming
                              },
                              mode: "x",
                            },
                          },
                        }),
                      },
                      scales: {
                        y: {
                          ticks: {
                            color: "#95aac9",
                            beginAtZero: true,
                            callback: function (value) {
                              if (value % 1 === 0) {
                                return value;
                              }
                            },
                          },
                          grid: {
                            borderDash: [3, 2],
                            borderColor: "#fff",
                          },
                        },
                        x: {
                          ticks: {
                            color: "#95aac9",
                          },
                          grid: {
                            display: false,
                            borderColor: "#fff",
                          },
                        },
                      },
                    }}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="col-12">
            <div className="card">
              <DataTable
                columns={columns}
                data={list}
                defaultSortField="title"
                selectableRows={false}
                selectableRowsNoSelectAll={true}
                selectableRowsHighlight={false}
                expandableRows={false}
                conditionalRowStyles={[
                  {
                    when: (row) => row.expanded,
                    style: {
                      button: {
                        color: "white",
                      },
                    },
                  },
                ]}
                onRowExpandToggled={(toggleState, row) => {
                  row.expanded = toggleState;
                }}
                expandOnRowClicked={false}
                // expandableRowsComponent={<SampleExpandedComponent />}
                pagination={true}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: t("global:pagination.resultsPerPage"),
                  rangeSeparatorText: t("global:pagination.rangeSeparatorText"),
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                highlightOnHover={false}
                striped={false}
                pointerOnHover={false}
                dense={false}
                noTableHead={false}
                persistTableHead={false}
                progressPending={false}
                noHeader={true}
                subHeader={false}
                theme="ticket"
                noDataComponent={<EmptyData title={t("global:empty")} />}
                subHeaderAlign={"right"}
                fixedHeader={false}
                fixedHeaderScrollHeight="300px"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
