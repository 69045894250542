import { faEllipsisV, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

import Badge from "components/badge";
import DropdownButton from "components/dropdownButton";
import EmptyData from "components/empty-data";
import Loading from "components/loading";
import Pagination from "components/Pagination";
import IngressoHeader from "components/subheader";
import New_api from "services/new-api";
import { ReactComponent as InvalidTicket } from "../../../../assets/icons/invalidTicket.svg";
import { ReactComponent as PaperPlane } from "../../../../assets/icons/paper-plane.svg";
import { ReactComponent as Download2 } from "../../../../assets/icons/download2.svg";
import { ReactComponent as ValidTicket } from "../../../../assets/icons/validTicket.svg";
import ModalCourtesyGenerator from "./modalCourtesyGenerator";

import "./styles.css";
import { useTranslation } from "react-i18next";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";

const builderTicketUrl = process.env.REACT_APP_TICKET_COURTESY_BUILDER_URL;

const switchStyles = {
  control: (control) => ({
    ...control,
    cursor: "pointer",
    boxShadow: "none",
    width: "100%",
    padding: "0.1rem",
    borderColor: "#ced4da",
    borderRadius: "0.375rem",
  }),
  placeholder: (placeholder) => ({
    ...placeholder,
    color: "#b1cce6",
  }),
  option: (option, state) => ({
    ...option,
    color: !state.isSelected ? "#6e84a3" : "white",
    cursor: "pointer",
  }),
  singleValue: (singleValue) => ({
    ...singleValue,
    color: "#6e84a3",
  }),
  dropdownIndicator: (dropdownIndicator) => ({
    ...dropdownIndicator,
    color: "#6e84a3",
  }),
};

const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_PER_PAGE = 25;

export default function Courtesies() {
  // Params
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const event_id = params.get("event_id");
  //

  // States
  const [courtesies, setCourtesies] = useState([]);
  const [slug, setSlug] = useState("");
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [sector, setSector] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(0);
  const [showOptions, setShowOptions] = useState({ id: false });
  const [filtersState, setFilters] = useState({
    event_sector_lot_id: "",
    nickname: "",
    email: "",
    full_attendee_name: "",
  });
  const [pageConfig, setPageConfig] = useState({
    page: DEFAULT_CURRENT_PAGE,
    perPage: DEFAULT_PER_PAGE,
    currentPage: 0,
    lastPage: 0,
    amount: 0,
  });
  //

  // Functions
  const openModal = () => setOpen(true);
  const closeModal = (reload) => {
    setOpen(false);
    if (reload) courtesiesList(DEFAULT_CURRENT_PAGE, DEFAULT_PER_PAGE);
  };

  const { t } = useTranslation(["tickets", "global"], {
    keyPrefix: "courtesies",
  });
  const { t: globalTranslation } = useTranslation();

  const courtesiesList = (page, perPage, filters) => {
    const params = {
      page: page || pageConfig.page,
      per_page: perPage || pageConfig.perPage,
      ...(filters?.nickname && {
        nickname: filters?.nickname,
      }),
      ...(filters?.email && {
        email: filters?.email,
      }),
      ...(filters?.event_sector_lot_id && {
        event_sector_lot_id: filters?.event_sector_lot_id,
      }),
      ...(filters?.full_attendee_name && {
        full_attendee_name: filters?.full_attendee_name,
      }),
    };

    setLoading(true);

    New_api.get(`/events/${event_id}/courtesies`, { params })
      .then((res) => {
        setPageConfig({
          page: res.data.meta.current_page,
          currentPage: res.data.meta.current_page,
          lastPage: res.data.meta.last_page,
          amount: res.data.meta.total,
          perPage: res.data.meta.per_page,
        });
        setCourtesies(
          res.data.data.sort((a, b) => {
            if (a.created_at === b.created_at) {
              if (a.attendee_name === null && b.attendee_name !== null)
                return -1;
              if (a.attendee_name !== null && b.attendee_name === null)
                return 1;
              if (a.attendee_name === null && b.attendee_name === null)
                return 0;
              return a.attendee_name.localeCompare(b.attendee_name);
            } else {
              return 0;
            }
          })
        );
      })
      .catch(() =>
        Swal.fire({
          title: globalTranslation("global:errors.ops"),
          icon: "error",
          text: globalTranslation("global:errors.internalServerError"),
          confirmButtonText: globalTranslation("global:confirmButtonText"),
        })
      )
      .finally(() => setLoading(false));
  };

  const getSlug = () => {
    New_api.get(`/events/${event_id}`, { params: { fields: ["slug"] } }).then(
      ({ data: { slug } }) => {
        setSlug(slug);
      }
    );
  };

  const sendCourtesy = (id) => {
    Swal.fire({
      icon: "warning",
      title: t("alert.warningTitle"),
      text: t("alert.send.warningText"),
      showCancelButton: true,
      confirmButtonText: globalTranslation("global:yes"),
      cancelButtonText: globalTranslation("global:no"),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        New_api.post(`/courtesies/${id}/send-mail`)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: t("alert.successTitle"),
              icon: "success",
              text: t("alert.send.successText"),
              confirmButtonText: globalTranslation("global:confirmButtonText"),
            });
          })
          .catch(() =>
            Swal.fire({
              title: globalTranslation("global:errors.ops"),
              icon: "error",
              text: globalTranslation("global:errors.internalServerError"),
              confirmButtonText: globalTranslation("global:confirmButtonText"),
            }).finally(() => setLoading(false))
          );
      }
    });
  };

  const listSectors = () => {
    New_api.get(`/events/${event_id}/event_sectors`).then((res) => {
      setSectors(
        res.data.data.map((sector) => ({
          name: "event_sector_lot_id",
          id: sector.id,
          value: sector?.event_sector_lots?.map(
            (event_sector_lot) => event_sector_lot.id
          ),
          label: sector.name,
        }))
      );
    });
  };

  const downloadCourtesy = (courtesyCode) => {
    const code = Buffer.from(`${courtesyCode}$${event_id}`).toString("base64");
    window.open(`${builderTicketUrl}${code}`);
  };

  const uptadeStatusCourtesy = (id, status) => {
    Swal.fire({
      icon: "warning",
      title: t("alert.warningTitle"),
      text: t("alert.update.warningText"),
      showCancelButton: true,
      confirmButtonText: globalTranslation("global:yes"),
      cancelButtonText: globalTranslation("global:no"),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        New_api.put(`/courtesies/${id}/change-status`, {
          status: !Boolean(status),
        })
          .then(() => {
            Swal.fire({
              title: t("alert.successTitle"),
              icon: "success",
              text: t("alert.update.successText"),
              confirmButtonText: t("alert.update.ok"),
            });
            courtesiesList();
          })
          .catch(() =>
            Swal.fire({
              title: globalTranslation("global:errors.ops"),
              icon: "error",
              text: globalTranslation("global:errors.internalServerError"),
              confirmButtonText: globalTranslation("global:confirmButtonText"),
            }).finally(() => setLoading(false))
          );
      }
    });
  };

  const handleFilter = () => {
    if (appliedFilters > 0) {
      setAppliedFilters(0);
      setSector(null);
      setFilters({
        event_sector_lot_id: "",
        nickname: "",
        email: "",
        full_attendee_name: "",
      });
      courtesiesList(DEFAULT_CURRENT_PAGE, DEFAULT_PER_PAGE, {
        event_sector_lot_id: "",
        nickname: "",
        email: "",
        full_attendee_name: "",
      });
    } else {
      let filterCounter = 0;
      filterCounter += filtersState.email !== "" ? 1 : 0;
      filterCounter += filtersState.event_sector_lot_id ? 1 : 0;
      filterCounter += filtersState.full_attendee_name !== "" ? 1 : 0;
      filterCounter += filtersState.nickname !== "" ? 1 : 0;

      setAppliedFilters(filterCounter);
      courtesiesList(DEFAULT_CURRENT_PAGE, DEFAULT_PER_PAGE, filtersState);
    }
  };
  const updateField = (data) => {
    setAppliedFilters(0);
    setFilters({
      ...filtersState,
      [data.target.name]: data.target.value,
    });
  };

  const selectedSector = (sector) => {
    setAppliedFilters(0);
    setSector(sector);
    setFilters({
      ...filtersState,
      [sector.name]: sector.value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleFilter();
    }
  };

  const handleModalOptions = (id) => {
    setShowOptions(() => ({
      [id]: true,
    }));
  };

  const options = (enabled) => {
    return [
      {
        children: (
          <Fragment>
            <PaperPlane stroke="#767380" />
            {t("options.send")}
          </Fragment>
        ),
        callback: sendCourtesy,
        params: ["id"],
      },
      {
        children: (
          <Fragment>
            <Download2 />
            {t("options.download")}
          </Fragment>
        ),
        callback: downloadCourtesy,
        params: ["code"],
      },
      {
        children: !enabled ? (
          <Fragment>
            <ValidTicket />
            {t("options.activate")}
          </Fragment>
        ) : (
          <Fragment>
            <InvalidTicket />
            {t("options.deactivate")}
          </Fragment>
        ),
        callback: uptadeStatusCourtesy,
        params: ["id", "enabled"],
      },
    ];
  };

  // const [options, setOptions] = useState();

  useEffect(() => {
    courtesiesList();
    listSectors();
    getSlug();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <NewPageHeader
        className="bg-white"
        title={t("header.title")}
        subtitle={t("header.text")}
      >
        <ButtonContained
          onClick={() => {
            openModal();
          }}
          className="primary-contained new-primary-contained"
          content={t("header.button")}
        />
      </NewPageHeader>
      <IngressoHeader position={1} evento={event_id} />
      {loading && <Loading title={t("loading") + "..."} />}
      <Container fluid>
        <Card className="mt-3 p-3">
          <Row className="pdv-sales-filters">
            <Col sm={12} md className="mt-1">
              <Select
                value={sector}
                name="event_sector_lot_id"
                styles={switchStyles}
                className="col-12 p-0"
                onKeyDown={handleKeyDown}
                onChange={selectedSector}
                placeholder={t("placeholders.sectors")}
                options={sectors}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </Col>
            <Col sm={12} md className="mt-1">
              <input
                name="nickname"
                value={filtersState.nickname}
                className="form-control"
                onChange={updateField}
                onKeyDown={handleKeyDown}
                placeholder={t("placeholders.nickname")}
              />
            </Col>
            <Col sm={12} md className="mt-1">
              <input
                name="full_attendee_name"
                value={filtersState.full_attendee_name}
                onChange={updateField}
                onKeyDown={handleKeyDown}
                className="form-control"
                placeholder={t("placeholders.attendeeName")}
              />
            </Col>
            <Col sm={12} md className="mt-1">
              <input
                name="email"
                value={filtersState.email}
                onChange={updateField}
                onKeyDown={handleKeyDown}
                className="form-control"
                placeholder={t("placeholders.email")}
              />
            </Col>
            <Col sm={12} md className="mt-1">
              <button
                type="button"
                className={`btn ${
                  appliedFilters > 0
                    ? "btn-outline-secondary"
                    : "btn-outline-primary"
                } w-100`}
                onClick={handleFilter}
              >
                <span className="filter-icon-container">
                  <FontAwesomeIcon icon={faFilter} />
                  {appliedFilters > 0 && (
                    <div className="filter-counter-courtesies">
                      {appliedFilters}
                    </div>
                  )}
                </span>
                {t(
                  appliedFilters > 0
                    ? "filterButton.clear"
                    : "filterButton.apply"
                )}
              </button>
            </Col>
          </Row>
        </Card>
        <Card className="p-3">
          {courtesies.length > 0 ? (
            <div className="col-12 table-responsive">
              <div className="table-small-desktop">
                <table className="table-courtesy">
                  <thead className="table-header-courtesy">
                    <tr>
                      <th scope="col">{t("columns.status")}</th>
                      <th scope="col">{t("columns.attendeeName")}</th>
                      <th scope="col">{t("columns.email")}</th>
                      <th scope="col">{t("columns.sector")}</th>
                      <th scope="col" className="text-center">
                        {t("columns.quantity")}
                      </th>
                      <th scope="col">{t("columns.generatedAt")}:</th>
                      <th scope="col">{t("columns.code")}</th>
                      <th scope="col">{t("columns.description")}</th>
                      <th scope="col">{t("columns.generatedBy")}:</th>
                      <th scope="col">{t("columns.printedAt")}:</th>
                      <th scope="col">{t("columns.identifier")}</th>
                      <th scope="col">{t("columns.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courtesies.map((courtesy, index) => (
                      <tr className="table-row-courtesy" key={courtesy.id}>
                        <td className="table-cell-courtesy">
                          <Badge
                            status={
                              Boolean(courtesy.enabled) ? "success" : "danger"
                            }
                            text={t(
                              Boolean(courtesy.enabled) > 0
                                ? "status.active"
                                : "status.inactive"
                            )}
                          />
                        </td>
                        <td className="table-cell-courtesy">
                          {!!courtesy.attendee_name
                            ? `${courtesy.attendee_name} ${courtesy.attendee_lastname}`
                            : "-"}
                        </td>
                        <td className="table-cell-courtesy">
                          {courtesy.receiver_mail}
                        </td>
                        <td className="table-cell-courtesy">
                          {courtesy.event_sector_lot?.event_sector?.name}
                        </td>
                        <td className="table-cell-courtesy cell-quantity">
                          {courtesy.amount}
                        </td>
                        <td className="table-cell-courtesy">
                          {courtesy.created_at
                            ? format(
                                new Date(courtesy.created_at),
                                "dd/MM/yyyy HH:mm:ss"
                              )
                            : "-"}
                        </td>
                        <td className="table-cell-courtesy">
                          {courtesy.order.token_ticket}
                        </td>
                        <td className="table-cell-courtesy">
                          {courtesy.description}
                        </td>
                        <td className="table-cell-courtesy">
                          {courtesy.user.name}
                        </td>
                        <td className="table-cell-courtesy">
                          {!!courtesy.printed_at
                            ? format(
                                new Date(courtesy.printed_at),
                                "dd/MM/yyyy HH:mm:ss"
                              )
                            : "-"}
                        </td>
                        <td className="table-cell-courtesy">
                          {courtesy.nickname}
                        </td>
                        <td className="d-flex align-items-center justify-content-center table-cell-courtesy-actions">
                          <DropdownButton
                            index={index}
                            itensAmount={courtesies?.length}
                            id={courtesy?.id}
                            data={courtesy}
                            element={
                              <button
                                type="button"
                                className="dotsMobile"
                                onClick={() => handleModalOptions(courtesy?.id)}
                              >
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </button>
                            }
                            statusIndex={showOptions}
                            onMouseLeave={handleModalOptions}
                            options={options(courtesy?.enabled)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                pageConfig={pageConfig}
                parentFunction={courtesiesList}
                filters={filtersState}
              />
            </div>
          ) : (
            <EmptyData title={t("emptyData")} />
          )}
        </Card>
      </Container>
      <ModalCourtesyGenerator
        downloadCourtesy={downloadCourtesy}
        sendCourtesy={sendCourtesy}
        event_id={event_id}
        slug={slug}
        toggleFunction={{ isOpen, closeModal }}
        refresh={courtesiesList}
      />
    </Fragment>
  );
}
