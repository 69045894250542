//Common
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { ToastContainer } from "react-toastr";
import QRCode from "qrcode.react";
import Swal from "sweetalert2";
import { COLORS, genderOptions } from "services/constants";
import "./styles.css";

//Providers
import Api from "services/api.js";
import { cpfMask, phoneMask, cepMask } from "mask.js";
import axios from "axios";
import ReactSwitch from "react-switch";
import ButtonContained from "components/buttonContained";
import { ReactComponent as PaperPlane } from "../../../../assets/icons/paper-plane.svg";
import { ReactComponent as Info } from "../../../../assets/icons/info.svg";
import New_api from "services/new-api";
import animationData from "../../../../components/lotties/new-load.json";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";

let container;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ModalUsers = (props) => {
  const { t } = useTranslation("users");
  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user.type || [];
  const isAdmin = userRole === "GOD";

  const [unchangedEmail, setUnchangedEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [register, setRegister] = useState({
    id: "",
    document: "",
    telephone: "",
    user_id: "",
    address_id: "",
    address: {
      id: "",
      zip_code: "",
      street: "",
      neighborhood: "",
      complement: "",
      state: "",
      city: "",
      number: "",
    },
    user: {
      id: "",
      email: "",
      first_name: "",
      last_name: "",
      gender: "",
      facebook: "",
      instagram: "",
      x_twitter: "",
      password: "",
      confirmpassword: "",
      qrcode: "",
      force_threeds_mode: false,
    },
  });

  const updateUser = (e) => {
    setRegister({
      ...register,
      user: {
        ...register.user,
        [e.target.name]: e.target.value,
      },
    });
  };

  const updateCpf = (e) => {
    setRegister({
      ...register,
      [e.target.name]: cpfMask(e.target.value),
    });
  };

  const updatePhone = (e) => {
    setRegister({
      ...register,
      [e.target.name]: phoneMask(e.target.value),
    });
  };

  const updateCep = (e) => {
    setRegister({
      ...register,
      address: {
        ...register.address,
        [e.target.name]: cepMask(e.target.value),
      },
    });
  };
  const updateAddress = (e) => {
    setRegister({
      ...register,
      address: {
        ...register.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  const updateSocial = (e) => {
    setRegister({
      ...register,
      user: {
        ...register.user,
        [e.target.name]: e.target.value,
      },
    });
  };

  const updateForcedThreeds = (value) => {
    setRegister({
      ...register,
      user: { ...register.user, force_threeds_mode: value },
    });
  };

  const registerUser = (e, sendRecovery = false) => {
    e && e.preventDefault();

    if (props.id) {
      if (
        register.user.email === "" ||
        register.user.first_name === "" ||
        register.user.last_name === "" ||
        register.user.gender === ""
      ) {
        container.error(t("container.error.requiredFields"));
      } else if (
        register.telephone !== "" &&
        register.telephone &&
        register.telephone.length < 14
      ) {
        container.error(t("container.error.invalid", {
          method: t("modal.secondStep.phone")
        }));
      } else if (!emailRegex.test(register.user.email.toLowerCase())) {
        container.error(t("container.error.invalid", {
          method: t("table.email")
        }));
      } else if (register.user.password === "") {
        register.user.password = undefined;
      } else {
        if (register.user.password === "") {
          register.user.password = undefined;
        }
        Api.post("useredit", register)
          .then(() => {
            if (sendRecovery) {
              setLoading(true)
              New_api.post('/recovery', { email: register.user.email})
              .then(() => {
                container.success(t("container.success.edit"));
                container.success(t("container.success.mailSent"));
              })
              .catch(() => {
                container.error(t("container.error.generic"));
              })
              .finally(() => {
                setLoading(false)
              })
            } else {
              container.success(t("container.success.edit"));
            }
            close(null, true);
          })
          .catch((e) => {
            let message = t("container.error.internal")
            if ((e.response.data.message ?? "").includes("Email")) {
              message = t("container.error.mailTaken")
            }

            Swal.fire({
              title: t("container.error.oops"),
              icon: "error",
              text: message,
              confirmButtonText: "Ok",
            });
          });
      }
    } else {
      if (
        register.user.email === "" ||
        register.user.first_name === "" ||
        register.user.last_name === "" ||
        register.user.gender === ""
      ) {
        container.error(t("container.error.requiredFields"));
      } else if (
        register.telephone !== "" &&
        register.telephone &&
        register.telephone.length < 14
      ) {
        container.error(t("container.error.invalid", {
          method: t("modal.secondStep.phone")
        }));
      } else if (!emailRegex.test(register.user.email.toLowerCase())) {
        container.error(t("container.error.invalid", {
          method: t("table.email")
        }));
      } else {
        const query = {
          email: register.user.email,
          firstName: register.user.first_name,
          lastName: register.user.last_name,
          document: register.document,
          gender: register.user.gender,
          telephone: register.telephone,
          zipCode: register.address.zip_code,
          street: register.address.street,
          complement: register.address.complement,
          neighborhood: register.address.neighborhood,
          number: register.address.number,
          state: register.address.state,
          city: register.address.city,
          facebook: register.user.facebook,
          instagram: register.user.instagram,
          xTwitter: register.user.x_twitter,
          forceThreedsMode: register.user.force_threeds_mode
        };

        New_api.post("store-by-admin", query)
          .then(() => {
            container.success(t("container.success.create"));
            close();
          })
          .catch(() => {
            container.error(t("container.error.register"));
          });
      }
    }
  };

  const getCep = () => {
    if (register.address.zip_code.length !== 8) return
    axios
      .get(`https://viacep.com.br/ws/${register.address.zip_code}/json`)
      .then((res) => {
        setRegister({
          ...register,
          address: {
            ...register.address,
            street: res.data.logradouro,
            state: res.data.uf,
            city: res.data.localidade,
            neighborhood: res.data.bairro,
            complement: res.data.complemento,
          },
        });
      })
      .catch(() => {
        setRegister({
          ...register,
          street: "",
          state: "",
          city: "",
          neighborhood: "",
          complement: "",
        });
      });
  };

  const close = (e, updateAfterEdit = false) => {
    setRegister({
      id: "",
      document: "",
      telephone: "",
      user_id: "",
      address_id: "",
      address: {
        id: "",
        zip_code: "",
        street: "",
        neighborhood: "",
        complement: "",
        state: "",
        city: "",
        number: "",
      },
      user: {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        confirmpassword: "",
        gender: "",
        facebook: "",
        instagram: "",
        x_twitter: "",
        qrcode: "",
        force_threeds_mode: false,
      },
    });
    props.callback("modal-hidden", updateAfterEdit);
  };

  const fetchData = useCallback(() => {
    if (props.id) {
      Api.get(`showUser/${props.id}`)
        .then((res) => {
          setUnchangedEmail(res.data.user.email)

          res.data.address[0]
            ? setRegister({
                address: {
                  id: res.data?.address[0].id,
                  zip_code: res.data?.address[0].zip_code,
                  street: res.data?.address[0].street,
                  neighborhood: res.data?.address[0].neighborhood,
                  complement: res.data?.address[0].complement,
                  state: res.data?.address[0].state,
                  city: res.data?.address[0].city,
                  number: res.data?.address[0].number,
                },
                document: res.data.user.document,
                telephone: res.data.user.telephone,
                user: {
                  id: res.data.user.id,
                  email: res.data.user.email ? res.data.user.email : "",
                  first_name: res.data.user.first_name
                    ? res.data.user.first_name
                    : "",
                  last_name: res.data.user.last_name
                    ? res.data.user.last_name
                    : "",
                  gender: res.data.user.gender ? res.data.user.gender : "",
                  qrcode: res.data.user.qrcode ? res.data.user.qrcode : "",
                  facebook: res.data.user.facebook || "",
                  instagram: res.data.user.instagram || "",
                  x_twitter: res.data.user.x_twitter || "",
                  force_threeds_mode: res.data.user.force_threeds_mode || false,
                },
              })
            : setRegister({
                address: {
                  id: "",
                  zip_code: "",
                  street: "",
                  neighborhood: "",
                  complement: "",
                  state: "",
                  city: "",
                  number: "",
                },
                document: res.data.user.document,
                telephone: res.data.user.telephone,
                user: {
                  id: res.data.user.id,
                  email: res.data.user.email ? res.data.user.email : "",
                  first_name: res.data.user.first_name
                    ? res.data.user.first_name
                    : "",
                  last_name: res.data.user.last_name
                    ? res.data.user.last_name
                    : "",
                  gender: res.data.user.gender ? res.data.user.gender : "",
                  qrcode: res.data.user.qrcode ? res.data.user.qrcode : "",
                  facebook: res.data.user.facebook || "",
                  instagram: res.data.user.instagram || "",
                  x_twitter: res.data.user.x_twitter || "",
                  force_threeds_mode: res.data.user.force_threeds_mode || false,
                },
              });
        })
        .catch(() => {
          Swal.fire({
            title: t("container.error.oops"),
            icon: "error",
            text: t("container.error.internal"),
            confirmButtonText: "Ok",
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const gerarQRCODE = () => {
    Api.get(`/gera/qrcode/user/${props.id}`)
      .then(() => {
        fetchData();
      })
      .catch(() => {
        Swal.fire({
          title: t("container.error.oops"),
          icon: "error",
          text: t("container.error.internal"),
          confirmButtonText: "Ok",
        });
      });
  };

  const handlePasswordRecovery = async () => {
    if (unchangedEmail !== register.user.email) {
      Swal.fire({
        title: t("modal.recovery.title"),
        icon: "warning",
        showCancelButton: true,
        text: t("modal.recovery.title", {
          email: register.user.email
        }),
        confirmButtonText: t("modal.recovery.confirmBtn"),
        cancelButtonText: t("modal.recovery.cancelBtn"),
        reverseButtons: true,
        confirmButtonColor: "white",
        cancelButtonColor: "white",
        customClass: {
          confirmButton: "custom-confirm-button-outline",
          cancelButton: "custom-confirm-button-outline",
          htmlContainer: 'swal-text-justify swal-container-user',
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          registerUser(null, true)
        }
      });
    } else {
      setLoading(true)
      New_api.post('/recovery', { email: register.user.email})
      .then(() => {
        container.success(t("container.success.mailSent"));
      })
      .catch(() => {
        container.error(t("container.error.generic"));
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }

  return (
    <>
      <div
        id="venda-detalhe"
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <div>
          <div className="card-header">
            <h4 className="card-header-title">
              {props.id ? t("modal.edit.title") : t("modal.create.title")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => close(e)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <h4 className="card-header-title mb-2">
              {t("modal.firstStep.title")}
            </h4>
            <ToastContainer
              ref={(ref) => (container = ref)}
              className="toast-top-right"
            />
            <div className="row mt-2">
              <div className={props.id ? "col-md-8" : "col-md-12"}>
                <label htmlFor="email">
                  {t("modal.firstStep.email.label")}
                </label>
                <form method="post" autoComplete="off">
                  <input
                    type="email"
                    placeholder={t("modal.firstStep.email.placeholder")}
                    className="form-control"
                    name="email"
                    value={register.user.email}
                    onChange={updateUser}
                  />
                  {!props.id && (
                    <div className="d-flex justify-content-start align-items-center mt-2 info-user">
                      <Info />
                      <p className="ml-2">{t("modal.firstStep.email.tip")}</p>
                    </div>
                  )}
                </form>
                {props.id ? (
                  <>
                    <label htmlFor="first_name">
                      {t("modal.firstStep.name")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("modal.firstStep.name")}
                      className="form-control"
                      name="first_name"
                      value={register.user.first_name}
                      onChange={updateUser}
                    />

                    <label htmlFor="last_name">
                      {t("modal.firstStep.lastName")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("modal.firstStep.lastName")}
                      className="form-control"
                      name="last_name"
                      value={register.user.last_name}
                      onChange={updateUser}
                    />

                    <label htmlFor="gender">
                      {t("modal.firstStep.gender")}
                    </label>
                    <select
                      className="form-control"
                      name="gender"
                      value={register.user.gender}
                      onChange={updateUser}
                    >
                      {genderOptions({
                        neutral: t(
                          "modal.firstStep.genderSelector.placeholder"
                        ),
                        feminine: t("modal.firstStep.genderSelector.feminine"),
                        masculine: t(
                          "modal.firstStep.genderSelector.masculine"
                        ),
                        nonBinary: t(
                          "modal.firstStep.genderSelector.nonBinary"
                        ),
                        notInform: t(
                          "modal.firstStep.genderSelector.notInform"
                        ),
                      }).map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <ButtonContained
                      onClick={handlePasswordRecovery}
                      disabled={!emailRegex.test(register.user.email.toLowerCase())}
                      className="secondary-outlined mt-3 w-auto"
                      content={
                        <Fragment>
                          {loading ? (
                            <Lottie
                              options={{
                                loop: true,
                                autoplay: true,
                                animationData: animationData,
                              }}
                              height={24}
                              width={24}
                            />
                          ) : (
                            <PaperPlane stroke="#0050C3" />
                          )}
                          {loading
                            ? t("modal.firstStep.recovery.loadingText")
                            : t("modal.firstStep.recovery.mainText")}
                        </Fragment>
                      }
                    />
                    {/* <label htmlFor="last_name">Alterar Senha</label>
                    <input
                      type="password"
                      placeholder="Nova Senha"
                      className="form-control"
                      name="password"
                      minlength="6"
                      value={register.user.password}
                      onChange={updateUser}
                    /> */}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className={props.id ? "col-md-4" : "col-md-3"}>
                {props.id === null ? (
                  <></>
                ) : register.user.qrcode ? (
                  <div style={{ textAlign: "center" }}>
                    <label>QRcode</label>
                    <br />
                    <QRCode value={register.user.qrcode} />
                  </div>
                ) : (
                  <>
                    <label>QRcode</label>
                    <button
                      className="btn btn-primary c3"
                      onClick={gerarQRCODE}
                    >
                      {t("modal.firstStep.generateQrCode")}
                    </button>
                  </>
                )}
              </div>
            </div>

            {!props.id ? (
              <>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <label htmlFor="first_name">
                      {t("modal.firstStep.name")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("modal.firstStep.name")}
                      className="form-control"
                      name="first_name"
                      value={register.user.first_name}
                      onChange={updateUser}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="last_name">
                      {t("modal.firstStep.lastName")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("modal.firstStep.lastName")}
                      className="form-control"
                      name="last_name"
                      value={register.user.last_name}
                      onChange={updateUser}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="gender">
                      {t("modal.firstStep.gender")}
                    </label>
                    <select
                      className="form-control"
                      name="gender"
                      value={register.user.gender}
                      onChange={updateUser}
                    >
                      {genderOptions({
                        neutral: t(
                          "modal.firstStep.genderSelector.placeholder"
                        ),
                        feminine: t("modal.firstStep.genderSelector.feminine"),
                        masculine: t(
                          "modal.firstStep.genderSelector.masculine"
                        ),
                        nonBinary: t(
                          "modal.firstStep.genderSelector.nonBinary"
                        ),
                        notInform: t(
                          "modal.firstStep.genderSelector.notInform"
                        ),
                      }).map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div>
          <div className="card-header">
            <h4 className="card-header-title">{t("modal.secondStep.title")}</h4>
          </div>
          <div className="card-body">
            <div id="dados-pessoais">
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="cpf">{t("modal.secondStep.document")}</label>
                  <input
                    type="text"
                    placeholder="000.000.000-00"
                    className="form-control"
                    name="document"
                    value={register.document || ""}
                    onChange={updateCpf}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="phone">{t("modal.secondStep.phone")}</label>
                  <input
                    type="text"
                    placeholder={t("modal.secondStep.phone")}
                    className="form-control"
                    name="telephone"
                    value={register.telephone || ""}
                    onChange={updatePhone}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="zip_code">
                    {t("modal.secondStep.zipcode")}
                  </label>
                  <input
                    type="text"
                    placeholder={t("modal.secondStep.zipcode")}
                    className="form-control"
                    name="zip_code"
                    value={register.address.zip_code || ""}
                    onChange={updateCep}
                    onBlur={getCep}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-8">
                  <label htmlFor="street">{t("modal.secondStep.street")}</label>
                  <input
                    type="text"
                    placeholder={t("modal.secondStep.street")}
                    className="form-control"
                    name="street"
                    value={register.address.street || ""}
                    onChange={updateAddress}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="number">{t("modal.secondStep.number")}</label>
                  <input
                    type="text"
                    placeholder={t("modal.secondStep.number")}
                    className="form-control"
                    name="number"
                    value={register.address.number || ""}
                    onChange={updateAddress}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-8">
                  <label htmlFor="neighborhood">
                    {t("modal.secondStep.neighborhood")}
                  </label>
                  <input
                    type="text"
                    placeholder={t("modal.secondStep.neighborhood")}
                    className="form-control"
                    name="neighborhood"
                    value={register.address.neighborhood || ""}
                    onChange={updateAddress}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="complement">
                    {t("modal.secondStep.secondeAddressLine")}
                  </label>
                  <input
                    type="text"
                    placeholder={t("modal.secondStep.secondeAddressLine")}
                    className="form-control"
                    name="complement"
                    value={register.address.complement || ""}
                    onChange={updateAddress}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-8">
                  <label htmlFor="city">{t("modal.secondStep.city")}</label>
                  <input
                    type="text"
                    placeholder={t("modal.secondStep.city")}
                    className="form-control"
                    name="city"
                    value={register.address.city || ""}
                    onChange={updateAddress}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="state">{t("modal.secondStep.state")}</label>
                  <input
                    type="text"
                    placeholder={t("modal.secondStep.state")}
                    className="form-control"
                    name="state"
                    value={register.address.state || ""}
                    onChange={updateAddress}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="facebook">Facebook</label>
                  <input
                    type="text"
                    placeholder="Facebook"
                    className="form-control"
                    name="facebook"
                    value={register.user.facebook || ""}
                    onChange={updateSocial}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="instagram">Instagram</label>
                  <input
                    type="text"
                    placeholder="Instagram"
                    className="form-control"
                    name="instagram"
                    value={register.user.instagram || ""}
                    onChange={updateSocial}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="x_twitter">X (Twitter)</label>
                  <input
                    type="text"
                    placeholder="X (Twitter)"
                    className="form-control"
                    name="x_twitter"
                    value={register.user.x_twitter || ""}
                    onChange={updateSocial}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {isAdmin && (
          <div>
            <div className="card-header">
              <h4 className="card-header-title">
                {t("modal.thirdStep.title")}
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 d-flex align-items-center">
                  <ReactSwitch
                    id="force_threeds_mode"
                    name="force_threeds_mode"
                    handleDiameter={16}
                    onColor="#75d1f2"
                    onHandleColor={COLORS.middleBlue}
                    offColor={COLORS.weakGray}
                    boxShadow="0px 1px 3px 0px #4C4E641F"
                    activeBoxShadow="0px 1px 3px 0px #4C4E641F"
                    offHandleColor={COLORS.white}
                    height={10}
                    width={26}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={register.user.force_threeds_mode}
                    onChange={(value) => updateForcedThreeds(value)}
                  />
                  <label
                    className="ml-2 mb-0 mt-0"
                    htmlFor="force_threeds_mode"
                  >
                    {t("modal.thirdStep.forceThreeDs")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <div className="box-button pt-3">
            <button
              className="btn btn-primary col-md-4 c3"
              onClick={registerUser}
            >
              {props.id
                ? t("modal.edit.buttonText")
                : t("modal.create.buttonText")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalUsers;
